import moment from 'moment-timezone'
import OrderService from '../../services/OrderService'
import { getCheckInDate, getCheckOutDate } from '../../components/helpers/items'
import { getAddonItemsFromOrders } from '../../components/helpers/getAddonItems'
import fileDownload from 'js-file-download'

const headings = [
  'Booking ID',
  'Addon',
  'Fare Type',
  'Fare Type Display',
  'Date Of Purchase',
  'Customer Name',
  'Customer Email',
  'Customer Phone',
  'Primary Guest',
  'Adults At Property',
  'Children At Property',
  'Purchased With',
  'Property Name',
  'Check In Date',
  'Check Out Date',
  'Logged',
  'Status',
].join(',')

const escape = val => `"${val}"`

// This is maximum allowed by order service
const limit = 250

export default async function(params) {
  const {
    vendorId,
    addonOpportunityId,
    showCancelled,
    bookingNumbers,
    customer,
    unlogged,
  } = params

  let done = false
  let page = 1
  let result = `${headings}\r\n`
  while (!done) {
    const response = await OrderService.getAddons({
      page,
      perPage: limit,
      addonOpportunityId,
      vendorId,
      showCancelled,
      bookingNumbers,
      customer,
      unlogged,
    })

    if (response.result.length === 0) {
      done = true
      continue
    }

    const addons = await getAddonItemsFromOrders({
      vendorId: vendorId,
      addonId: addonOpportunityId,
      addons: response.result,
      showCancelled: showCancelled,
    })

    for (const addon of addons) {
      result += [
        addon.booking_number,
        escape(addon.name),
        escape(addon.fare_type),
        escape(addon.fare_type_display || ''),
        escape(moment.utc(addon.created_at).format('ddd, D MMM Y')),
        escape(addon.item.customer_full_name),
        escape(addon.item.customer_email),
        escape(((addon.item.customer_phone_prefix != null) && `(+${addon.item.customer_phone_prefix}) `) + addon.item.customer_phone),
        escape(addon.item.guestname),
        addon.item.number_of_adults,
        addon.item.number_of_children,
        escape(addon.item.offer_name),
        escape(addon.item.reservation.property_name),
        getCheckInDate(addon.item, addon.voucher),
        getCheckOutDate(addon.item, addon.voucher),
        addon.voucher.vendor_logged_at ? 'YES' : 'NO',
        addon.status,
      ].join(',') + '\r\n'
    }
    page = page + 1
  }
  const fileName = `addon_booking_report_${addonOpportunityId}_${moment().format('YYYYMMDDHHmmss')}.csv`
  await fileDownload(result, fileName)
}
