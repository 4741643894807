import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

class RefundRequestReasonCell extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpanded: false,
    }
  }

  toggleExpand = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }))
  };

  render() {
    const { reasonText } = this.props
    const { isExpanded } = this.state

    return (
      <div>
        <div
          className={'text-truncate'}
          style={{
            overflow: 'hidden',
            whiteSpace: isExpanded ? 'normal' : 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {reasonText}
        </div>
        <Button bsStyle="link" onClick={this.toggleExpand}>
          {isExpanded ? 'Less' : 'More'}
        </Button>
      </div>
    )
  }
}

export default RefundRequestReasonCell
