/* src/components/Addons/AddonCard.js */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { Glyphicon, Grid, Col, Row } from 'react-bootstrap'
import AddonSalesPerformance from './AddonSalesPerformance'
import Image from '../Common/Image'

const getImage = addon => {
  if (!addon.cloudinary_image_id || addon.cloudinary_image_id.length === 0) {
    return null
  }

  return (
    <Image id={addon.cloudinary_image_id} />
  )
}

const getOrdersLink = addon => `/${addon.vendor_id}/addons/${addon.fk_salesforce_id}/orders`

class AddonCard extends React.Component {
  render() {
    const { addon } = this.props

    return (
      <div className="offer-card">
        <Grid>
          <Row>
            <Col md={2}>
              {getImage(addon)}

              <h5>Deal ID</h5>
              {addon.fk_salesforce_id}

              <h5>LE Offer ID</h5>
              {addon.le_offer_id}
            </Col>
            <Col md={7}>
              <h4>{addon.name}</h4>
              {addon.description}
            </Col>
            <Col md={3}>
              <h5>Reservation Email</h5>
              {addon.vendor_booking_email}
            </Col>
          </Row>

          <AddonSalesPerformance addon={addon} addonId={addon.fk_salesforce_id} />

          <Row className="action-bar">
            <Col md={6} mdOffset={6}>
              <div className="button-group">
                <Link to={getOrdersLink(addon)} className="btn btn-primary">
                  <Glyphicon glyph="briefcase" /> View Bookings
                </Link>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

AddonCard.contextTypes = {
  addon: PropTypes.object.isRequired,
}

export default AddonCard
