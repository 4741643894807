import PropTypes from 'prop-types'
import React, { Component } from 'react'
import UpdatePassword from './UpdatePassword'
import VendorInformation from './VendorInformation'

export default class ProfilePage extends Component {
  render() {
    return (
      <div className="container profile-page">
        <h3>Account Information</h3>
        <div className="section">
          <UpdatePassword />
        </div>
        {this.context.vendor && (
          <VendorInformation vendor={this.context.vendor} />
        )}
      </div>
    )
  }
}

ProfilePage.contextTypes = {
  user: PropTypes.object,
  vendor: PropTypes.object,
}
