import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon } from 'react-bootstrap'

// Calendar is a presentational component that renders calendar layout with
// custom behaviors
const Calendar = ({
  grid,
  shouldHidePrevMonthButton,
  shouldHideNextMonthButton,
  onShowPrevMonth,
  onShowNextMonth,
  renderCalendarDay,
  onDateHover,
  onDateUnhover,
}) => (
  <div className="calendar">
    <div className="inline-group">
      {grid.map(function(month, monthIdx) {
        return (
          <div key={`month-${monthIdx}`}>
            <div className="month-name">{month.date.format('MMMM YYYY')}</div>
            <div>
              <div className="week">
                <div className="day-name">Su</div>
                <div className="day-name">Mo</div>
                <div className="day-name">Tu</div>
                <div className="day-name">We</div>
                <div className="day-name">Th</div>
                <div className="day-name">Fr</div>
                <div className="day-name">Sa</div>
              </div>
              {month.weeks.map(function(week, weekIdx) {
                return <div className="week names" key={`week-${weekIdx}`}>
                  {week.map(function(dayDate, idx) {
                    return (
                      <div
                        className="day-container"
                        key={idx}
                        onMouseEnter={() => {
                          onDateHover(dayDate, idx)
                        }}
                        onMouseLeave={() => {
                          onDateUnhover(dayDate, idx)
                        }}
                      >
                        {renderCalendarDay(dayDate, idx)}
                      </div>
                    )
                  })}
                </div>
              })}
            </div>
          </div>
        )
      })}
    </div>
    <div className="buttons">
      <button disabled={shouldHidePrevMonthButton} onClick={onShowPrevMonth}>
        <Glyphicon glyph="chevron-left" />
      </button>
      <button disabled={shouldHideNextMonthButton} onClick={onShowNextMonth}>
        <Glyphicon glyph="chevron-right" />
      </button>
    </div>
  </div>
)

Calendar.contextTypes = {
  renderCalendarDay: PropTypes.func.isRequired,
  grid: PropTypes.arrayOf(PropTypes.object).isRequired,
  onShowPrevMonth: PropTypes.func.isRequired,
  onShowNextMonth: PropTypes.func.isRequired,
  shouldHidePrevMonthButton: PropTypes.bool.isRequired,
  shouldHideNextMonthButton: PropTypes.bool.isRequired,
}

export default Calendar
