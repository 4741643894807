export function camelCaseToCapitalized(camelCasedString) {
  if (!camelCasedString || !(typeof camelCasedString === 'string' || camelCasedString instanceof String)) {
    return ''
  }

  const spaceSeparatedNonCapitalizedString = camelCasedString.replace(/([A-Z])/, ' $1')
  const spaceSeparatedCapitalizedString = spaceSeparatedNonCapitalizedString[0].toUpperCase() + spaceSeparatedNonCapitalizedString.slice(1)

  return spaceSeparatedCapitalizedString
}
