import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

export default class DownloadTravellerPassportsButton extends Component {
  render() {
    return (
      <Button
        bsStyle="default"
        onClick={this.props.onClick}
      >
        Download Traveller Passports
      </Button>
    )
  }
}
