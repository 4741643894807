import React, { Component } from 'react'
import MDSpinner from 'react-md-spinner'
import {
  Button,
  Col,
  ControlLabel,
  Dropdown,
  Form,
  FormControl,
  FormGroup,
  MenuItem,
} from 'react-bootstrap'
import PropertyService from '../../services/PropertyService'

class PropertyToggle extends Component {
  handleClick = (e) => {
    e.preventDefault()
    this.props.onClick(e)
    setTimeout(() => {
      this.inputRef.focus()
    }, 100)
  };

  render() {
    const { open, onSearch, selected } = this.props

    return (
      <div>
        <FormControl
          type="text"
          onChange={onSearch}
          placeholder="Search property..."
          inputRef={(ref) => {
            this.inputRef = ref
          }}
          style={{ display: !open && 'none', width: '100%' }}
        />
        {!open && (
          <Button onClick={this.handleClick} style={{ width: '100%' }}>
            {selected?.name ?? 'Select property'}
          </Button>
        )}
      </div>
    )
  }
}

export default class PropertySelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      items: [],
      selectedProperty: null,
      searching: false,
    }
    this.timer = null
  }

  componentDidMount = async() => {
    this.fetchItems()
  };

  handleChange = (event) => {
    const query = event.target.value
    this.setState({ query })

    // Debounce
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.fetchItems(query)
    }, 300)
  };

  fetchItems = async(query) => {
    try {
      this.setState({ loading: true })
      const { result } = await PropertyService.listPropertiesByVendor(
        1,
        20,
        this.props.idVendor,
        query,
      )
      this.setState({ items: result })
    } catch (error) {
      console.error('Erro ao buscar itens:', error)
    } finally {
      this.setState({ loading: false })
    }
  };

  render() {
    const { items, selectedProperty, selectedType, selectedRate, loading } =
      this.state
    return (
      <div style={{ width: '100%', marginBottom: 0 }}>
        <Dropdown id="dd-prop-selector" style={{ width: '100%' }}>
          <PropertyToggle
            bsRole="toggle"
            onSearch={this.handleChange}
            selected={selectedProperty}
          ></PropertyToggle>

          <Dropdown.Menu bsRole="menu">
            {!loading &&
              items.map((item) => (
                <MenuItem
                  key={item.id}
                  eventKey={item.id}
                  onClick={() => {
                    this.setState({
                      selectedProperty: item,
                      selectedType: null,
                      selectedRate: null,
                    })
                  }}
                >
                  {item.name}
                </MenuItem>
              ))}
            {loading && <MDSpinner className="spinner" size={25} />}
          </Dropdown.Menu>
        </Dropdown>
        <Col className="rateId" xs={12} sm={12}>
          <FormGroup controlId="formControlsSelect" style={{ marginBottom: 0 }}>
            <ControlLabel>Select room type</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="select"
              onChange={(e) => {
                const selectedType = selectedProperty?.room_types?.find(
                  (type) => type.id == e.target.value,
                )
                this.setState({ selectedType, selectedRate: null })
              }}
            >
              <option value="">Select room type</option>
              {selectedProperty?.room_types?.map((type) => (
                <option key={type.id} value={type.id} id={type.id}>
                  {type.description}
                </option>
              ))}
            </FormControl>
          </FormGroup>
        </Col>
        <Col className="rateId" xs={12} sm={12}>
          <FormGroup controlId="formControlsSelect" style={{ marginBottom: 0 }}>
            <ControlLabel>Select rate</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="select"
              onChange={(e) => {
                const selRate = selectedType?.room_rates?.find(
                  (type) => type.id == e.target.value,
                )
                this.setState({ selectedRate: selRate })
                this.props.onChange &&
                  this.props.onChange({
                    rateId: selRate?.id,
                    propertyId: selectedProperty?.id,
                    roomId: selectedType?.id,
                  })
              }}
            >
              <option value="">Select rate</option>
              {selectedType?.room_rates?.map((rate) => (
                <option value={rate.id} key={rate.id}>
                  {rate.rate_plan?.name}
                </option>
              ))}
            </FormControl>
          </FormGroup>
        </Col>
      </div>
    )
  }
}
