import { vendor } from '@luxuryescapes/lib-global'

import { BUY_NOW_BOOK_LATER, TOUR } from '../../constants/order-constants'

const validItemStatus = ['cancelled', 'completed']
const validTourItemStatus = ['cancelled', 'completed', 'awaiting_dates']

export const validateItemStatus = (status, type) => {
  if (type === 'tour') {
    return validTourItemStatus.includes(status)
  } else {
    return validItemStatus.includes(status)
  }
}

export const hasItemReservation = (item) => {
  if (item.reservation_type === BUY_NOW_BOOK_LATER && !item.reservation_made) {
    return false
  }
  return true
}

export const hasItemTraveller = (item) => {
  if (item.offer.type === TOUR && vendor.requiresTravellerDetails(item.fk_vendor_id)) {
    return true
  }
  return false
}

export const hasOrderTraveller = (order) => {
  let hasTraveller = false
  for (const item of order.items) {
    if (!hasTraveller && hasItemTraveller(item)) {
      hasTraveller = true
      break
    }
  }

  return hasTraveller
}
