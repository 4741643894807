/* src/components/Addons/AddonsPageContainer.js */
import React from 'react'

import AddonsPage from './AddonsPage'
import OffersService from '../../services/OffersService'
import ErrorDisplay from '../Common/ErrorDisplay'
import MDSpinner from 'react-md-spinner'

class AddonsPageContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      error: null,
      addons: [],
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    this.setState({ loading: true, error: null }, async() => {
      try {
        const addons = await OffersService.getVendorAddons(this.props.params.id_vendor)

        this.setState({ loading: false, addons })
      } catch (e) {
        this.setState({ loading: false, error: e.message })
      }
    })
  }

  render() {
    const { loading, error, addons } = this.state

    if (loading) {
      return (
        <div className="spinner-container">
          <MDSpinner className="spinner" size={100} />
        </div>
      )
    }

    if (error) {
      return (
        <div className="container">
          <ErrorDisplay message={error} />
        </div>
      )
    }

    return (
      <AddonsPage addons={addons} />
    )
  }
}

export default AddonsPageContainer
