import React, { Component } from 'react'
import moment from 'moment-timezone'

export default class CheckOutDateCell extends Component {
  render() {
    const {
      checkOutDate,
      previousCheckOutDate,
      hasReservation,
    } = this.props

    if (!hasReservation) {
      return null
    }

    const checkOutDatePlaceholder = checkOutDate ? moment.utc(checkOutDate).format('DD/MM/YYYY') : 'Check-out date not provided'
    const previousCheckOutDatePlaceholder = previousCheckOutDate ? moment.utc(previousCheckOutDate).format('DD/MM/YYYY') : ''

    return (
      <div>
        {previousCheckOutDatePlaceholder && (
          <div className="text-warning">
            <div>Changed to {checkOutDatePlaceholder}</div>
            <div>From {previousCheckOutDatePlaceholder}</div>
          </div>
        )}
        {!previousCheckOutDatePlaceholder && (
          <div>{checkOutDatePlaceholder}</div>
        )}
      </div>
    )
  }
}
