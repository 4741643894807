import React, { Component } from 'react'
import MDSpinner from 'react-md-spinner'
import classNames from 'classnames'
import Reporting from '../../services/ReportingService'
import { Alert, Glyphicon, Col, Row, Button } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

export default class SalesPerformance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fetching: false,
      error: null,
      performance: null,
      openPanel: false,
    }
  }

  dismissError = () => {
    this.setState({
      error: null,
    })
  }

  hidePanel = () => {
    this.setState({ openPanel: false })
  }

  fetchData = async() => {
    if (this.state.performance) {
      return this.setState({ openPanel: true })
    }
    this.setState({
      openPanel: true,
      fetching: true,
    })
    try {
      const response = await Reporting.getOfferPerformance(this.props.idOffer)
      this.setState({
        fetching: false,
        performance: response,
      })
    } catch (err) {
      this.setState({
        fetching: false,
        openPanel: false,
        error: err.message,
      })
    }
  }

  renderClosedPanel() {
    return (
      <Col sm={12} md={12}>
        <div className="sales-performance-buttons">
          <Button bsStyle="link" onClick={this.fetchData} className="open-panel-button">
            <Glyphicon glyph="signal" />
              View Sales Performance
            <Glyphicon glyph="chevron-down" className="small" />
          </Button>
        </div>
      </Col>
    )
  }

  renderLoadingPanel() {
    return (
      <Col sm={12} md={12}>
        <div className="spinner-container">
          <MDSpinner className="spinner" size={80} />
        </div>
      </Col>
    )
  }

  renderPerformancePanel() {
    const hasNights = !!(this.state.performance.packages[0] && this.state.performance.packages[0].number_of_nights)
    const numberOfNights = this.state.performance.packages.reduce(
      (acc, pkg) => acc + (pkg.number_of_nights * pkg.sold), 0,
    )

    return (
      <span>
        <Col sm={12} md={2}>
          <h5>Total Revenue</h5>
          {this.state.performance.totalRevenue}
          <h5>Total Active Packages</h5>
          <span className="text-large">
            {this.state.performance.totalSold}
          </span>
          <h5>Confirmed</h5>
          {this.state.performance.totalBookingsConfirmed}
          <h5>Awaiting Dates</h5>
          {this.state.performance.totalBookingsPending}
          <h5>Cancelled</h5>
          {this.state.performance.totalCancelled}
          {hasNights && (
            <div>
              <h5>Total Room Nights</h5>
              {numberOfNights}
            </div>
          )}
        </Col>
        <Col sm={12} md={10}>
          <div className="space-between">
            <h5>Performance by package</h5>
            <Button bsStyle="link" onClick={this.hidePanel}>
              <Glyphicon glyph="chevron-up" className="small primary" />
            </Button>
          </div>
          <BootstrapTable
            data={this.state.performance.packages}
            striped
            hover
            bordered
          >
            <TableHeaderColumn dataField="name" isKey width="60%">Package Name</TableHeaderColumn>
            {hasNights && <TableHeaderColumn dataField="number_of_nights">Number of Nights</TableHeaderColumn>}
            <TableHeaderColumn dataField="sold">Sold</TableHeaderColumn>
            <TableHeaderColumn dataField="revenue">Revenue (Surcharges Included)</TableHeaderColumn>
          </BootstrapTable>
          <div className="last-updated subtext pull-right">
            All reporting is live and real time
          </div>
        </Col>
      </span>
    )
  }

  render() {
    const rowClassNames = classNames('sales-performance', {
      active: this.state.openPanel,
    })
    const shouldShowPerformance = !!(this.state.openPanel && this.state.performance)
    if (this.state.error) {
      return (
        <Alert bsStyle="danger" onDismiss={this.dismissError}>
          <h4>An Error occurred while fetching sales performance</h4>
          <p> {this.state.error} </p>
        </Alert>
      )
    }
    return (
      <Row className={rowClassNames}>
        {!this.state.openPanel && this.renderClosedPanel()}
        {this.state.openPanel && this.state.fetching && this.renderLoadingPanel()}
        {shouldShowPerformance && this.renderPerformancePanel()}
      </Row>
    )
  }
}
