import { request } from './common'

function basePath() {
  return window.configs.NOTIFICATION_HOST + '/api/le-notify/'
}

export default class NotificationService {
  static async resendVendorBookingConfirmation({ orderID, offerID }) {
    return request(basePath() + 'vendor-booking-confirmation/resend', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id_orders: orderID,
        id_offers: offerID,
      }),
    })
  }
}
