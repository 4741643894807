import VoucherService from '../../services/VoucherService'

export const getVoucher = async({ vendorId, offerId, bookingNumber, noCache }) => {
  const response = await VoucherService.get({
    id_vendor: vendorId,
    id_offer: offerId,
    voucher: bookingNumber,
    no_cache: noCache,
  })

  return response.result[0] || {}
}

export const updateVoucherStatus = async({ vendorId, offerId, bookingNumber, isLogged }) => {
  await VoucherService.patch({
    id_vendor: vendorId,
    id_offer: offerId,
    voucher: bookingNumber,
    params: `vendorLog=${(isLogged ? 'false' : 'true')}`,
  })
}
