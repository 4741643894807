import React, { Component } from 'react'
import { Alert, Button, Form, FormGroup, FormControl, ControlLabel, Col, HelpBlock } from 'react-bootstrap'
import MDSpinner from 'react-md-spinner'
import AuthService from '../../services/AuthService'

export default class UpdatePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      updating: false,
      error: null,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      passwordMatchError: null,
      passwordUpdated: false,
    }
  }

  onCurrentPasswordChange = (event) => {
    this.setState({
      currentPassword: event.target.value,
    })
  }

  onNewPasswordChange = (event) => {
    this.setState({
      newPassword: event.target.value,
      passwordMatchError: null,
    })
  }

  onConfirmNewPasswordChange = (event) => {
    this.setState({
      confirmNewPassword: event.target.value,
      passwordMatchError: null,
    })
  }

  validate = () => {
    if (this.state.confirmNewPassword !== this.state.newPassword) {
      this.setState({
        passwordMatchError: 'Passwords do not match',
      })
      return false
    }
    return true
  }

  onSubmit = async(event) => {
    event.preventDefault()
    if (!this.validate()) {
      return
    }
    this.setState({ updating: true, error: null, passwordUpdated: false })
    try {
      await AuthService.updatePassword({
        currentPassword: this.state.currentPassword,
        newPassword: this.state.newPassword,
      })
      this.setState({ updating: false, passwordUpdated: true })
    } catch (err) {
      this.setState({ updating: false, error: err.message })
    }
  }

  render() {
    return (
      <div>
        <h4>Change Password</h4>
        {this.state.error && (
          <Alert bsStyle="danger">{this.state.error}</Alert>
        )}
        {this.state.passwordUpdated && (
          <Alert bsStyle="success">Password successfully updated</Alert>
        )}
        <Form horizontal>
          <FormGroup>
            <Col sm={12} md={6}>
              <ControlLabel>Current Password</ControlLabel>
              <FormControl type="password" value={this.state.currentPassword} onChange={this.onCurrentPasswordChange} />
            </Col>
          </FormGroup>
          <FormGroup validationState={this.state.passwordMatchError ? 'error' : null}>
            <Col sm={12} md={6}>
              <ControlLabel>New Password</ControlLabel>
              <FormControl type="password" value={this.state.newPassword} onChange={this.onNewPasswordChange} />
              {this.state.passwordMatchError && (
                <HelpBlock>{this.state.passwordMatchError}</HelpBlock>
              )}
            </Col>
            <Col sm={12} md={6}>
              <ControlLabel>Confirm New Password</ControlLabel>
              <FormControl type="password" value={this.state.confirmNewPassword} onChange={this.onConfirmNewPasswordChange} />
              {this.state.passwordMatchError && (
                <HelpBlock>{this.state.passwordMatchError}</HelpBlock>
              )}
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm={12} md={6}>
              <Button
                bsSize="large"
                bsStyle="primary"
                disabled={this.state.updating}
                onClick={this.onSubmit}>
                {this.state.updating ? (
                  <div className="spinner-container">
                    <MDSpinner className="spinner" singleColor={'#FFF'} size={16} />
                  </div>
                ) : 'Update Password'}
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    )
  }
}
