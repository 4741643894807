import React, { Component } from 'react'

export default class GuestsCell extends Component {
  render() {
    const {
      row,
    } = this.props

    if (!row.number_of_adults && !row.number_of_children) {
      return null
    }

    return (
      <div>
        <div><strong>Adult:</strong> {row.number_of_adults}</div>
        <div><strong>Child:</strong> {row.number_of_children}</div>
        <div><strong>Infants:</strong> {row.number_of_infants ?? 0}</div>
      </div>
    )
  }
}
