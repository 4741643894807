import TravellerService from '../../services/TravellerService'
import TourService from '../../services/TourService'
import { validateItemStatus } from './validators'
import { getNumberOfAdults, getNumberOfChildren, getDealOptionName, getTourDuration, getSpecialRequests, sortTourTravellers } from './tourItems'
import { OFFER_TYPE_TOUR_V2 } from '../../constants/offer-constants'
import { TRAVELLER_SORT_CRITERIA } from '../../constants/traveller-details'

export const createTourItem = (order, offer, tourItem, travellers, tourReservation) => ({
  id_item: tourItem.id,
  id_order: order.id,
  id_vendor: tourItem.vendor_id,
  id_offer: tourItem.tour_id,
  booking_number: tourItem.booking_number,
  customer_full_name: order.customer_full_name,
  customer_email: order.customer_email,
  customer_phone: order.customer_phone,
  customer_phone_prefix: order.customer_phone_prefix,
  created_at: order.created_at,
  offer_name: tourItem.tour_name,
  deal_option_name: getDealOptionName(offer, tourItem.tour_option_id),
  tour_duration: getTourDuration(tourReservation.startDate, tourReservation.endDate),
  status: tourItem.status,
  offer_type: tourItem.type,
  tour_flight_details_required: tourItem.tour_flight_details_required,
  number_of_adults: getNumberOfAdults(tourItem.travellers),
  number_of_children: getNumberOfChildren(tourItem.travellers),
  travellers: sortTourTravellers(travellers, TRAVELLER_SORT_CRITERIA),
  tourItemTravellers: tourItem.travellers,
  start_date: tourItem.start_date,
  end_date: tourItem.end_date,
  reservation_id: tourItem.reservation_id,
  logged: tourReservation?.logged ?? false,
  type: OFFER_TYPE_TOUR_V2,
  special_requests: getSpecialRequests(tourReservation),
})

const areFlightDetailsRequired = (travellers) => travellers?.[0]?.purchased_item?.tour_flight_details_required ?? true

export const getTourItemsFromOrders = async(offer, orders = [], travellerDetails = false, filters = {}) => {
  const result = []
  const bookingNumbers = []
  const showCancelled = typeof filters.showCancelled === 'undefined' ? true : filters.showCancelled
  const filterByBookingNumbers = typeof filters.bookingNumbers === 'undefined' ? null : filters.bookingNumbers

  for (const order of orders) {
    let travellers = []
    if (travellerDetails) {
      travellers = await TravellerService.getDetails(order.id, true)
    }
    for (const tourItem of order.tour_items) {
      if (showCancelled === false && tourItem.status !== 'completed') {
        continue
      }
      if (!validateItemStatus(tourItem.status, tourItem.type)) {
        continue
      }
      if (filterByBookingNumbers && !filterByBookingNumbers.includes(tourItem.booking_number)) {
        continue
      }

      bookingNumbers.push(tourItem.booking_number)

      tourItem.tour_flight_details_required = areFlightDetailsRequired(travellers)

      let tourReservation

      try {
        tourReservation = await TourService.getReservation(tourItem.reservation_id)
      } catch (error) {
        tourReservation = null
      }

      result.push(createTourItem(order, offer, tourItem, travellers, tourReservation))
    }
  }

  return result
}
