import qs from 'qs'

import { request } from './common'

export default class OffersService {
  static getOffersByVendor(page = 1, limit = 15, idVendor) {
    const requestUri = window.configs.OFFERS_HOST + '/api/offers'
    const requestParams = `q=${idVendor}&id_vendor=${idVendor}&page=${page}&limit=${limit}&orderByScheduleDate=1`
    return request(requestUri + '?' + requestParams, { method: 'GET' })
  }

  static async getAllOffersByVendor(idVendor) {
    let page = 1
    const limit = 100

    let offers = []

    let offersResult = await this.getOffersByVendor(page, limit, idVendor)

    while (offersResult.result.length > 0) {
      offers = [...offers, ...offersResult.result]
      page++
      offersResult = await this.getOffersByVendor(page, limit, idVendor)
    }

    return offers
  }

  static async getOfferById(id) {
    const requestUri = window.configs.OFFERS_HOST + '/api/offers/' + id

    const response = await request(requestUri, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })

    return response.result
  }

  static async getVendorAddons(vendorId) {
    const requestUri = `${window.configs.OFFERS_HOST}/api/vendor-addons/${vendorId}?id_vendor=${vendorId}`

    const response = await request(requestUri, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })

    return response.result
  }

  static async getVendorAddon(vendorId, addonID) {
    const requestUri = window.configs.OFFERS_HOST + '/api/vendor-addons/' + vendorId + '/opportunities/' + addonID + '?id_vendor=' + vendorId

    const response = await request(requestUri, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })

    return response.result
  }

  static async getScheduleLogs(id, queryParams) {
    const requestUri = `${window.configs.OFFERS_HOST}/api/offers/${id}/schedule-logs?${qs.stringify(queryParams)}`

    const response = await request(requestUri, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })

    return response.logs
  }
}
