/* src/components/Addons/AddonsPage.js */
import React from 'react'
import PropTypes from 'prop-types'
import AddonCard from './AddonCard'

class AddonsPage extends React.Component {
  render() {
    const { addons } = this.props
    const hasAddons = addons.length > 0

    return (
      <div className="container offers-page">
        <h3>My Paid Inclusions</h3>

        {hasAddons ? (
          <div>
            {addons.map((addon, i) => <AddonCard key={i} addon={addon} />)}
          </div>
        ) : (
          <div className="no-campaigns-message">Currently you do not have any paid inclusions for this package</div>
        )}
      </div>
    )
  }
}

AddonsPage.contextTypes = {
  addons: PropTypes.array.isRequired,
}

export default AddonsPage
