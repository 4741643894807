import { request } from './common'

export default class AuthService {
  static async loginWithGoogle({ id_google, email, token_id, givenName, surname }) {
    const url = `${window.configs.AUTH_HOST}/login/google`
    const response = await request(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id_google,
        email,
        token_id,
        givenName,
        surname,
      }),
    })
    return response.result
  }

  static async getCurrentUser() {
    const url = `${window.configs.COMMERCE_HOST}/api/users/current`
    const response = await request(url)
    return response.result
  }

  static async updatePassword({ currentPassword, newPassword }) {
    const url = `${window.configs.COMMERCE_HOST}/api/users/current`
    const response = await request(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        password: newPassword,
        existingPassword: currentPassword,
      }),
    })
    return response.result
  }

  static async sendForgotPassword({ email }) {
    const url = `${window.configs.COMMERCE_HOST}/api/reset-password-email`
    const response = await request(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        source: 'vendor',
      }),
    })
    return response.result
  }

  static async sendResetPassword({ password, token }) {
    const url = `${window.configs.COMMERCE_HOST}/api/reset-token`
    const response = await request(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        password,
        token,
      }),
    })
    return response.result
  }
}
