import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

import Alert from './Alert'

import NotificationService from '../../services/NotificationService'

export default class BookingCell extends Component {
  constructor(props) {
    super(props)

    this.handleHover = this.handleHover.bind(this)
    this.resendVendorEmail = this.resendVendorEmail.bind(this)
    this.dismissVendorEmailAlert = this.dismissVendorEmailAlert.bind(this)

    this.state = {
      isHovering: false,
      sendingVendorEmail: false,
      showVendorEmailAlert: false,
      vendorEmailAlerMessage: null,
    }
  }

  handleHover() {
    if (this.props.showResendVendorEmail) {
      this.setState((prevState) => ({
        isHovering: !prevState.isHovering,
      }))
    }
  }

  dismissVendorEmailAlert() {
    this.setState({ showVendorEmailAlert: false })
  }

  async resendVendorEmail() {
    const orderID = this.props.orderID
    const offerID = this.props.offerID

    this.setState({
      sendingVendorEmail: true,
      vendorEmailAlerMessage: null,
    },
    async() => {
      let message

      try {
        await NotificationService.resendVendorBookingConfirmation({ orderID, offerID })

        message = 'Successfully resent confirmation email'
      } catch (err) {
        message = err.message
      }

      this.setState({
        sendingVendorEmail: false,
        showVendorEmailAlert: true,
        vendorEmailAlerMessage: message,
      })
    })
  }

  render() {
    const {
      customerFullName,
      customerEmail,
      customerPhonePrefix,
      customerPhone,
    } = this.props

    return (
      <div
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleHover}
      >
        <Alert
          show={this.state.showVendorEmailAlert}
          onHide={this.dismissVendorEmailAlert}
        >
          {this.state.vendorEmailAlerMessage}
        </Alert>
        <div><strong>{customerFullName}</strong></div>
        <div>{customerEmail}</div>
        <div>{customerPhonePrefix && `(+${customerPhonePrefix}) `}{customerPhone}</div>
        {
          this.state.isHovering &&
          <Button
            bsStyle="link"
            onClick={this.resendVendorEmail}
            disabled={this.state.sendingVendorEmail}
          >
            Get Booking Email
          </Button>
        }
      </div>
    )
  }
}
