import React, { PureComponent } from 'react'
import { Button } from 'react-bootstrap'

export class TourV2StatusCell extends PureComponent {
  handleLogItem = async() => {
    const { tourItem, onTourV2ItemLog, onError } = this.props

    try {
      await onTourV2ItemLog(tourItem.reservation_id, !tourItem.logged)
    } catch (e) {
      onError(e.message)
    }
  }

  render() {
    const { tourItem } = this.props

    const isLogged = tourItem.logged

    return (
      <div>
        {isLogged && <div className="subtext">Logged</div>}
        <Button bsStyle="link" onClick={this.handleLogItem}>
          {isLogged ? 'Unlog' : 'Log'}
        </Button>
      </div>
    )
  }
}
