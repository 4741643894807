import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MDSpinner from 'react-md-spinner'
import { Alert } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

import GuestsCell from '../Common/GuestsCell'
import BookingCell from '../Common/BookingCell'
import CheckInDateCell from '../Common/CheckInDateCell'
import CheckOutDateCell from './CheckOutDateCell'
import PrimaryGuestCellHotel from './PrimaryGuestCellHotel'
import PrimaryGuestCellTour from './PrimaryGuestCellTour'
import { StatusCell } from './StatusCell'
import OrdersPageFilters from './OrdersPageFilters'
import ExportButton from '../Common/ExportButton'
import { DownloadTravellerPassportsButton, DownloadTravellerPassportsModal } from './DownloadTravellerPassports'
import { TravellerDetailsButton, TravellerDetailsModal } from './TravellerDetails'
import { hasItemReservation } from '../helpers/validators'
import { formatDate, getCheckInDate, getCheckOutDate } from '../helpers/items'

import { BUY_NOW_BOOK_LATER, CANCELLED } from '../../constants/order-constants'
import { OFFER_TYPE_TOUR_V2 } from '../../constants/offer-constants'
import TourTravellersCell from './TourTravellersCell'

export default class OrdersPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      download_traveller_passports_modal_show: false,
      traveller_modal_show: false,
      traveller_modal_data: null,
      error: null,
      generatingReport: false,
      generatingTravellerDetails: false,
    }
  }

  renderId(cell, row) {
    return (
      <div><strong>{row.booking_number}</strong></div>
    )
  }

  renderBooking(cell, row) {
    return (
      <BookingCell
        offerID={row.id_offer}
        orderID={row.id_order}
        customerFullName={row.guestname}
        customerEmail={row.customer_email}
        customerPhonePrefix={row.customer_phone_prefix}
        customerPhone={row.customer_phone}
        showResendVendorEmail
      />
    )
  }

  renderBookingCreated(cell, row) {
    return formatDate(row.created_at, false, 'DD/MM/YYYY')
  }

  renderPackage(cell, row) {
    if (row.type === OFFER_TYPE_TOUR_V2) {
      return (
        <div>{row.package_name}</div>
      )
    }

    const packageDuration = row.package_nights ? 'nights' : 'days'

    return (
      <div>
        <div><strong>{row.package_name}</strong></div>
        <div>{row.package_nights || row.package_days} {packageDuration}</div>
      </div>
    )
  }

  renderGuests(cell, row) {
    return <GuestsCell row={row} />
  }

  renderPrimaryGuest = (cell, row) => {
    if (row.type === 'tour' && this.props.isLETour) {
      return <PrimaryGuestCellTour
        row={row}
        travellerDetailsButton={<TravellerDetailsButton onClick={() => {
          this.setState({
            traveller_modal_show: true,
            traveller_modal_data: row,
          })
        }} />}
      />
    }

    return <PrimaryGuestCellHotel row={row} />
  }

  renderTourTravellers = (cell, row) => <TourTravellersCell
    row={row}
    setTravellerData={this.setTravellerData}
  />

  setTravellerData = (travellerData) => {
    this.setState({
      traveller_modal_show: true,
      traveller_modal_data: travellerData,
    })
  }

  renderCheckInDate = (cell, row) => {
    if (row.type === OFFER_TYPE_TOUR_V2) {
      return row.start_date
    }

    if (!row.voucher) {
      return
    }

    const checkInDate = getCheckInDate(row, row.voucher)
    const previousCheckInDate = getCheckInDate(row, row.voucher, true)
    const hasReservation = hasItemReservation(row)

    return (
      <CheckInDateCell
        checkInDate={checkInDate}
        previousCheckInDate={previousCheckInDate}
        type={row.type}
        hasReservation={hasReservation}
        voucher={row.voucher}
        status={row.status}
        surchargePaid={row.surcharge_paid}
        isSurchargeWaived={hasReservation && !!row.reservation.waived_surcharge_amount}
      />
    )
  }

  renderCheckOutDate = (cell, row) => {
    if (row.type === OFFER_TYPE_TOUR_V2) {
      return row.end_date
    }

    if (!row.voucher) {
      return
    }
    const checkOutDate = getCheckOutDate(row, row.voucher)
    const previousCheckOutDate = getCheckOutDate(row, row.voucher, true)
    const hasReservation = hasItemReservation(row)
    return (
      <CheckOutDateCell
        checkOutDate={checkOutDate}
        previousCheckOutDate={previousCheckOutDate}
        hasReservation={hasReservation}
      />
    )
  }

  renderStatus = (cell, row) => {
    if (row.type === OFFER_TYPE_TOUR_V2) {
      return row.status
    }

    const { onItemLog } = this.props
    if (row.status === CANCELLED) {
      return <div className="subtext">Cancelled</div>
    }

    if (row.on_hold) {
      return <div className="subtext">On Hold</div>
    }

    if (row.reservation_type === BUY_NOW_BOOK_LATER && !row.reservation_made) {
      return null
    }

    if (!row.voucher) {
      return null
    }

    return (
      <StatusCell
        item={row}
        onItemLog={onItemLog}
        onError={this.handleError}
      />
    )
  }

  exportBookings = async() => {
    this.setState({ generatingReport: true })
    try {
      await this.props.exportBookings()
      this.setState({ generatingReport: false })
    } catch (err) {
      this.setState({
        error: err.message,
        generatingReport: false,
      })
    }
  }

  exportTravellerDetails = async() => {
    this.setState({ generatingTravellerDetails: true })
    try {
      await this.props.exportTravellerDetails()
      this.setState({ generatingTravellerDetails: false })
    } catch (err) {
      this.setState({
        error: err.message,
        generatingTravellerDetails: false,
      })
    }
  }

  handleError = msg => this.setState({ error: msg })

  dismissError = () => {
    this.setState({ error: null })
  }

  handleHideTravellerDetails = () => {
    this.setState({
      traveller_modal_show: false,
      traveller_modal_data: null,
    })
  }

  renderToolbar = props => {
    const { departureDates, onDepartureDatesChange } = this.props

    return (
      <div className="search-bar">
        <OrdersPageFilters
          departureDates={departureDates}
          onDepartureDatesChange={onDepartureDatesChange}
        />

        {props.components.searchPanel}
      </div>
    )
  }

  render() {
    const {
      updatingData,
      orderItems,
      sizePerPage,
      currentPage,
      totalBookings,
      departureDates,
      onPageChange,
      onSearchChange,
    } = this.props

    let isEnableTravellers = false
    if (['tour', OFFER_TYPE_TOUR_V2].includes(this.props.offer.type) && this.props.isLETour) {
      isEnableTravellers = true
    }

    return (
      <div className="container orders-page">
        {this.state.error && (
          <Alert bsStyle="danger" onDismiss={this.dismissError}>
            <h4> An Error occurred while saving your changes </h4>
            <p> {this.state.error} </p>
          </Alert>
        )}

        {isEnableTravellers && (<DownloadTravellerPassportsModal
          show={this.state.download_traveller_passports_modal_show}
          onHide={() => { this.setState({ download_traveller_passports_modal_show: false }) }}
          departureDates={departureDates}
          offer={this.props.offer}
          offerId={this.props.offerId}
          vendorId={this.props.vendorId}
        />)}

        {isEnableTravellers && (<TravellerDetailsModal
          vendorId={this.props.vendorId}
          offer={this.props.offer}
          updateTravellerData={this.props.onUpdateTravellerData}
          show={this.state.traveller_modal_show}
          data={this.state.traveller_modal_data}
          onHide={this.handleHideTravellerDetails}
        />)}

        <div className="headings">
          <h3>Bookings</h3>
          <div className="action-buttons">
            {updatingData && (
              <MDSpinner className="spinner" size={50} />
            )}

            {isEnableTravellers && (<ExportButton
              isGenerating={this.state.generatingTravellerDetails}
              onClick={this.exportTravellerDetails}
              name="Traveller Details"
            />)}
            {isEnableTravellers && (<DownloadTravellerPassportsButton
              onClick={() => { this.setState({ download_traveller_passports_modal_show: true }) }}
            />)}
            <ExportButton
              isGenerating={this.state.generatingReport}
              onClick={this.exportBookings}
              name="Bookings"
            />
          </div>
        </div>

        <BootstrapTable
          data={orderItems}
          fetchInfo={{
            dataTotalSize: totalBookings,
          }}
          options={{
            toolBar: this.renderToolbar,
            page: currentPage,
            sizePerPage,
            sizePerPageList: [],
            searchDelayTime: 1000,
            clearSearch: true,
            onPageChange,
            onSearchChange,
          }}
          searchPlaceholder="Search by customer name, email or booking ID"
          remote
          search
          pagination
          hover
        >
          <TableHeaderColumn dataField="booking_number" dataFormat={this.renderId} isKey width="8%">ID</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderBookingCreated} width="10%">Booking Created</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderBooking} width="20%">Booking</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.props.offer.type === OFFER_TYPE_TOUR_V2 ? this.renderTourTravellers : this.renderPrimaryGuest} width="20%">{this.props.offer.type === OFFER_TYPE_TOUR_V2 ? 'Travellers' : 'Primary Guest'}</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderGuests}>{this.props.offer.type === OFFER_TYPE_TOUR_V2 ? 'Traveller Types' : 'Guests'}</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderPackage}>Package</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderCheckInDate}>{this.props.offer.type === OFFER_TYPE_TOUR_V2 ? 'Start Date' : 'Check-in Date'}</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderCheckOutDate}>{this.props.offer.type === OFFER_TYPE_TOUR_V2 ? 'End Date' : 'Check-out Date'}</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderStatus} width="10%">Status</TableHeaderColumn>
        </BootstrapTable>
      </div>
    )
  }
}
OrdersPage.contextTypes = {
  user: PropTypes.object,
}
