import React, { Component } from 'react'

export default class PrimaryGuestCellHotel extends Component {
  render() {
    const {
      row,
    } = this.props

    return (
      <div>
        <div>{row.guestname}</div>
        <div><strong>{row.guest_special_requests && 'Additional Request info:'}</strong> {row.guest_special_requests}</div>
      </div>
    )
  }
}
