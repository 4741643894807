import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Alert, Button } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment-timezone'

import Filters from './Filters'
import PurchasedWithCell from './PurchasedWithCell'
import CheckInDateCell from '../Common/CheckInDateCell'
import CheckOutDateCell from '../Orders/CheckOutDateCell'
import BookingCell from '../Common/BookingCell'
import ExportButton from '../Common/ExportButton'
import { updateVoucherStatus, getVoucher } from '../helpers/vouchers'
import { getCreatedAt, getCheckInDate, getCheckOutDate } from '../helpers/items'
import { hasItemReservation } from '../helpers/validators'
import { BUY_NOW_BOOK_LATER, CANCELLED } from '../../constants/order-constants'

export default class Page extends Component {
  constructor(props) {
    super(props)

    this.addon = props.addon
    this.total = props.total
    this.page = props.page
    this.sizePerPage = props.sizePerPage
    this.onPageChange = props.onPageChange

    this.state = {
      items: props.items,
      generatingReport: false,
      error: null,
    }
  }

  renderId(cell, row) {
    return (
      <div>
        <p><strong>{row.booking_number}</strong></p>
        <p><strong>{row.name}</strong></p>
      </div>
    )
  }

  renderBooking(cell, row) {
    return (
      <BookingCell
        offerID={row.id_offer}
        orderID={row.item.id_order}
        customerFullName={row.item.customer_full_name}
        customerEmail={row.item.customer_email}
        customerPhonePrefix={row.item.customer_phone_prefix}
        customerPhone={row.item.customer_phone}
        showResendVendorEmail={false}
      />
    )
  }

  renderPrimaryGuest(cell, row) {
    return (
      <div><strong>{row.item.guestname}</strong></div>
    )
  }

  renderDateOfPurchase(cell, row) {
    return moment.utc(getCreatedAt(row)).format('ddd, D MMM Y')
  }

  renderFareType(cell) {
    return <div>
      <span>{cell || '-'}</span>
    </div>
  }

  renderPurchasedWith(cell, row) {
    return <PurchasedWithCell row={row.item} />
  }

  renderCheckInDate = (cell, row) => {
    const checkInDate = getCheckInDate(row.item, row.voucher)
    const previousCheckInDate = getCheckInDate(row.item, row.voucher, true)
    const hasReservation = hasItemReservation(row.item)

    return (
      <CheckInDateCell
        checkInDate={checkInDate}
        previousCheckInDate={previousCheckInDate}
        type={row.item.type}
        hasReservation={hasReservation}
        voucher={row.voucher}
        status={row.status}
        surchargePaid={false}
      />
    )
  }

  renderCheckOutDate = (cell, row) => {
    const checkOutDate = getCheckOutDate(row.item, row.voucher)
    const previousCheckOutDate = getCheckOutDate(row.item, row.voucher, true)
    const hasReservation = hasItemReservation(row.item)

    return (
      <CheckOutDateCell
        checkOutDate={checkOutDate}
        previousCheckOutDate={previousCheckOutDate}
        hasReservation={hasReservation}
      />
    )
  }

  updateItemsWithVoucher = async({ offerId, bookingNumber }) => {
    try {
      const result = await getVoucher({
        vendorId: this.props.vendorId,
        offerId: offerId,
        bookingNumber: bookingNumber,
        noCache: moment().format('x'),
      })

      const items = this.state.items

      items.forEach((item) => {
        if (item.booking_number === bookingNumber) {
          item.voucher = result
        }
      })

      this.setState({
        items: items,
      })
    } catch (err) {
      this.setState({
        error: err.message,
      })
    }
  }

  onItemLog = async({ offerId, bookingNumber, isLogged }) => {
    try {
      await updateVoucherStatus({
        vendorId: this.props.vendorId,
        offerId: offerId,
        bookingNumber: bookingNumber,
        isLogged: isLogged,
      })
    } catch (err) {
      this.setState({
        error: err.message,
      })
    }

    await this.updateItemsWithVoucher({ offerId, bookingNumber })
  }

  dismissError = () => {
    this.setState({ error: null })
  }

  exportBookings = async() => {
    this.setState({ generatingReport: true })
    try {
      await this.props.exportBookings()
      this.setState({ generatingReport: false })
    } catch (err) {
      this.setState({
        error: err.message,
        generatingReport: false,
      })
    }
  }

  renderStatus = (cell, row) => {
    if (row.status === CANCELLED) {
      return <div className="subtext">Cancelled</div>
    }
    if (row.item.reservation_type === BUY_NOW_BOOK_LATER && !row.item.reservation_made) {
      return null
    }
    if (!row.voucher) {
      return
    }

    const voucher = row.voucher
    const isLogged = !!voucher.vendor_logged_at

    return (
      <div>
        {isLogged && <div className="subtext">Logged</div>}
        <Button bsStyle="link" onClick={() => {
          this.onItemLog({
            offerId: row.item.id_offer,
            bookingNumber: row.booking_number,
            isLogged: isLogged,
          })
        }}>{isLogged ? 'Unlog' : 'Log'}</Button>
      </div>
    )
  }

  render() {
    return (
      <div className="container addon-orders-page orders-page">
        {this.state.error && (
          <Alert bsStyle="danger" onDismiss={this.dismissError}>
            <h4> An Error occurred while saving your changes </h4>
            <p> {this.state.error} </p>
          </Alert>
        )}
        <div className="headings">
          <h3>Bookings</h3>
          <div className="action-buttons">
            <ExportButton
              isGenerating={this.state.generatingReport}
              onClick={this.exportBookings}
              name="Bookings"
            />
          </div>
        </div>

        <div className="subheadings">
          <h4>{this.addon.name}</h4>
        </div>

        <Filters
          filter={this.props.filter}
          onBookingNumbersSearch={this.props.onBookingNumbersSearch}
          onCustomerSearch={this.props.onCustomerSearch}
          onUnloggedSearch={this.props.onUnloggedSearch}
        />

        <BootstrapTable
          data={this.state.items}
          pagination
          remote
          fetchInfo={{ dataTotalSize: this.total }}
          striped
          hover
          bordered
          options={{
            sizePerPage: this.sizePerPage,
            onPageChange: this.onPageChange,
            page: this.page,
            sizePerPageList: [],
            searchDelayTime: 600,
          }}
        >
          <TableHeaderColumn dataField="booking_number" dataFormat={this.renderId} isKey width="15%">ID</TableHeaderColumn>
          <TableHeaderColumn dataField="fare_type" dataFormat={this.renderFareType} width="10%">Fare Type</TableHeaderColumn>
          <TableHeaderColumn dataField="fare_type_display" dataFormat={this.renderFareType}>Fare Type Display</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderDateOfPurchase} width="10%">Date Of Purchase</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderBooking} width="10%">Booking</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderPrimaryGuest} width="10%">Primary Guest</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderPurchasedWith} width="10%">Purchased with</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderCheckInDate} width="10%">Hotel Check-in Date</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderCheckOutDate} width="10%">Hotel Check-out Date</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderStatus}>Status</TableHeaderColumn>
        </BootstrapTable>
      </div>
    )
  }
}
Page.contextTypes = {
  user: PropTypes.object,
}
