import { request } from './common'

export default class PropertyService {
  static listPropertiesByVendor(page = 1, limit = 15, idVendor, search = '') {
    const requestParams = `${window.configs.OFFERS_HOST}/api/properties?include_deleted=true&id_salesforce_external=${idVendor}&page=${page}&limit=${limit}&name=${search}`
    return request(requestParams, { method: 'GET' })
  }

  static getProperty(idProperty) {
    const requestParams = `${window.configs.OFFERS_HOST}/api/properties/${idProperty}`
    return request(requestParams, { method: 'GET' })
  }
}
