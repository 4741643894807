import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

// 12678523 ~ 12 Mb in bytes
const PHOTO_UPLOAD_SIZE_LIMIT = 12678523

export default class UploadImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      image: props.image || '/assets/passport-scan.png',
    }

    this.fileInputRef = null
    this.styleFileInput = {
      position: 'absolute',
      top: '0px',
      right: '0px',
      left: '0px',
      bottom: '0px',
      opacity: '0.00001',
      pointerEvents: 'none',
    }
  }

  setFileInputRef = (fileInputRef) => {
    this.fileInputRef = fileInputRef
  }

  handleChange = (files) => {
    try {
      if (!files[0]) {
        this.props.handler(null, 'You can upload only images')
        return
      }

      if (files[0].size > PHOTO_UPLOAD_SIZE_LIMIT) {
        this.props.handler(null, 'Your upload image should not exceed 12 Mb')
        return
      }

      const reader = new window.FileReader()
      reader.onload = () => {
        const fileAsBase64String = reader.result
        this.setState({
          image: fileAsBase64String,
        })
        this.props.handler(fileAsBase64String, false)
      }

      reader.readAsDataURL(files[0])
    } catch (error) {
      this.props.handler(null, error.message)
    }
  }

  render() {
    return (
      <div className="upload-image">
        <div className="preview-image-block">
          <div className="preview-image-img">
            <img style={{ width: '200px' }} src={this.state.image} alt="preview"/>
          </div>
        </div>
        <div className="select-image-block">
          <div className="select-image-button">
            <Button onClick={() => this.fileInputRef.click()} disabled={this.props.disabled}>Select Image</Button>
          </div>
          <input
            ref={this.setFileInputRef}
            accept="image/*"
            type="file"
            style={this.styleFileInput}
            autoComplete="off"
            onChange={(e) => this.handleChange(e.target.files)} />
        </div>
      </div>
    )
  }
}
