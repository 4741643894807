import PropTypes from 'prop-types'
import React from 'react'
import MDSpinner from 'react-md-spinner'

import OffersService from '../../services/OffersService'
import OrderService from '../../services/OrderService'
import Page from './Page'
import ErrorDisplay from '../Common/ErrorDisplay'
import { getAddonItemsFromOrders } from '../helpers/getAddonItems'
import exportBookingsToCSV from '../../exports/addons/bookings'

export default class AddonOrdersPageContainer extends React.Component {
  constructor(props) {
    super(props)

    const {
      id_vendor: vendorId,
      id_addon: addonId,
    } = props.params

    this.defaultFilter = {
      unlogged: false,
      customer: '',
      bookingNumbers: '',
    }

    this.state = {
      loading: true,
      page: 1,
      items: [],
      total: 0,
      filter: this.defaultFilter,
    }

    this.addon = null
    this.addonId = addonId
    this.vendorId = vendorId
    this.sizePerPage = 10
    this.sizePerPageUnlogged = 150
  }

  componentDidMount = async() => {
    try {
      await this.fetchAddon()
      await this.fetchData()
    } catch (err) {
      this.setState({ loading: false, error: err.message })
    }
  }

  fetchAddon = async() => {
    this.addon = await OffersService.getVendorAddon(this.vendorId, this.addonId)
  }

  fetchData = async() => {
    this.setState({ loading: true })
    try {
      let items = []
      let total = 0

      const params = {
        page: this.state.page,
        perPage: this.sizePerPage,
        addonOpportunityId: this.addonId,
        vendorId: this.vendorId,
        showCancelled: !this.state.filter.unlogged,
        bookingNumbers: this.state.filter.bookingNumbers.split(','),
        customer: this.state.filter.customer,
        unlogged: this.state.filter.unlogged,
      }

      const response = await OrderService.getAddons(params)
      total = response.total

      items = await getAddonItemsFromOrders({
        vendorId: this.vendorId,
        addonId: this.addonId,
        addons: response.result,
        showCancelled: params.showCancelled,
      })

      this.setState({ loading: false, items, total })
    } catch (err) {
      this.setState({ loading: false, error: err.message })
    }
  }

  onBookingNumbersSearch = (bookingNumbers) => {
    this.setState({
      filter: Object.assign({}, this.defaultFilter, {
        bookingNumbers: bookingNumbers,
      }),
    }, () => {
      this.fetchData()
    })
  }

  onCustomerSearch = (customer) => {
    this.setState({
      filter: Object.assign({}, this.defaultFilter, {
        customer: customer,
      }),
    }, () => {
      this.fetchData()
    })
  }

  onUnloggedSearch = (unlogged) => {
    this.setState({
      filter: Object.assign({}, this.defaultFilter, {
        unlogged: unlogged,
      }),
    }, () => {
      this.fetchData()
    })
  }

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.fetchData()
    })
  }

  exportBookings = async() => {
    await exportBookingsToCSV({
      vendorId: this.vendorId,
      addonOpportunityId: this.addonId,
      showCancelled: !this.state.filter.unlogged,
      bookingNumbers: this.state.filter.bookingNumbers.split(','),
      customer: this.state.filter.customer,
      unlogged: this.state.filter.unlogged,
    })
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="spinner-container">
          <MDSpinner className="spinner" size={100} />
        </div>
      )
    }
    if (this.state.error) {
      return (
        <div className="container">
          <ErrorDisplay message={this.state.error} />
        </div>
      )
    }

    return (
      <Page
        items={this.state.items}
        sizePerPage={this.sizePerPage}
        total={this.state.total}
        page={this.state.page}
        addon={this.addon}
        addonId={this.addonId}
        vendorId={this.vendorId}
        onPageChange={this.onPageChange}
        filter={this.state.filter}
        onBookingNumbersSearch={this.onBookingNumbersSearch}
        onCustomerSearch={this.onCustomerSearch}
        onUnloggedSearch={this.onUnloggedSearch}
        exportBookings={this.exportBookings}
      />
    )
  }
}

AddonOrdersPageContainer.contextTypes = {
  user: PropTypes.object,
}
