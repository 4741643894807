import moment from 'moment-timezone'

export const getNumberOfAdults = (travellers) => {
  if (!travellers) {
    return 0
  }

  return travellers.reduce((numberOfAdults, traveller) => traveller.type === 'adult' ? numberOfAdults + 1 : numberOfAdults, 0)
}

export const getNumberOfChildren = (travellers) => {
  if (!travellers) {
    return 0
  }

  return travellers.reduce((numberOfChildren, traveller) => traveller.type === 'child' ? numberOfChildren + 1 : numberOfChildren, 0)
}

export const getDealOptionName = (tourOffer, tourOptionId) => {
  const tourOption = tourOffer?.tourOptions?.find(tourOption => tourOption.tourOptionId === tourOptionId)
  return tourOption?.dealOptionName ?? ''
}

export const getTourDuration = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return null
  }

  const tourStartDate = moment(startDate)
  const tourEndDate = moment(endDate)

  return moment.duration(tourEndDate.diff(tourStartDate)).add(1, 'day').days()
}

export const getSpecialRequests = (tourReservation) => {
  if (!tourReservation) {
    return ''
  }

  return tourReservation.rooms.map(room => room.specialRequests).filter(Boolean)
}

export const getCustomerPhoneWithCountryCallingCode = (customerPhonePrefix, customerPhone) => {
  if (customerPhonePrefix) {
    return `(+${customerPhonePrefix}) ${customerPhone}`
  }

  return customerPhone
}

export const getLeadPassengerName = (travellers) => {
  if (!travellers) {
    return ''
  }

  const leadPassenger = travellers.find(traveller => traveller?.is_lead_passenger === true)

  if (leadPassenger) {
    return `${leadPassenger.first_name ?? ''} ${leadPassenger.last_name ?? ''}`
  }

  return ''
}

export const sortTourTravellers = (travellers, sortProperties) => {
  if (!Array.isArray(travellers) || !Array.isArray(sortProperties) || sortProperties.length === 0) {
    return travellers
  }

  const sortCriteria = new Map(
    sortProperties.map((property, index) => [
      sortProperties[index - 1],
      property,
    ]),
  )

  function getTourTravellerSortComparator(propertyName) {
    return function(travellerOne, travellerTwo) {
      let nextProperty

      if (travellerOne[propertyName] < travellerTwo[propertyName]) {
        return -1
      } else if (travellerOne[propertyName] > travellerTwo[propertyName]) {
        return 1
      } else if ((nextProperty = sortCriteria.get(propertyName))) {
        return getTourTravellerSortComparator(nextProperty)(
          travellerOne,
          travellerTwo,
        )
      }
    }
  }

  return travellers.sort(getTourTravellerSortComparator(sortProperties[0]))
}
