import { request } from './common'
import qs from 'qs'
import filterRemittance from '../utils/filterRemittance'
import fileDownload from 'js-file-download'
import contentDisposition from 'content-disposition'

export default class VendorService {
  static get(idVendor) {
    const requestUri = window.configs.VENDOR_HOST + '/api/vendor/' + idVendor
    return request(requestUri, { method: 'GET' })
  }

  static getRemittance(vendorId, limit = 200) {
    const queryParams = {
      limit,
    }

    queryParams.vendor_id = vendorId

    const url = `${window.configs.VENDOR_HOST}/api/vendor/remittance?${qs.stringify(queryParams)}`

    return request(url, { method: 'GET' })
  }

  static async getAllRemittance(emailSent, vendorId, limit = 200) {
    const remittance = await this.getRemittance(vendorId, limit)

    const result = filterRemittance(
      remittance.result,
      emailSent,
    )

    return result
  }

  static async downloadRemittance(id, type = 'pdf', vendorId) {
    const queryParams = {
      type,
      vendor_id: vendorId,
    }

    // eslint-disable-next-line no-undef
    const response = await fetch(
      `${window.configs.VENDOR_HOST}/api/vendor/remittance/${id}/download?${qs.stringify(queryParams)}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      },
    )

    const body = await response.blob()

    const fileNameHeader = contentDisposition.parse(
      response.headers.get('content-disposition'),
    )

    await fileDownload(body, fileNameHeader.parameters.filename)
  }
}
