import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const Alert = ({ children, show, onHide, size = 'small' }) => (
  <Modal className="static-modal" bsSize={size} show={show} onHide={onHide}>
    <Modal.Header>
      <div className="floating-close-button">
        <Button bsStyle="link" onClick={onHide}>X</Button>
      </div>
    </Modal.Header>
    <Modal.Body>
      {children}
    </Modal.Body>
    <Modal.Footer>
      <Button bsSize="large" className="btn-outline" onClick={onHide}>OK</Button>
    </Modal.Footer>
  </Modal>
)

export default Alert
