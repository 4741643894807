import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import MDSpinner from 'react-md-spinner'
import { Alert } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

import GuestsCell from '../Common/GuestsCell'
import BookingCell from '../Common/BookingCell'
import OrdersPageFilters from './OrdersPageFilters'
import ExportButton from '../Common/ExportButton'
import { DownloadTravellerPassportsButton, DownloadTravellerPassportsModal } from './DownloadTravellerPassports'
import { TravellerDetailsModal } from './TravellerDetails'
import TourTravellersCell from './TourTravellersCell'
import { TourV2StatusCell } from './TourV2StatusCell'
import { CANCELLED } from '../../constants/order-constants'

const DATE_FORMAT = 'DD-MMM-YYYY'

export default class TourV2OrdersPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      download_traveller_passports_modal_show: false,
      traveller_modal_show: false,
      traveller_modal_data: null,
      error: null,
      generatingReport: false,
      generatingTravellerDetails: false,
    }
  }

  renderId(cell, row) {
    return (
      <div><strong>{row.booking_number}</strong></div>
    )
  }

  renderBooking(cell, row) {
    return (
      <BookingCell
        offerID={row.id_offer}
        orderID={row.id_order}
        customerFullName={row.customer_full_name}
        customerEmail={row.customer_email}
        customerPhonePrefix={row.customer_phone_prefix}
        customerPhone={row.customer_phone}
        showResendVendorEmail
      />
    )
  }

  renderDealOption(cell, row) {
    return (
      <div>
        <div><strong>{row.deal_option_name}</strong></div>
        <div>{row.tour_duration ? `${row.tour_duration} days` : ''}</div>
      </div>
    )
  }

  renderGuests(cell, row) {
    return <GuestsCell row={row} />
  }

  renderTourTravellers = (cell, row) => <TourTravellersCell
    row={row}
    setTravellerData={this.setTravellerData}
    isLETour={this.props.isLETour}
  />

  setTravellerData = (travellerData) => {
    this.setState({
      traveller_modal_show: true,
      traveller_modal_data: travellerData,
    })
  }

  renderCheckInDate = (cell, row) => moment(row.start_date).format(DATE_FORMAT)

  renderCheckOutDate = (cell, row) => moment(row.end_date).format(DATE_FORMAT)

  renderStatus = (cell, row) => {
    if (row.status === CANCELLED) {
      return <div className="subtext">Cancelled</div>
    }

    return <TourV2StatusCell tourItem={row} onTourV2ItemLog={this.props.onTourV2ItemLog} onError={this.handleError}/>
  }

  renderSpecialRequests = (cell, row) => (
    <div>
      {row.special_requests.map(special_request => (
        <div>{special_request}</div>
      ))}
    </div>
  )

  exportBookings = async() => {
    this.setState({ generatingReport: true })
    try {
      await this.props.exportBookings()
      this.setState({ generatingReport: false })
    } catch (err) {
      this.setState({
        error: err.message,
        generatingReport: false,
      })
    }
  }

  exportTravellerDetails = async() => {
    this.setState({ generatingTravellerDetails: true })
    try {
      await this.props.exportTravellerDetails()
      this.setState({ generatingTravellerDetails: false })
    } catch (err) {
      this.setState({
        error: err.message,
        generatingTravellerDetails: false,
      })
    }
  }

  handleError = msg => this.setState({ error: msg })

  dismissError = () => {
    this.setState({ error: null })
  }

  handleHideTravellerDetails = () => {
    this.setState({
      traveller_modal_show: false,
      traveller_modal_data: null,
    })
  }

  renderToolbar = props => {
    const { departureDates, onDepartureDatesChange } = this.props

    return (
      <div className="search-bar">
        <OrdersPageFilters
          departureDates={departureDates}
          onDepartureDatesChange={onDepartureDatesChange}
        />

        {props.components.searchPanel}
      </div>
    )
  }

  render() {
    const {
      updatingData,
      orderItems,
      sizePerPage,
      currentPage,
      totalBookings,
      departureDates,
      onPageChange,
      onSearchChange,
    } = this.props

    const isEnableTravellers = this.props.isLETour

    return (
      <div className="container orders-page">
        {this.state.error && (
          <Alert bsStyle="danger" onDismiss={this.dismissError}>
            <h4> An Error occurred while saving your changes </h4>
            <p> {this.state.error} </p>
          </Alert>
        )}

        {isEnableTravellers && (<DownloadTravellerPassportsModal
          show={this.state.download_traveller_passports_modal_show}
          onHide={() => { this.setState({ download_traveller_passports_modal_show: false }) }}
          departureDates={departureDates}
          offer={this.props.offer}
          offerId={this.props.offerId}
          vendorId={this.props.vendorId}
        />)}

        {isEnableTravellers && (<TravellerDetailsModal
          vendorId={this.props.vendorId}
          offer={this.props.offer}
          updateTravellerData={this.props.onUpdateTravellerData}
          show={this.state.traveller_modal_show}
          data={this.state.traveller_modal_data}
          onHide={this.handleHideTravellerDetails}
        />)}

        <div className="headings">
          <h3>Bookings</h3>
          <div className="action-buttons">
            {updatingData && (
              <MDSpinner className="spinner" size={50} />
            )}

            {isEnableTravellers && (<ExportButton
              isGenerating={this.state.generatingTravellerDetails}
              onClick={this.exportTravellerDetails}
              name="Traveller Details"
            />)}
            {isEnableTravellers && (<DownloadTravellerPassportsButton
              onClick={() => { this.setState({ download_traveller_passports_modal_show: true }) }}
            />)}
            <ExportButton
              isGenerating={this.state.generatingReport}
              onClick={this.exportBookings}
              name="Bookings"
            />
          </div>
        </div>

        <BootstrapTable
          data={orderItems}
          fetchInfo={{
            dataTotalSize: totalBookings,
          }}
          options={{
            toolBar: this.renderToolbar,
            page: currentPage,
            sizePerPage,
            sizePerPageList: [],
            searchDelayTime: 1000,
            clearSearch: true,
            onPageChange,
            onSearchChange,
          }}
          searchPlaceholder="Search by customer name, email or booking ID"
          remote
          search
          pagination
          hover
        >
          <TableHeaderColumn dataField="booking_number" dataFormat={this.renderId} isKey width="8%">ID</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderBooking} width="20%">Booking</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderTourTravellers} width="20%">Travellers</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderSpecialRequests}>Special Requests</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderGuests}>Traveller Types</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderDealOption}>Deal Option Name</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderCheckInDate}>Start Date</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderCheckOutDate}>End Date</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderStatus} width="10%">Status</TableHeaderColumn>
        </BootstrapTable>
      </div>
    )
  }
}
TourV2OrdersPage.contextTypes = {
  user: PropTypes.object,
}
