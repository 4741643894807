import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import MDSpinner from 'react-md-spinner'
import { formatDate } from '../helpers/items'

export default class AllowPutOnHoldModal extends Component {
  render() {
    const { showRejectBNBLModal, hideRejectBNBLModal, loadingOffer, offer, offerError, onConfirmBNBL, handleStepToConfirmation } = this.props
    return (
      <Modal className="static-modal dates-request-reject" bsSize="small" show={showRejectBNBLModal} onHide={hideRejectBNBLModal}>
        <Modal.Header closeButton>
          <Modal.Title>Offer the customer to convert their booking to Buy Now, Book Later?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingOffer && <MDSpinner size={30} />}
          {!loadingOffer && offer && offer.book_by_date && offer.travel_to_date &&
            <p>Would you allow the customer to convert their booking to "Buy Now, Book Later", which would let the customer book by {formatDate(offer.book_by_date, null, 'DD/MM/YYYY')} and travel by {formatDate(offer.travel_to_date, null, 'DD/MM/YYYY')}? Dates will be subject to room availability.</p>
          }
          {!loadingOffer && offer && (!offer.book_by_date || !offer.travel_to_date) &&
            <p>Would you allow the customer to convert their booking to "Buy Now, Book Later", which would let the customer book by the dates specified in the offer campaign? Dates will be subject to room availability.</p>
          }
          {offerError && <div className="alert alert-danger">{offerError}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize="large" variant="secondary" className="btn-dates-request" onClick={handleStepToConfirmation}>No</Button>
          <Button
            bsSize="large"
            variant="primary"
            className="btn-primary btn-dates-request"
            onClick={onConfirmBNBL}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
