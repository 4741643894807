import React, { Component } from 'react'

export default class PurchasedWithCell extends Component {
  getOfferLink = ({ offer_slug: offerSlug, id_offer: offerId }) => `${window.configs.LE_CUSTOMER_PORTAL}/offer/${offerSlug}/${offerId}`

  render() {
    const {
      row,
    } = this.props

    return (
      <div>
        <p>{row.reservation.property_name}</p>
        <p>
          <a href={this.getOfferLink(row)} className="link" target="_blank">View Offer</a>
        </p>
      </div>
    )
  }
}
