import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Authenticated, NotAuthenticated } from 'react-stormpath'
import moment from 'moment-timezone'
import OffersPage from './Offers/OffersPageContainer'
import LoginPage from './Profile/LoginPage'

export default class IndexPage extends Component {
  showLastLogin() {
    if (this.context.user) {
      const lastLogin = this.context.user.lastLogin
      if (lastLogin.login_date) {
        moment.tz.setDefault(window.configs.DEFAULT_TIMEZONE)
        const lastLoginDate = new Date(lastLogin.login_date)
        return (
          <div className="text-center">You last logged in {moment(lastLoginDate).fromNow()} {moment(lastLoginDate).calendar()} </div>
        )
      }
    }
  }

  render() {
    return (
      <div className="container">
        <Authenticated>
          {this.context.vendor ? (
            <OffersPage vendor={this.context.vendor} />
          ) : (
            <div>
              <h2 className="text-center">
                Welcome
                { this.context.user ? ' ' + this.context.user.givenName : null }!
              </h2>
              <hr />
              <h4 className="text-center">{ this.context.user && !this.context.user.roles.includes('vendor-user') ? ' You have Restricted Access because only vendor user is supported at the moment.' : null }</h4>
              {this.showLastLogin()}
            </div>
          )}
        </Authenticated>
        <NotAuthenticated>
          <LoginPage />
        </NotAuthenticated>
      </div>
    )
  }
}

IndexPage.contextTypes = {
  user: PropTypes.object,
  vendor: PropTypes.object,
}
