import React from 'react'

const IMAGE_PREFIX = 'https://images.luxuryescapes.com/lux-group/image/upload/w_180/'

const Image = ({ id }) => (
  <div className="offer-image">
    <img alt="offer" src={IMAGE_PREFIX + id + '.png'} />
  </div>
)

export default Image
