import React from 'react'
import { Form, FormGroup, FormControl, ControlLabel, Col } from 'react-bootstrap'

const getAddress = ({ billingstreet, billingcity }) => [billingstreet, billingcity].join(', ')

const value = (val) => val || ''

export default function VendorInformation({ vendor }) {
  return (
    <div>
      <div className="section">
        <h4>Account Information</h4>
        <Form horizontal>
          <FormGroup>
            <Col sm={12} md={6}>
              <ControlLabel>Website</ControlLabel>
              <FormControl type="text" value={value(vendor.website)} readOnly />
            </Col>
            <Col sm={12} md={6}>
              <ControlLabel>Phone Number</ControlLabel>
              <FormControl type="text" value={value(vendor.phone)} readOnly />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm={12} md={12}>
              <ControlLabel>Address</ControlLabel>
              <FormControl type="text" value={value(getAddress(vendor))} readOnly />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm={12} md={6}>
              <ControlLabel>Country</ControlLabel>
              <FormControl type="text" value={value(vendor.billingcountry)} readOnly />
            </Col>
          </FormGroup>
        </Form>
      </div>
      <div className="section">
        <h4>Bank Account Details</h4>
        <Form horizontal>
          <FormGroup>
            <Col sm={12} md={6}>
              <ControlLabel>Account Holder</ControlLabel>
              <FormControl type="text" value={value(vendor.aus_bank_account_name__c)} readOnly />
            </Col>
            <Col sm={12} md={6}>
              <ControlLabel>GST</ControlLabel>
              <FormControl type="text" value={value(vendor.gst_number__c)} readOnly />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm={12} md={6}>
              <ControlLabel>Account Number</ControlLabel>
              <FormControl type="text" value={value(vendor.aus_bank_account_number__c)} readOnly />
            </Col>
            <Col sm={12} md={6}>
              <ControlLabel>Bank Name</ControlLabel>
              <FormControl type="text" value={value(vendor.aus_bank_name__c)} readOnly />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm={12} md={6}>
              <ControlLabel>Bank Code</ControlLabel>
              <FormControl type="text" value={value(vendor.aus_bank_bsb__c)} readOnly />
            </Col>
          </FormGroup>
        </Form>
      </div>
    </div>
  )
}
