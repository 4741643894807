import { request } from './common'

export default class TravellerService {
  static async getDetails(orderId, withoutPassportPhoto = false, submittedOnly = true) {
    const requestUri = `${window.configs.TRAVELLER_HOST}/api/traveller/tour-details/${orderId}?submitted_only=${(submittedOnly ? 'true' : 'false')}&without_passport_photo=${(withoutPassportPhoto ? 'true' : 'false')}`
    const response = await request(requestUri, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })

    if (response.result && response.result.travellers) {
      return response.result.travellers
    }

    return []
  }

  static async updateDetails(data) {
    const requestUri = `${window.configs.TRAVELLER_HOST}/api/traveller/tour-details`
    const response = await request(requestUri, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })

    return response.result
  }
}
