import React, { Component } from 'react'
import { Modal, Button, Label, FormGroup, FormControl, Checkbox, HelpBlock, Alert as AlertBootstrap, Glyphicon } from 'react-bootstrap'
import MDSpinner from 'react-md-spinner'
import DatesRequestsService from '../../services/DatesRequestsService'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import OrderService from '../../services/OrderService'
import OffersService from '../../services/OffersService'
import GuestsCell from '../Common/GuestsCell'
import PrimaryGuestCellHotel from './PrimaryGuestCellHotel'
import ErrorDisplay from '../Common/ErrorDisplay'
import { datesRequestWithOrderDetails, formatDate } from '../helpers/items'
import Alert from '../Common/Alert'
import RefundRequestReasonCell from './RefundRequestReasonCell'
import moment from 'moment'
import AllowPutOnHoldModal from '../Common/AllowPutOnHoldModal'

const REJECTION_MESSAGE_PLACEHOLDER = `- We can't open the 22nd but we could open the 23rd or 24th
- We can only open up weekdays in July
- We are sold out in October but have dates available in November `

export default class DatesRequests extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      sizePerPage: 10,
      totalDatesRequests: 0,
      loading: true,
      datesRequests: [],
      error: null,
      queryDatesRequestId: props.location.query?.datesRequestId,
      confirmationModalShow: false,
      currentDatesRequest: null,
      rejectionNoFeedback: false,
      rejectionMessage: '',
      feedbackPlaceholder: REJECTION_MESSAGE_PLACEHOLDER,
      approvalModalShow: false,
      showOutsidePolicyApprovalModal: false,
      showRejectBNBLModal: false,
      offer: null,
      loadingOffer: false,
      offerError: null,
      downloadingAttachments: [],
    }
    this.vendorId = props.params.id_vendor
  }

  componentDidMount = async() => {
    this.fetchData()
  }

  handlePageChange = page => {
    this.setState({ page: page }, this.fetchData)
  }

  fetchData = async() => {
    this.setState({ loading: true, error: null })
    const { page, sizePerPage, queryDatesRequestId } = this.state
    try {
      const { result: datesRequests, total: totalDatesRequests } = await DatesRequestsService.fetchDatesRequests({ page, limit: sizePerPage, vendorId: this.vendorId })

      const queryFilterDatesRequest = queryDatesRequestId ?
        datesRequests.filter((datesRequest) => datesRequest.id_dates_request === queryDatesRequestId) : datesRequests

      const idOrders = queryFilterDatesRequest.map(d => d.fk_order_id).join(',')
      const { result: orderMeta } = await OrderService.getCompletedVendorPurchases({ idOrders, showBNBL: true, vendorId: this.vendorId })

      const ordersDetails = queryFilterDatesRequest.reduce((acc, datesRequest) => {
        const order = orderMeta.find(o => o.id === datesRequest.fk_order_id)
        const item = order && order.items.find(i => i.id === datesRequest.fk_item_id)
        const newAcc = item ? [...acc, datesRequestWithOrderDetails(datesRequest, order, item)] : acc
        return newAcc
      }, [])

      this.setState({
        loading: false,
        datesRequests: ordersDetails,
        totalDatesRequests: totalDatesRequests ?? 0,
      })
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  fetchOfferDetails = async(offerId) => {
    this.setState({ loadingOffer: true, offerError: null })

    try {
      const offer = await OffersService.getOfferById(offerId)
      this.setState({ offer, loadingOffer: false })
    } catch (error) {
      this.setState({ offerError: 'Failed to load offer details.', loadingOffer: false })
    }
  }

  downloadPdf = async(datesRequestId, guestName, attachment, index) => {
    try {
      this.setState((prevState) => ({
        downloadingAttachments: [...prevState.downloadingAttachments, attachment],
      }))
      await DatesRequestsService.getDatesRequestAttachmentUrl(datesRequestId, guestName, attachment, index)
      this.setState((prevState) => ({
        downloadingAttachments: prevState.downloadingAttachments.filter(a => a !== attachment),
      }))
    } catch (e) {
      this.setState((prevState) => ({
        downloadingAttachments: prevState.downloadingAttachments.filter(a => a !== attachment),
      }))
    }
  }

  renderDocumentLink = (_, row) => {
    const { downloadingAttachments } = this.state
    const createdInLast21Days = moment(row.created_at, 'DD/MM/YYYY').isAfter(moment().subtract(21, 'days'))

    if (row.attachments?.length > 0 && createdInLast21Days) {
      return (
        <div>
          { row.attachments.map((attachment, index) => (
            <div key={index}>
              {downloadingAttachments.includes(attachment) && (
                <MDSpinner
                  style={{
                    display: 'block',
                    margin: '0 auto',
                  }}
                  size={30}
                />
              )}
              {!downloadingAttachments.includes(attachment) && (
                <Button
                  bsStyle="link"
                  onClick={() => this.downloadPdf(row.id_dates_request, row.guestname, attachment, index + 1)}
                  style={{
                    fontSize: '30px',
                    display: 'block',
                    margin: '0 auto',
                  }}
                >
                  <Glyphicon glyph="download" bsSize="large" style={{ color: 'blue' }} />
                </Button>
              )}
            </div>
          ))}
        </div>
      )
    }
    return <div>N/A</div>
  }

  onPageChange = page => {
    this.setState({ page }, this.fetchData)
  }

  approveDatesRequest = (datesRequestId, isOutsidePolicy) => {
    this.updateDatesRequest('approved', datesRequestId, null, isOutsidePolicy)
  }

  rejectDatesRequest = (datesRequestId, isOutsidePolicy, offerId) => {
    this.setState({
      currentDatesRequest: datesRequestId,
    })
    if (isOutsidePolicy) {
      this.displayRejectBNBLModal(offerId)
    } else {
      this.showConfirmationModal()
    }
  };

  onConfirmBNBL = () => {
    this.updateDatesRequest('approved_bnbl', this.state.currentDatesRequest)
    this.hideRejectBNBLModal()
  }

  onConfirmReject = () => {
    this.updateDatesRequest('rejected', this.state.currentDatesRequest, this.state.rejectionMessage)
    this.hideConfirmationModal()
  }

  displayRejectBNBLModal = (offerId) => {
    this.setState({ showRejectBNBLModal: true })
    this.fetchOfferDetails(offerId)
  }

  showConfirmationModal = () => {
    this.setState({ confirmationModalShow: true })
  }

  hideRejectBNBLModal = () => {
    this.setState({
      currentDatesRequest: null,
      showRejectBNBLModal: false,
    })
    this.resetOfferState()
  }

  handleStepToConfirmation = () => {
    this.setState({
      showRejectBNBLModal: false,
    })
    this.resetOfferState()
    this.showConfirmationModal()
  }

  hideConfirmationModal = () => {
    this.setState({
      currentDatesRequest: null,
      confirmationModalShow: false,
    })
  }

  resetOfferState = () => {
    this.setState({
      offer: null,
      loadingOffer: false,
      offerError: null,
    })
  }

  toggleRejectionFeedback = () => {
    if (!this.state.rejectionNoFeedback) {
      this.setState({ rejectionMessage: '' })
    }
    this.setState({
      rejectionNoFeedback: !this.state.rejectionNoFeedback,
    })
  }

  setRejectionMessage = (e) => {
    this.setState({
      rejectionNoFeedback: false,
      rejectionMessage: e?.target?.value,
    })
  }

  updateDatesRequest = async(status, datesRequestId, rejectionMessage, isOutsidePolicy) => {
    this.setState({ loading: true, error: null })
    try {
      await DatesRequestsService.updateDatesRequests(status, datesRequestId, rejectionMessage)
      this.fetchData()
      if (status === 'approved') {
        if (isOutsidePolicy) {
          this.setState({
            showOutsidePolicyApprovalModal: true,
          })
        } else {
          this.setState({
            approvalModalShow: true,
          })
        }
      }
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  renderOfferLink(cell, row) {
    return (
      <div className="offer-id">
        {row.offer_id} <a href={row.offer_link} className="link" target="_blank">(View)</a>
      </div>
    )
  }

  renderBooking(cell, row) {
    return (
      <div>
        <div><strong>{row.guestname}</strong></div>
        <div>{row.customer_email}</div>
        <div>{row.customer_phone_prefix && `(+${row.customer_phone_prefix}) `}{row.customer_phone}</div>
      </div>
    )
  }

  renderPrimaryGuest = (cell, row) => <PrimaryGuestCellHotel row={row} />

  renderGuests(cell, row) {
    return <GuestsCell row={row} />
  }

  renderId(cell, row) {
    return (
      <div><strong>{row.booking_number}</strong></div>
    )
  }

  renderPackage(cell, row) {
    const packageDuration = row.package_nights ? 'nights' : 'days'

    return (
      <div>
        <div><strong>{row.package_name}</strong></div>
        <div>{row.package_nights || row.package_days} {packageDuration}</div>
      </div>
    )
  }

  renderReason = (_, row) => {
    if (!row.is_outside_policy) {
      return <div>N/A</div>
    }

    return <RefundRequestReasonCell reasonText={row.reason} />
  }

  renderType = (cell, row) => {
    const textColour = row.is_outside_policy ? 'text-danger' : 'text-success'

    return (
      <div className={`${textColour} text-wrap fw-bold`}>
        {row.is_outside_policy ? 'Outside Policy' : 'Within Policy'}
      </div>
    )
  }

  renderStatus = (cell, row) => {
    const isPending = row.status === 'pending'
    const statusStyleMap = {
      pending: 'default',
      approved: 'success',
      approved_bnbl: 'success',
      rejected: 'danger',
      customer_booked: 'info',
      customer_cancelled: 'info',
      customer_put_on_hold: 'info',
      expired: 'info',
      auto_rejected_pending: 'danger',
      auto_rejected: 'danger',
      cancelled: 'info',
    }
    const statusLabelMap = {
      pending: 'Pending',
      approved: 'Approved Requested Dates',
      approved_bnbl: 'Approved for Buy Now, Book Later',
      rejected: 'Rejected',
      customer_booked: 'Customer booked dates',
      customer_cancelled: 'Customer cancelled',
      customer_put_on_hold: 'Customer converted to Buy Now, Book Later',
      expired: 'Expired',
      auto_rejected_pending: 'Rejected',
      auto_rejected: 'Rejected',
      cancelled: 'Customer withdrawn',
    }

    const displayStatus = statusLabelMap[row.status] || 'Unknown'

    return (
      <div>
        {!isPending && <Label
          className="btn-dates-request"
          bsStyle={statusStyleMap[row.status]}
          style={{
            whiteSpace: 'normal',
          }}
        >
          {displayStatus}
        </Label>}
        {isPending && (
          <div>
            <Button className="btn-dates-request" bsStyle="success" bsSize="xsmall" onClick={() => {
              this.approveDatesRequest(row.id_dates_request, !!row.is_outside_policy)
            }}>
              Approve
            </Button>
            <Button className="btn-dates-request" bsStyle="danger" bsSize="xsmall" onClick={() => {
              this.rejectDatesRequest(row.id_dates_request, !!row.is_outside_policy, row.offer_id ?? row.id_offer)
            }}>
              Reject
            </Button>
          </div>
        )}
      </div>
    )
  }

  render() {
    const {
      datesRequests,
      page,
      sizePerPage,
      totalDatesRequests,
      loading,
      confirmationModalShow,
      rejectionNoFeedback,
      feedbackPlaceholder,
      approvalModalShow,
      showOutsidePolicyApprovalModal,
      showRejectBNBLModal,
      loadingOffer,
      offer,
      offerError,
    } = this.state
    const disableConfirmReject = (!rejectionNoFeedback && (!this.state.rejectionMessage || this.state.rejectionMessage.length < 7))

    return (
      <div className="dates-requests">
        <Alert size="medium" show={approvalModalShow} onHide={() => { this.setState({ approvalModalShow: false }) }}>
          <h4>Thank you!</h4>
          <p style={{ textAlign: 'left' }}>A reminder that <b>this booking is not yet complete.</b> The Luxury Escapes customer support team has been notified to help the customer book these dates. </p>
          <p style={{ textAlign: 'left' }}>If your property is channel managed, they will reach out soon to coordinate the addition of inventory so that it is allocated to this customer.</p>
        </Alert>
        <Alert size="medium" show={showOutsidePolicyApprovalModal} onHide={() => { this.setState({ showOutsidePolicyApprovalModal: false }) }}>
          <h4>Thank you!</h4>
          <p style={{ textAlign: 'left' }}>
            Just a reminder, <b>this date change has not been completed yet.</b> Luxury Escapes has now notified the customer to confirm the dates and we'll notify you once the booking is finalised.
          </p>
        </Alert>
        <AllowPutOnHoldModal
          showRejectBNBLModal={showRejectBNBLModal}
          hideRejectBNBLModal={this.hideRejectBNBLModal}
          loadingOffer={loadingOffer}
          offer={offer}
          offerError={offerError}
          onConfirmBNBL={this.onConfirmBNBL}
          handleStepToConfirmation={this.handleStepToConfirmation}
        />
        <Modal className="static-modal dates-request-reject" bsSize="small" show={confirmationModalShow} onHide={this.hideConfirmationModal}>
          <Modal.Header closeButton>
            <Modal.Title>Provide feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Feedback to the customer may help confirm a booking!</p>
            <p>We recommend suggesting alternative dates that can be opened up. The customer might then submit a new request.</p>
            {' '}
            <form className={`textarea-container ${!this.state.rejectionNoFeedback ? 'show-textarea' : ''}`}>
              <FormGroup controlId="formControlsTextarea">
                <Checkbox
                  checked={this.state.rejectionNoFeedback}
                  onClick={this.toggleRejectionFeedback}
                  name="rejectionNoFeedback"
                >We are unable to provide alternative dates at this time</Checkbox>
                <FormControl
                  componentClass="textarea"
                  placeholder={feedbackPlaceholder}
                  value={this.state.rejectionMessage}
                  onChange={this.setRejectionMessage}
                  name="rejectionMessage"
                  rows={3}
                />
                <HelpBlock></HelpBlock>
              </FormGroup>
            </form>
            <p style={{ 'padding-top': '10px' }} >
              <AlertBootstrap bsStyle="danger">
                <img style={{ height: '24px', 'padding-right': '5px' }} src="/assets/info.svg" alt="logo" />
                Please note that
                <strong> this feedback goes directly to the customer. </strong>
                It does not come through Luxury Escapes support first.
              </AlertBootstrap>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="large" variant="secondary" className="btn-dates-request" onClick={this.hideConfirmationModal}>Cancel</Button>
            <Button
              bsSize="large"
              variant="primary"
              className="btn-primary btn-dates-request"
              onClick={this.onConfirmReject}
              disabled={disableConfirmReject}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.error && (
          <div className="container">
            <ErrorDisplay message={this.state.error} />
          </div>
        )}
        {loading && <div className="spinner-container overlay">
          <MDSpinner className="spinner" size={100} />
        </div>}
        {<BootstrapTable
          data={datesRequests}
          fetchInfo={{
            dataTotalSize: totalDatesRequests,
          }}
          options={{
            page,
            sizePerPage,
            sizePerPageList: [],
            onPageChange: this.onPageChange,
          }}
          remote
          pagination
        >
          <TableHeaderColumn dataFormat={this.renderOfferLink} width="15%">Offer ID</TableHeaderColumn>
          <TableHeaderColumn dataField="created_at" isKey>Date of request</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderPrimaryGuest} width="15%">Primary Guest</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderGuests}>Guests</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderPackage}>Package</TableHeaderColumn>
          <TableHeaderColumn dataField="room_name">Room name</TableHeaderColumn>
          <TableHeaderColumn dataField="check_in">Current check-in</TableHeaderColumn>
          <TableHeaderColumn dataField="request_date">Requested check-in date</TableHeaderColumn>
          <TableHeaderColumn dataField="check_out">Check-out</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderReason} width="15%">Request reason</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderType}>Type</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderDocumentLink}>Documents</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderStatus} width="10%">Status</TableHeaderColumn>
        </BootstrapTable>}
      </div>
    )
  }
}
