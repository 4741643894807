import React, { PureComponent } from 'react'
import { Button } from 'react-bootstrap'

export class StatusCell extends PureComponent {
  handleLogItem = async() => {
    const { item, onItemLog, onError } = this.props

    try {
      await onItemLog({
        offerId: item.id_offer,
        bookingNumber: item.booking_number,
        isLogged: !!item.voucher.vendor_logged_at,
      })
    } catch (e) {
      onError(e.message)
    }
  }

  render() {
    const { item } = this.props

    const isLogged = !!item.voucher.vendor_logged_at

    return (
      <div>
        {isLogged && <div className="subtext">Logged</div>}
        <Button bsStyle="link" onClick={this.handleLogItem}>
          {isLogged ? 'Unlog' : 'Log'}
        </Button>
      </div>
    )
  }
}
