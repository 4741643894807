import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import MDSpinner from 'react-md-spinner'

export default class ExportButton extends Component {
  render() {
    const {
      isGenerating,
      onClick,
      name,
    } = this.props

    return (
      <Button
        bsStyle="default"
        disabled={isGenerating}
        onClick={onClick}
      >
        {isGenerating ? (
          <div className="button-content">
            <div className="spinner-container">
              <MDSpinner className="spinner" singleColor={'#4298CF'} size={16} />
            </div>
            <div>
              Exporting {name}
            </div>
          </div>
        ) : (
          <div>
            Export {name}
          </div>
        )}
      </Button>
    )
  }
}
