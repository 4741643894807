import React from 'react'
import { findDOMNode } from 'react-dom'
import { Glyphicon } from 'react-bootstrap'

export default class RoomRatePicker extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      roomRates: props.roomRates,
      selectedRoomRate: props.selectedRoomRate,
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick, false)
    document.addEventListener('touchend', this.handleDocumentClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, false)
    document.removeEventListener('touchend', this.handleDocumentClick, false)
  }

  handleDocumentClick = e => {
    const pickerEl = findDOMNode(this)
    const targetEl = e.target

    if (pickerEl === targetEl || pickerEl.contains(targetEl)) {
      return
    }

    this.close()
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  toggleDisplay = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  getLabel = () => this.state.roomRates.find(roomRate => roomRate.id === this.state.selectedRoomRate).name

  selectRow = (roomRate) => {
    this.setState({
      selectedRoomRate: roomRate.id,
    })
    this.props.onRoomRateChange(roomRate)
    this.close()
  }

  render() {
    const { isOpen } = this.state

    return (
      <div className="roomtypepicker-container">
        <button
          onClick={this.toggleDisplay}
          className="roomtypepicker-input"
        >
          {this.getLabel()}
          <span className="pull-right">
            <Glyphicon glyph={isOpen ? 'chevron-right' : 'chevron-down'} />
          </span>
        </button>

        {isOpen && (
          <div className="roomtypepicker-options">
            {this.state.roomRates.map((roomRate) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <div role="button" tabIndex={0} className="roomtypepicker-options-row" key={roomRate.id} onClick={() => {
                this.selectRow(roomRate)
              }}>
                {roomRate.name}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}
