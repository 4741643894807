import React, { Component } from 'react'
import { Button, FormControl, FormGroup, Modal } from 'react-bootstrap'

import DepartureDatesOptions from '../../Common/DepartureDatesOptions'
import ReportingService from '../../../services/ReportingService'

export default class DownloadTravellerPassportsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      isGenerating: false,
      departureDate: '',
    }
  }

  onDepartureDatesChange = (e) => {
    this.setState({ departureDate: e.target.value })
  }

  generatingPDF = async(jobId) => {
    const self = this
    if (this.props.show) {
      setTimeout(async() => {
        const statusData = await ReportingService.getStatusGeneratingTravellerPassportsPDF(jobId)
        if (statusData.status === 'completed') {
          const tempLink = document.createElement('a')
          tempLink.style.display = 'none'
          tempLink.href = statusData.url
          tempLink.setAttribute('download', `${self.props.offer.name}_${self.state.departureDate}.pdf`)
          document.body.appendChild(tempLink)
          tempLink.click()
          document.body.removeChild(tempLink)
          self.setState({ isGenerating: false })
        } else if (statusData.status === 'failed' || !statusData.status) {
          self.setState({ isGenerating: false, error: 'Failed to generate PDF' })
        } else {
          self.generatingPDF(jobId)
        }
      }, 5000)
    }
  }

  downloadNow = async() => {
    if (this.state.departureDate) {
      this.setState({ isGenerating: true, error: false })

      const data = await ReportingService.setGeneratingTravellerPassportsPDF({
        vendorId: this.props.vendorId,
        offerId: this.props.offerId,
        departureDate: this.state.departureDate,
      })

      this.generatingPDF(data.jobId)
    }
  }

  render() {
    const { show, onHide, departureDates } = this.props
    return (
      <Modal className="static-modal" show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Download Traveller Passports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormControl
              componentClass="select"
              placeholder="Select Date"
              onChange={this.onDepartureDatesChange}
              defaultValue={this.state.departureDate}
            >
              <option key="select_date" value="">Select Date</option>
              <DepartureDatesOptions
                type="future"
                departureDates={departureDates}
              />
              <DepartureDatesOptions
                type="past"
                departureDates={departureDates}
              />
            </FormControl>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize="large" bsStyle="default" onClick={onHide}>Cancel</Button>
          <Button bsSize="large" bsStyle={(this.state.error ? 'danger' : 'primary')} onClick={this.downloadNow} disabled={!this.state.departureDate || this.state.isGenerating}>
            {this.state.isGenerating ? 'Generating ...' : (this.state.error || 'Download Now')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
