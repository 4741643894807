export const OFFER_TYPE_HOTEL = 'hotel'
export const OFFER_TYPE_TOUR = 'tour'
export const OFFER_TYPE_TOUR_V2 = 'tour_v2'
export const OFFER_TYPE_LAST_MINUTE_HOTEL = 'last_minute_hotel'
export const OFFER_TYPE_TACTICAL_AO_HOTEL = 'tactical_ao_hotel'

export const PACKAGE_PRICING_TYPES = [
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_TOUR,
]

export const DYNAMIC_PRICING_TYPES = [
  OFFER_TYPE_LAST_MINUTE_HOTEL,
  OFFER_TYPE_TACTICAL_AO_HOTEL,
]
