import React, { Component } from 'react'
import { Glyphicon, Modal, Button } from 'react-bootstrap'

import Spinner from '../Common/Spinner'
import OffersService from '../../services/OffersService'

const actionVerbMap = {
  INSERT: 'created',
  UPDATE: 'updated',
  DELETE: 'deleted',
}

const scheduleMap = {
  online_purchase: 'Online purchase',
  list_visibility: 'Front page visibility',
  availability: 'Availability',
}

export default class ScheduleHistory extends Component {
  state = {
    open: false,
    fetching: false,
    logs: [],
  }

  async fetchData() {
    const logs = await OffersService.getScheduleLogs(this.props.offerId, {
      type: this.props.type,
      brand: this.props.brand,
    })

    this.setState({
      fetching: false,
      logs,
    })
  }

  openModal = () => {
    this.fetchData()
    this.setState({
      open: true,
      fetching: true,
    })
  }

  closeModal = () => {
    this.setState({
      open: false,
      fetching: false,
    })
  }

  renderLogs() {
    if (this.state.fetching) {
      return <Spinner size={50} />
    }

    if (this.state.logs.length === 0) {
      return <p>No schedule history</p>
    }

    return (
      <div className="schedule-history">
        {this.state.logs.map(log => (
          <div key={log.id}>
            <div className="description">
              {scheduleMap[log.type]} schedule for {log.brand} in {log.region} {actionVerbMap[log.action]} on {this.props.formatDate(log.logged_at)}
            </div>
            <div className="dates">
              <div>
                {this.props.formatDate(log.start)}
              </div>
              <div>&#8594;</div>
              <div>
                {this.props.formatDate(log.end)}
              </div>
            </div>
            <hr />
          </div>
        ))}
      </div>
    )
  }

  render() {
    return (
      <span>
        <Modal show={this.state.open} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Schedule History</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderLogs()}</Modal.Body>
        </Modal>
        <Button bsStyle="link" onClick={this.openModal}>
          <Glyphicon glyph="time" className="small" />
        </Button>
      </span>
    )
  }
}
