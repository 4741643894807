import React from 'react'
import Remittance from './Remittance'
import VendorService from '../../services/VendorService'
import { reportError } from '../../utils/reportError'
import ErrorDisplay from '../Common/ErrorDisplay'
import { REMITTANCE_TYPE_SENT } from './constants/sentType'

export default class RemittanceContainer extends React.Component {
  constructor(props) {
    super(props)
    this.id_vendor = props.vendor
    this.state = {
      remittance: null,
      sentType: REMITTANCE_TYPE_SENT,
    }
  }

  componentDidMount() {
    this.updateRemittance(this.state.sentType, this.id_vendor)
  }

  resetMessageState() {
    this.setState({
      error: null,
      comment: null,
    })
  }

  updateRemittance = (sentType, vendorId) => {
    this.setState(
      {
        remittance: null,
      },
      async() => {
        try {
          const emailSent = sentType === REMITTANCE_TYPE_SENT

          const remittance = await VendorService.getAllRemittance(
            emailSent,
            vendorId,
          )

          this.setState({
            remittance,
            error: null,
          })
        } catch (error) {
          reportError(error)
          this.setState({
            error: `Remittance lookup failed: ${error.message}`,
          })
        }
      },
    )
  }

  handleDownloadRemittance = async(id, type) => {
    try {
      this.setState(
        {
          comment: 'Downloading remittance...',
        },
        async() => {
          await VendorService.downloadRemittance(id, type, this.id_vendor)

          this.resetMessageState()
        },
      )
    } catch (error) {
      reportError(error)
      this.setState({
        error: `Remittance download failed: ${error.message}`,
      })
    }
  }

  render() {
    let message = null

    if (this.state.comment) {
      message = <div className="comment">{this.state.comment}</div>
    }

    if (this.state.error) {
      message = <ErrorDisplay message={this.state.error} />
    }

    return (
      <div className="container remittance-container">
        {message}
        <Remittance
          handleDownloadRemittance={this.handleDownloadRemittance}
          data={this.state.remittance}
          dateOfPayment={this.state.dateOfPayment}
          sentType={this.state.sentType}
        />
      </div>
    )
  }
}
