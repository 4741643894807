import React, { Component } from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { Glyphicon } from 'react-bootstrap'

import Calendar from '../../Common/Calendar'

import getCalendarMonthWeeks from '../../helpers/getCalendarMonthWeeks'

const formatDate = date => date.format('YYYY-MM-DD')

const hasLowInventory = availability => {
  if (!availability) {
    return false
  }
  const remaining = availability.total - availability.count
  return remaining / availability.total < 0.25
}

class AvailabilityCalendar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      offset: 0,
      selectedDate: null,
    }
  }

  availabilityCompare = (a, b) => moment(a.range[0]).unix() - moment(b.range[0]).unix()

  firstDay = () => {
    const earliestBlock = this.props.availability.sort(
      this.availabilityCompare,
    )[0]
    return moment.utc(earliestBlock.range[0])
  }

  lastDay = () => {
    const latestBlock = this.props.availability
      .sort(this.availabilityCompare)
      .slice(-1)[0]
    return moment.utc(latestBlock.range[1])
  }

  showingLastDay = grid => {
    const lastDayOfAvailability = this.lastDay()
    const lastMonthInGrid = grid.slice(-1)[0]
    const lastWeekInGrid = lastMonthInGrid.weeks.slice(-1)[0]
    const lastDayInGrid = lastWeekInGrid
      .filter(dayDate => dayDate.day)
      .slice(-1)[0]
    return lastDayOfAvailability.isSameOrBefore(lastDayInGrid.day)
  }

  monthsArray = (n, o) => {
    const firstDay = this.firstDay()
    const accum = Array(n)
    for (let i = 0; i < n; i++) {
      accum[i] = firstDay.clone().add(o + i, 'month')
    }
    return accum
  }

  getMonths = () => this.monthsArray(2, this.state.offset)

  getMonthWeeks = month => getCalendarMonthWeeks(month, false)

  getGrid = () => this.getMonths().map(month => ({
    date: month,
    weeks: this.getMonthWeeks(month),
  }))

  dateIsInBlock = (date, block) => {
    const startDate = moment.utc(block.range[0])
    if (date.isBefore(startDate)) {
      return false
    }
    const endDate = moment.utc(block.range[1]).add(1, 'day')
    return date.isBefore(endDate)
  }

  dateIsInBlackoutBlock = (date, block) => {
    const startDate = moment.utc(block.start_date)
    if (date.isBefore(startDate)) {
      return false
    }

    const endDate = moment.utc(block.end_date).add(1, 'day')
    if (!date.isBefore(endDate)) {
      return false
    }

    const dayOfWeek = date.format('dddd').toLowerCase()
    return block[dayOfWeek]
  }

  getAvailabilityForDate = (date, availability) => {
    if (!date || !availability) {
      return null
    }
    return availability.find(block => this.dateIsInBlock(date, block))
  }

  getDateIsBlackout = (date, blackoutDates) => {
    if (!date || !blackoutDates) {
      return null
    }
    return !!blackoutDates.find(block =>
      this.dateIsInBlackoutBlock(date, block),
    )
  }

  showNextMonth = () => {
    this.setState(prevState => ({
      offset: prevState.offset + 1,
    }))
  }

  showPrevMonth = () => {
    this.setState(prevState => ({
      offset: prevState.offset - 1,
    }))
  }

  getGridWithDates = () => {
    const self = this
    return this.getGrid().map(function(month) {
      return {
        date: month.date,
        weeks: month.weeks.map(function(week) {
          return week.map(function(day) {
            return {
              day,
              availability: self.getAvailabilityForDate(
                day,
                self.props.availability,
              ),
              blackout: self.getDateIsBlackout(day, self.props.blackoutDates),
            }
          })
        }),
      }
    })
  }

  renderCalendarDay = (dayDate, idx) => {
    const formatDay = m => m.format('D')
    const formattedDate = dayDate.day ? formatDate(dayDate.day) : null
    const active =
      formattedDate &&
      this.state.selectedDate === formattedDate &&
      !dayDate.blackout
    const dirty =
      formattedDate && this.props.dirtyDates[formattedDate] && !dayDate.blackout ?
        this.props.dirtyDates[formattedDate] :
        0
    const lowInventory = hasLowInventory(dayDate.availability)
    const countClassNames = classNames('counts', {
      dirty,
      'low-inventory': lowInventory,
    })
    const dirtyTotal = dayDate.availability ? dayDate.availability.total + (dirty || 0) : 0

    const showDecrementButton = dayDate.availability && this.props.canReduceInventory && (dayDate.availability.count < dirtyTotal)

    const dayClassNames = classNames('day', {
      active: active,
      blackout: dayDate.blackout,
    })

    return (
      <div className={dayClassNames} key={idx}>
        {dayDate.day && <div className="date">{formatDay(dayDate.day)}</div>}
        {dayDate.availability && (
          <div className={countClassNames}>
            {dayDate.availability.count} /{' '}
            {dayDate.availability.total + (dirty || 0)}
          </div>
        )}
        {!dayDate.availability && dirty && (
          <div className={countClassNames}>0 / {dirty}</div>
        )}
        {active && (
          <div className="increment-count-input">
            <button
              onClick={() => {
                this.props.incrementAvailability(formattedDate)
              }}
            >
              <Glyphicon glyph={'plus'} />
            </button>
            {showDecrementButton && (
              <button
                onClick={() => {
                  this.props.decrementAvailability(formattedDate)
                }}
              >
                <Glyphicon glyph={'minus'} />
              </button>
            )}
          </div>
        )}
      </div>
    )
  }

  onDateHover = (dayDate, idx) => {
    if (!dayDate.day) {
      return
    }
    this.setState({
      selectedDate: formatDate(dayDate.day),
    })
  }

  onDateUnhover = (dayDate, idx) => {
    this.setState({
      selectedDate: null,
    })
  }

  render() {
    const grid = this.getGridWithDates()
    const shouldHidePrevMonthButton = this.state.offset <= 0
    const shouldHideNextMonthButton = this.showingLastDay(grid)

    return (
      <Calendar
        grid={grid}
        shouldHidePrevMonthButton={shouldHidePrevMonthButton}
        shouldHideNextMonthButton={shouldHideNextMonthButton}
        onShowPrevMonth={this.showPrevMonth}
        onShowNextMonth={this.showNextMonth}
        renderCalendarDay={this.renderCalendarDay}
        onDateHover={this.onDateHover}
        onDateUnhover={this.onDateUnhover}
      />
    )
  }
}

export default AvailabilityCalendar
