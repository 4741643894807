import React, { Component } from 'react'
import { FormControl, FormGroup } from 'react-bootstrap'

export default class Filters extends Component {
  constructor(props) {
    super(props)

    this.state = {
      unlogged: props.filter.unlogged ? 'unlogged' : 'all',
      customer: props.filter.customer,
      bookingNumbers: props.filter.bookingNumbers,
    }
  }

  unloggedChange = (event) => {
    this.props.onUnloggedSearch(event.target.value === 'unlogged')
  }

  customerChange = (event) => {
    this.setState({
      customer: event.target.value,
    })
  }

  onCustomerKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.onCustomerSearch(this.state.customer)
    }
  }

  bookingNumbersChange = (event) => {
    this.setState({
      bookingNumbers: event.target.value,
    })
  }

  onBookingNumbersKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.onBookingNumbersSearch(this.state.bookingNumbers)
    }
  }

  render() {
    return (
      <div className="search-bar">
        <FormGroup>
          <FormControl
            componentClass="select"
            placeholder="Show all bookings"
            onChange={this.unloggedChange}
            defaultValue={this.state.unlogged}
          >
            <option key="show_all_bookings" value="all">All Bookings</option>
            <option key="show_unlogged_bookings" value="unlogged">Unlogged Bookings</option>
          </FormControl>
        </FormGroup>
        <FormGroup>
          <FormControl
            type="text"
            placeholder="Search by Customer name or email"
            value={this.state.customer}
            onChange={this.customerChange}
            onKeyPress={this.onCustomerKeyPress}
          />
        </FormGroup>
        <FormGroup>
          <FormControl
            type="text"
            placeholder="Search by Booking ID"
            value={this.state.bookingNumbers}
            onChange={this.bookingNumbersChange}
            onKeyPress={this.onBookingNumbersKeyPress}
          />
        </FormGroup>
      </div>
    )
  }
}
