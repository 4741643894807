import PropTypes from 'prop-types'
import React from 'react'
import OffersService from '../../services/OffersService'
import ReservationService from '../../services/ReservationService'
import TourService from '../../services/TourService'
import OffersPage from './OffersPage'
import ErrorDisplay from '../Common/ErrorDisplay'
import MDSpinner from 'react-md-spinner'
import moment from 'moment-timezone'

const isPastOffer = (offer) => {
  const now = moment()
  for (const brand in offer.availability_schedules) {
    const schedule = offer.availability_schedules[brand].schedule
    if (schedule && moment(schedule.end).isAfter(now)) {
      return false
    }
  }
  return true
}

const getAvailabilitySchedule = (offer, brand) => offer.brand_schedules.find(
  schedule =>
    schedule.type === 'list_visibility' &&
      schedule.brand === brand,
)

const isPastTourV2Offer = (tour) => {
  const now = moment()

  return !(tour.schedules.some(schedule => moment(schedule.offlineEndDate).isAfter(now)))
}

const getPastAndActiveTourV2Offers = (tours) => {
  const pastTourV2Offers = []
  const activeTourV2Offers = []

  for (const tour of tours) {
    if (isPastTourV2Offer(tour)) {
      pastTourV2Offers.push(tour)
    } else {
      activeTourV2Offers.push(tour)
    }
  }

  return { pastTourV2Offers, activeTourV2Offers }
}

export default class OffersPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.id_vendor = props.vendor ? props.vendor.sfid : props.params.id_vendor
    this.state = {
      page: 0,
      loading: true,
      error: null,
      liveOffers: [],
      pastOffers: [],
      hasMore: false,
    }
    this.sizePerPage = 20
  }

  onFetchMore = () => {
    this.fetchData()
  }

  componentDidMount = () => {
    this.fetchData()
  }

  isSelfManaged= async(offer) => {
    if (offer.type !== 'hotel' || !offer.packages.length) {
      return false
    }
    const approvedPkgs = offer.packages.filter((pkg) => pkg.status === 'content-approved' && pkg.fk_property_id)
    const uniquePkgs = [...new Set(approvedPkgs.map((pkg) => pkg.fk_property_id))]
    for (let i = 0; i < uniquePkgs.length; i++) {
      const propertyData = await ReservationService.getProperty(uniquePkgs[i])
      if (propertyData.channel_manager !== 'self-managed') {
        return false
      }
    }
    return true
  }

  fetchData = async() => {
    this.setState({ loading: true, error: null })
    try {
      const page = this.state.page + 1
      let response = await OffersService.getOffersByVendor(page, this.sizePerPage, this.id_vendor)
      const liveOffers = []
      const pastOffers = []

      if (page === 1) {
        const toursV2Response = await TourService.getToursByVendorId(this.id_vendor)
        const { pastTourV2Offers, activeTourV2Offers } = getPastAndActiveTourV2Offers(toursV2Response)

        liveOffers.push(...activeTourV2Offers)
        pastOffers.push(...pastTourV2Offers)
      }

      response = Object.assign({}, { result: [] }, response)
      let hasMore = true
      for (const offer of response.result) {
        if (offer.status !== 'content-approved' && offer.type !== 'tour') {
          hasMore = false // offers are returned content approved first, then draft, if we have hit draft then we should stop paginating
          continue
        }

        offer.self_managed = await this.isSelfManaged(offer)

        offer.availability_schedules = {
          'Luxury Escapes': { schedule: getAvailabilitySchedule(offer, 'luxuryescapes'), host: window.configs.LE_CUSTOMER_PORTAL },
          'Scoopon Travel': { schedule: getAvailabilitySchedule(offer, 'scoopontravel'), host: window.configs.ST_CUSTOMER_PORTAL },
          'Cudo Travel': { schedule: getAvailabilitySchedule(offer, 'cudotravel'), host: window.configs.CT_CUSTOMER_PORTAL },
          'TreatMe Travel': { schedule: getAvailabilitySchedule(offer, 'treatmetravel'), host: window.configs.TT_CUSTOMER_PORTAL },
          'Deals Travel': { schedule: getAvailabilitySchedule(offer, 'dealstravel'), host: window.configs.DT_CUSTOMER_PORTAL },
          Yidu: { schedule: getAvailabilitySchedule(offer, 'yidu') },
          'Zoom Zoom': { schedule: getAvailabilitySchedule(offer, 'zoomzoom') },
        }

        if (isPastOffer(offer)) {
          pastOffers.push(offer)
        } else {
          liveOffers.push(offer)
        }
      }
      if (hasMore) {
        hasMore = response.total > page * this.sizePerPage
      }

      this.setState({
        liveOffers: this.state.liveOffers.concat(liveOffers),
        pastOffers: this.state.pastOffers.concat(pastOffers),
        hasMore,
        loading: false,
        page,
      })
    } catch (err) {
      this.setState({ loading: false, error: err.message })
    }
  }

  render() {
    if (this.state.page === 0 && this.state.loading) {
      return (
        <div className="spinner-container">
          <MDSpinner className="spinner" size={100} />
        </div>
      )
    }

    if (this.state.error) {
      return (
        <div className="container">
          <ErrorDisplay message={this.state.error} />
        </div>
      )
    }

    return (
      <OffersPage
        liveOffers={this.state.liveOffers}
        pastOffers={this.state.pastOffers}
        hasMore={this.state.hasMore}
        id_vendor={this.id_vendor}
        onFetchMore={this.onFetchMore}
        fetching={this.state.loading}
      />
    )
  }
}

OffersPageContainer.contextTypes = {
  user: PropTypes.object,
  vendor: PropTypes.object,
}
