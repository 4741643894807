import React from 'react'
import MDSpinner from 'react-md-spinner'
import PropTypes from 'prop-types'

import ErrorDisplay from '../Common/ErrorDisplay'
import AvailabilityPage from './AvailabilityPage'

import ReservationService from '../../services/ReservationService'
import OffersService from '../../services/OffersService'

export default class AvailabilityContainer extends React.Component {
  constructor(props) {
    super(props)

    this.propertyId = props.params.id_property
    this.roomTypeId = props.location.query.roomTypeId || null
    this.roomRateId = props.location.query.roomRateId || null

    this.state = {
      loading: true,
      hasError: false,
    }
  }

  componentWillMount = () => {
    this.fetchData()
  }

  getLinkedOffers = (offers, roomTypeId) => offers.filter(offer => !!offer.packages.some((pkg) => pkg.fk_room_type_id === roomTypeId))

  async fetchData() {
    try {
      const property = await ReservationService.getProperty(this.propertyId)
      const offers = await OffersService.getAllOffersByVendor(this.props.params.id_vendor)

      if (!this.roomTypeId) {
        this.roomTypeId = property.room_types[0].id
      }

      const roomType = property.room_types.find(rt => rt.id === this.roomTypeId)

      if (!this.roomRateId || !roomType.room_rates.find(rr => rr.id === this.roomRateId)) {
        this.roomRateId = roomType.room_rates[0].id
      }

      const roomTypes = property.room_types.map(roomType => ({
        id: roomType.id,
        name: roomType.name,
        offers: this.getLinkedOffers(offers, roomType.id),
      }))

      const roomRates = roomType.room_rates.map(roomRate => ({
        id: roomRate.id,
        name: roomRate.rate_plan.name,
      }))

      const availability = await ReservationService.getAvailability(this.propertyId, this.roomTypeId, this.roomRateId)
      const blackoutDates = await ReservationService.getBlackoutDates(this.propertyId, this.roomTypeId, this.roomRateId)

      this.setStateFromResponse({
        availability: availability,
        blackoutDates: blackoutDates,
        selectedRoomType: this.roomTypeId,
        roomTypes: roomTypes,
        selectedRoomRate: this.roomRateId,
        roomRates,
      })
    } catch (error) {
      this.handleFetchError(error)
    }
  }

  onRoomTypeChange = (roomType) => {
    this.roomTypeId = roomType.id
    this.setState({
      loading: true,
    })
    this.fetchData()
  }

  onRoomRateChange = (roomRate) => {
    this.roomRateId = roomRate.id
    this.setState({
      loading: true,
    })
    this.fetchData()
  }

  updateAvailability = async({ dates }) => {
    const ranges = []
    for (const [date, delta] of Object.entries(dates)) {
      ranges.push({
        start_date: date,
        end_date: date,
        delta,
      })
    }
    const results = await ReservationService.updateAvailability({
      propertyId: this.propertyId,
      roomTypeId: this.roomTypeId,
      ranges,
    })

    if (results && results.length) {
      this.setState({
        availability: results,
      })
    }
  }

  setStateFromResponse(response) {
    this.setState({
      loading: false,
      availability: response.availability,
      blackoutDates: response.blackoutDates,
      roomTypes: response.roomTypes,
      selectedRoomType: response.selectedRoomType,
      roomRates: response.roomRates,
      selectedRoomRate: response.selectedRoomRate,
    })
  }

  handleFetchError(error) {
    this.setState({
      loading: false,
      hasError: true,
      error,
    })
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="spinner-container">
          <MDSpinner className="spinner" singleColor={'#000'} size={100} />
        </div>
      )
    }

    if (this.state.hasError) {
      return (
        <div className="container">
          <ErrorDisplay
            message={this.state.error.message}
          />
        </div>
      )
    }

    return (
      <AvailabilityPage
        availability={this.state.availability}
        blackoutDates={this.state.blackoutDates}
        selectedRoomType={this.state.selectedRoomType}
        roomTypes={this.state.roomTypes}
        onRoomTypeChange={this.onRoomTypeChange}
        selectedRoomRate={this.state.selectedRoomRate}
        roomRates={this.state.roomRates}
        onRoomRateChange={this.onRoomRateChange}
        updateAvailability={this.updateAvailability}
        canReduceInventory={this.context.vendor && this.context.vendor.can_reduce_inventory__c}
      />
    )
  }
}

AvailabilityContainer.contextTypes = {
  vendor: PropTypes.object,
}
