import { request } from './common'
import qs from 'qs'

export default class ReservationService {
  static async getProperty(propertyId) {
    const response = await request(window.configs.RESERVATION_HOST + `/api/properties/${propertyId}`)
    return response.result
  }

  static async getAvailability(propertyId, roomTypeId, roomRateId) {
    const response = await request(window.configs.RESERVATION_HOST + `/api/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/availability`)
    return response.result
  }

  static async getBlackoutDates(propertyId, roomTypeId, roomRateId) {
    const response = await request(window.configs.RESERVATION_HOST + `/api/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/blackout-dates`)
    return response.result.dates
  }

  static async patchAvailability(url, op, ranges) {
    const response = await request(url, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ranges,
        op,
      }),
    })
    return response.result
  }

  static async updateAvailability({ propertyId, roomTypeId, ranges }) {
    let result = []

    const url = `${window.configs.RESERVATION_HOST}/api/properties/${propertyId}/room-types/${roomTypeId}/availability`

    const incrementRange = ranges.filter(r => r.delta > 0)
    if (incrementRange.length > 0) {
      result = await this.patchAvailability(url, 'increment', incrementRange)
    }
    const decrementRange = ranges.filter(r => r.delta < 0).map(r => ({ ...r, delta: Math.abs(r.delta) }))
    if (decrementRange.length > 0) {
      result = await this.patchAvailability(url, 'decrement', decrementRange)
    }
    return result
  }

  static async enquireHotelReservation(
    propertyId,
    roomTypeId,
    currencyCode,
    queryObj,
  ) {
    const timezoneOffset = new Date().getTimezoneOffset() / 60 * -1
    const q = {
      ...queryObj,
      timezone_offset: timezoneOffset,
    }

    return request(
      window.configs.RESERVATION_HOST + `/api/properties/${propertyId}/room-types/${roomTypeId}/enquiry?${qs.stringify(
        q,
      )}`,
    )
  }

  static async getTourOptionsEnquiry(tourId, tourOptionId) {
    const response = await request(window.configs.RESERVATION_HOST + `/api/tours/${tourId}/tour-options/${tourOptionId}/enquiry`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
    return response.result
  }

  static async getTourOptionsDates(tourId, tourOptionId) {
    const response = await request(window.configs.RESERVATION_HOST + `/api/tours/${tourId}/tour-options/${tourOptionId}/dates`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
    return response.result
  }
}
