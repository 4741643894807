import React, { Component } from 'react'
import { FormControl, FormGroup } from 'react-bootstrap'

import DepartureDatesOptions from '../Common/DepartureDatesOptions'

export default class OrdersPageFilters extends Component {
  onDepartureDatesChange = (e) => {
    this.props.onDepartureDatesChange(e.target.value)
  }

  render() {
    const { departureDates } = this.props

    return (
      <div>
        <FormGroup bsSize="sm">
          <FormControl
            componentClass="select"
            placeholder="Show all bookings"
            onChange={this.onDepartureDatesChange}
          >
            <option key="show_all_bookings" value="all">All Bookings</option>
            <optgroup label="Status">
              <option key="show_unlogged_bookings" value="unlogged">Unlogged Bookings</option>
            </optgroup>
            {departureDates && <DepartureDatesOptions
              type="future"
              departureDates={departureDates}
            />}
            {departureDates && <DepartureDatesOptions
              type="past"
              departureDates={departureDates}
            />}
          </FormControl>
        </FormGroup>
      </div>
    )
  }
}
