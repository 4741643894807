import React, { Component } from 'react'
import jwt_decode from 'jwt-decode'
import { browserHistory } from 'react-router'
import AuthService from '../../services/AuthService'

export default class SocialGoogleButton extends Component {
  componentDidMount = async() => {
    // eslint-disable-next-line no-undef
    google.accounts.id.initialize({
      client_id: window.configs.GOOGLE_APP_ID,
      callback: this.onSuccess,
    })

    // eslint-disable-next-line no-undef
    google.accounts.id.renderButton(
      document.getElementById('my-signin2'),
      {
        theme: 'filled_blue',
        text: 'continue_with',
        locale: 'en_AU',
        size: 'large',
      },
    )
  }

  async onSuccess(data) {
    const tokenData = jwt_decode(data.credential)
    const loginWithGoogleData = {
      id_google: tokenData.sub,
      email: tokenData.email,
      token_id: data.credential,
      givenName: tokenData.given_name,
      surname: tokenData.family_name,
    }
    const loginResponse = await AuthService.loginWithGoogle(loginWithGoogleData)
    window._auth_token_ = loginResponse.access_token
    window.location.reload(false)
  }

  render() {
    return (<div id="my-signin2">Continue with google</div>)
  }
}
