import React from 'react'
import { findDOMNode } from 'react-dom'
import { Glyphicon } from 'react-bootstrap'

export default class RoomTypePicker extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      roomTypes: props.roomTypes,
      selectedRoomType: props.selectedRoomType,
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick, false)
    document.addEventListener('touchend', this.handleDocumentClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, false)
    document.removeEventListener('touchend', this.handleDocumentClick, false)
  }

  handleDocumentClick = e => {
    const pickerEl = findDOMNode(this)
    const targetEl = e.target

    if (pickerEl === targetEl || pickerEl.contains(targetEl)) {
      return
    }

    this.close()
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  toggleDisplay = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  getLabel = () => this.state.roomTypes.find(roomType => roomType.id === this.state.selectedRoomType).name

  selectRow = (roomType) => {
    this.setState({
      selectedRoomType: roomType.id,
    })
    this.props.onRoomTypeChange(roomType)
    this.close()
  }

  render() {
    const { isOpen } = this.state

    return (
      <div className="roomtypepicker-container">
        <button
          onClick={this.toggleDisplay}
          className="roomtypepicker-input"
        >
          {this.getLabel()}
          <span className="pull-right">
            <Glyphicon glyph={isOpen ? 'chevron-right' : 'chevron-down'} />
          </span>
        </button>

        {isOpen && (
          <div className="roomtypepicker-options">
            {this.state.roomTypes.map((roomType) => {
              const offerListComponent = <ul>
                {roomType.offers.map((offer) => (
                  <li key={offer.id_salesforce_external}>{offer.opportunity_name}</li>
                ))}
              </ul>
              return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div role="button" tabIndex={0} className="roomtypepicker-options-row" key={roomType.id} onClick={() => {
                  this.selectRow(roomType)
                }} style={{
                  display: 'flex', flexDirection: 'row',
                }}>
                  <div style={{ width: '70%' }}>{roomType.name}</div>
                  <div style={{ width: '30%' }}>{offerListComponent}</div>
                </div>
              ) })
            }
          </div>
        )}
      </div>
    )
  }
}
