import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { Button } from 'react-bootstrap'
import MDSpinner from 'react-md-spinner'

import OfferCard from './OfferCard'
import { OFFER_TYPE_TOUR_V2 } from '../../constants/offer-constants'
import TourV2OfferCard from './TourV2OfferCard'

export default class OffersPage extends Component {
  constructor(props) {
    super(props)
    this.user = props.user
    this.id_vendor = props.id_vendor
  }

  render() {
    const hasLiveOffers = this.props.liveOffers.length > 0
    const hasPastOffers = this.props.pastOffers.length > 0
    return (
      <div className="container offers-page">

        <h3>My Campaigns</h3>
        <div className="offers-block">
          <h4>Live Campaigns</h4>
          {hasLiveOffers ? (
            <div>
              {this.props.liveOffers.map((offer, index) => (
                offer.type === OFFER_TYPE_TOUR_V2 ?
                  <TourV2OfferCard offer={offer} id_vendor={this.id_vendor} key={index} /> :
                  <OfferCard offer={offer} id_vendor={this.id_vendor} key={index} />
              ))}
            </div>
          ) : (
            <div className="no-campaigns-message">You do not currently have any live campaigns</div>
          )}
        </div>

        <div className="offers-block">
          <h4>Past Campaigns</h4>
          {hasPastOffers ? (
            <div>
              {this.props.pastOffers.map((offer, index) => (
                offer.type === OFFER_TYPE_TOUR_V2 ?
                  <TourV2OfferCard offer={offer} id_vendor={this.id_vendor} key={index} /> :
                  <OfferCard offer={offer} id_vendor={this.id_vendor} key={index} />
              ))}
            </div>
          ) : (
            <div className="no-campaigns-message">You do not currently have any past campaigns</div>
          )}
        </div>
        {this.props.hasMore && (
          <Button
            bsSize="large"
            bsStyle="primary"
            disabled={this.props.fetching}
            onClick={this.props.onFetchMore}
          >
            {this.props.fetching ? (
              <div className="spinner-container">
                <MDSpinner className="spinner" singleColor={'#FFF'} size={16} />
              </div>
            ) : 'Load More...'}
          </Button>
        )}
      </div>
    )
  }
}

OffersPage.contextTypes = {
  user: PropTypes.object,
}
