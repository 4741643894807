import React, { Component } from 'react'
import { Alert, Button, Form, FormGroup, FormControl, ControlLabel, Col, Row } from 'react-bootstrap'
import MDSpinner from 'react-md-spinner'
import AuthService from '../../services/AuthService'

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      updating: false,
      error: null,
      email: '',
      completed: false,
    }
  }

  onEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    })
  }

  onSubmit = async(event) => {
    event.preventDefault()
    this.setState({ updating: true, error: null, completed: false })
    try {
      await AuthService.sendForgotPassword({
        email: this.state.email,
      })
      this.setState({ updating: false, completed: true })
    } catch (err) {
      this.setState({ updating: false, error: err.message })
    }
  }

  render() {
    return (
      <div className="container center">
        <Row>
          <Col sm={12} md={6} mdOffset={3}>
            <h2>Forgot Password</h2>
            <hr />
            {this.state.error && (
              <Alert bsStyle="danger">{this.state.error}</Alert>
            )}
            {this.state.completed && (
              <Alert bsStyle="success">Email has been sent</Alert>
            )}
          </Col>
        </Row>
        <Form horizontal>
          <FormGroup>
            <Col sm={12} md={6} mdOffset={3}>
              <ControlLabel>Email</ControlLabel>
              <FormControl type="email" value={this.state.email} onChange={this.onEmailChange} />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm={12} md={6} mdOffset={3}>
              <Button
                bsSize="large"
                bsStyle="primary"
                disabled={this.state.updating}
                onClick={this.onSubmit}>
                {this.state.updating ? (
                  <div className="spinner-container">
                    <MDSpinner className="spinner" singleColor={'#FFF'} size={16} />
                  </div>
                ) : 'Send'}
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    )
  }
}
