/* src/components/Addons/AddonSalesPerformance.js */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Link } from 'react-router'
import MDSpinner from 'react-md-spinner'
import { Alert, Glyphicon, Col, Row, Button } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

import Reporting from '../../services/ReportingService'

class AddonSalesPerformance extends React.Component {
  state = {
    loading: false,
    openPanel: false,
    error: null,
    data: null,
  }

  dismissError = () => {
    this.setState({ error: null })
  }

  hidePanel = () => this.setState({ openPanel: false })

  fetchData = () => {
    this.setState({ loading: true, openPanel: true, error: null }, async() => {
      try {
        const data = await Reporting.getAddonPerformance(this.props.addonId)

        this.setState({ loading: false, openPanel: true, data: data })
      } catch (e) {
        console.error(e)
        this.setState({ loading: false, openPanel: false, error: e.message })
      }
    })
  }

  formatOfferName = (cell, row) => {
    const link = `/${this.props.addon.vendor_id}/${row.offer_id}/orders`
    return <Link to={link}>{cell}</Link>
  }

  renderClosedPanel = () => (
    <Col sm={12} md={12}>
      <div className="sales-performance-buttons">
        <Button bsStyle="link" onClick={this.fetchData} className="open-panel-button">
          <Glyphicon glyph="signal" /> View Sales Performance
          <Glyphicon glyph="chevron-down" className="small" />
        </Button>
      </div>
    </Col>
  )

  renderLoadingPanel = () => (
    <Col sm={12} md={12}>
      <div className="spinner-container">
        <MDSpinner className="spinner" size={80} />
      </div>
    </Col>
  )

  renderPerformancePanel = () => {
    const data = this.state.data

    return (
      <div>
        <Col sm={12} md={2}>
          <h5>Total Revenue</h5>
          {data.total_revenue}

          <h5>Total Active Addons</h5>
          <span className="text-large">
            {data.total_active}
          </span>

          <h5>Cancelled</h5>
          {data.total_cancelled}
        </Col>
        <Col sm={12} md={10}>
          <div className="space-between">
            <h5>Performance by package</h5>
            <Button bsStyle="link" onClick={this.hidePanel}>
              <Glyphicon glyph="chevron-up" className="small primary" />
            </Button>
          </div>
          <BootstrapTable
            data={this.state.data.offers}
            striped
            hover
            bordered
          >
            <TableHeaderColumn dataField="deal_option" isKey width="60%">Deal Option</TableHeaderColumn>
            <TableHeaderColumn dataField="offer_name" dataFormat={this.formatOfferName}>Sold With</TableHeaderColumn>
            <TableHeaderColumn dataField="sold">Sold</TableHeaderColumn>
            <TableHeaderColumn dataField="revenue">Revenue</TableHeaderColumn>
          </BootstrapTable>
          <div className="last-updated subtext pull-right">
            Report last updated at {data.updated_at}
          </div>
        </Col>
      </div>
    )
  }

  render() {
    const { openPanel, error, data, loading } = this.state
    const rowClassNames = classNames('sales-performance', { active: openPanel })
    const shouldShowPerformance = !!(openPanel && data)

    if (error) {
      return (
        <Alert bsStyle="danger" onDismiss={this.dismissError}>
          <h4>An Error occurred while fetching sales performance</h4>
          <p>{error}</p>
        </Alert>
      )
    }

    return (
      <Row className={rowClassNames}>
        {!openPanel && this.renderClosedPanel()}
        {openPanel && loading && this.renderLoadingPanel()}
        {shouldShowPerformance && this.renderPerformancePanel()}
      </Row>
    )
  }
}

AddonSalesPerformance.contextTypes = {
  addon: PropTypes.object.isRequired,
  addonId: PropTypes.string.isRequired,
}

export default AddonSalesPerformance
