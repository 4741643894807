import React, { Component } from 'react'

import { TravellerDetailsButton } from './TravellerDetails'
import TourV2GuestCell from './TourV2GuestCell'

import { sortTourTravellers } from '../helpers/tourItems'

import { TRAVELLER_SORT_CRITERIA } from '../../constants/traveller-details'

export default class TourTravellersCell extends Component {
  render() {
    const {
      row,
      setTravellerData,
      isLETour,
    } = this.props

    return (
      <div>
        {sortTourTravellers(row.travellers, TRAVELLER_SORT_CRITERIA).map((traveller, index) => (
          <TourV2GuestCell
            isLETour={isLETour}
            key={index}
            row={{ ...row, traveller, guestname: `${traveller.first_name ?? ''} ${traveller.last_name ?? ''}` }}
            travellerDetailsButton={<TravellerDetailsButton onClick={() => {
              setTravellerData({ ...row, traveller, guestname: `${traveller.first_name ?? ''} ${traveller.last_name ?? ''}` })
            }} />}
          />
        ))}
      </div>
    )
  }
}
