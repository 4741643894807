import React, { Component } from 'react'
import RemittanceContainer from './RemittanceContainer'

export default class ViewRemittance extends Component {
  constructor(props) {
    super(props)
    this.id_vendor = props.params.id_vendor
  }

  render() {
    return (
      <div className="container profile-page">
        <h3>Remittances</h3>
        <div className="section">
          <RemittanceContainer vendor={this.id_vendor} />
        </div>
      </div>
    )
  }
}
