import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import MDSpinner from 'react-md-spinner'
import AvailabilityCalendar from './AvailabilityCalendar'
import RoomTypePicker from './RoomTypePicker'
import RoomRatePicker from './RoomRatePicker'

export default class AvailabilityPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      saving: false,
      hasChanges: false,
      dirtyDates: {},
    }
  }

  incrementAvailability = (date) => {
    this.setState({
      hasChanges: true,
      dirtyDates: {
        ...this.state.dirtyDates,
        [date]: (this.state.dirtyDates[date] || 0) + 1,
      },
    })
  }

  decrementAvailability = (date) => {
    this.setState({
      hasChanges: true,
      dirtyDates: {
        ...this.state.dirtyDates,
        [date]: (this.state.dirtyDates[date] || 0) - 1,
      },
    })
  }

  clearChanges = () => {
    this.setState({
      hasChanges: false,
      dirtyDates: {},
    })
  }

  saveChanges = async() => {
    try {
      this.setState({ error: null, saving: true })
      await this.props.updateAvailability({ dates: this.state.dirtyDates })
      this.clearChanges()
    } catch (err) {
      this.setState({ error: err })
    }
    this.setState({ saving: false })
  }

  render() {
    const isSaving = this.state.saving
    return (
      <div className="container availability-page">
        <h3>Availability</h3>
        <div>
          {this.state.error && (
            <Alert bsStyle="danger">
              <h4> An Error occurred while saving your changes </h4>
              <p> Your changes may not have been saved, so please refresh the page and try again </p>
            </Alert>
          )}
          <div className="calendar-container">
            <RoomTypePicker
              roomTypes={this.props.roomTypes}
              selectedRoomType={this.props.selectedRoomType}
              onRoomTypeChange={this.props.onRoomTypeChange}
            />
            {this.props.roomRates.length > 1 && <RoomRatePicker
              roomRates={this.props.roomRates}
              selectedRoomRate={this.props.selectedRoomRate}
              onRoomRateChange={this.props.onRoomRateChange}
            />}
            {this.props.availability.length ? (
              <div>
                <AvailabilityCalendar
                  availability={this.props.availability}
                  blackoutDates={this.props.blackoutDates}
                  incrementAvailability={this.incrementAvailability}
                  decrementAvailability={this.decrementAvailability}
                  dirtyDates={this.state.dirtyDates}
                  canReduceInventory={this.props.canReduceInventory}
                />
                <div className="action-bar">
                  <div>To adjust allocation, hover over the specified date and use the + button.</div>
                  <div className="button-group">
                    <Button bsSize="large" bsStyle="default" disabled={!this.state.hasChanges || isSaving} onClick={this.clearChanges}>Cancel</Button>
                    <Button
                      bsSize="large"
                      bsStyle="primary"
                      disabled={!this.state.hasChanges || isSaving}
                      onClick={this.saveChanges}
                    >
                      {isSaving ? (
                        <div className="spinner-container">
                          <MDSpinner className="spinner" singleColor={'#FFF'} size={16} />
                        </div>
                      ) : 'Save Changes'}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (<i>No availability</i>)}
          </div>
        </div>
      </div>
    )
  }
}
