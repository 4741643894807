import React, { Component } from 'react'
import { Alert, Modal } from 'react-bootstrap'
import MDSpinner from 'react-md-spinner'
import moment from 'moment-timezone'

import TravellerService from '../../../services/TravellerService'
import { getGuestNameForTourReservation } from '../../helpers/items'
import { changeDateFormat } from '../../helpers/formatDate'
import Form from './Form'
import { OFFER_TYPE_TOUR_V2 } from '../../../constants/offer-constants'

const DATE_FORMAT = 'DD/MM/YYYY'
const ISO_DATE_FORMAT = 'YYYY-MM-DD'
const TIME_FORMAT = 'HH:mm'

const travellerSchema = {
  passport_image: {
    required: true,
    label: 'Image Of Passport',
  },
  title: {
    required: true,
    label: 'Title',
  },
  first_name: {
    required: true,
    label: 'First Name',
  },
  middle_name: {
    required: false,
    label: 'Middle Name',
  },
  last_name: {
    required: true,
    label: 'Last Name',
  },
  gender: {
    required: true,
    label: 'Gender',
  },
  weight: {
    required: true,
    label: 'Weight',
  },
  dob: {
    required: true,
    label: 'Date of Birth',
    dateField: true,
  },
  passport_expiry: {
    required: true,
    label: 'Passport Expiry',
    dateField: true,
  },
  passport_number: {
    required: true,
    label: 'Passport Number',
  },
  nationality: {
    required: true,
    label: 'Nationality',
  },

  bedding_configuration: {
    required: true,
    label: 'Bedding Configuration',
  },
  contact_number: {
    required: true,
    label: 'Contact Number',
  },
  email: {
    required: true,
    label: 'Email Address',
  },
  street_address: {
    required: true,
    label: 'Street Address',
  },
  city: {
    required: true,
    label: 'City',
  },
  state: {
    required: true,
    label: 'State',
  },
  postcode: {
    required: true,
    label: 'Post Code',
  },
  country: {
    required: true,
    label: 'Country',
  },

  emergency_contact_email: {
    required: true,
    label: 'Email',
  },
  emergency_contact_name: {
    required: true,
    label: 'Full Name',
  },
  emergency_contact_phone: {
    required: true,
    label: 'Contact Number',
  },
  emergency_contact_relationship: {
    required: true,
    label: 'Relationship',
  },

  medical_conditions: {
    required: false,
    label: 'Medical Conditions',
  },
  covid_vaccination_status: {
    required: true,
    label: 'Covid Vaccination Status',
  },
  mobility_requirements: {
    required: false,
    label: 'Mobility Requirements',
  },
  dietary_requirements: {
    required: false,
    label: 'Dietary Requirements',
  },
  conditions: {
    required: false,
    label: 'Other Requirements (deprecated)',
  },
  requests: {
    required: false,
    label: 'Additional Requests',
  },
  pre_tour_accommodation: {
    required: false,
    label: 'Pre-Tour Accommodation',
  },
  post_tour_accommodation: {
    required: false,
    label: 'Post-Tour Accommodation',
  },
}

const travellerFlightSchema = {
  arrival_date: {
    required: true,
    label: 'Arrival Date',
    dateField: true,
  },
  arrival_time: {
    required: true,
    label: 'Arrival Time (24 hour time)',
  },
  arrival_airport: {
    required: true,
    label: 'Airport (3 Letter Code)',
  },
  arrival_flight_number: {
    required: true,
    label: 'Flight Number',
  },

  departure_date: {
    required: true,
    label: 'Departure Date',
    dateField: true,
  },
  departure_time: {
    required: true,
    label: 'Departure Time (24 hour time)',
  },
  departure_airport: {
    required: true,
    label: 'Airport (3 Letter Code)',
  },
  departure_flight_number: {
    required: true,
    label: 'Flight Number',
  },
}

const travellerFormData = () => {
  const data = {}
  Object.keys(Object.assign({}, travellerSchema, travellerFlightSchema)).forEach((name) => {
    data[name] = ''
  })
  return data
}

const populateFormData = (traveller, formData) => {
  const schema = Object.assign({}, travellerSchema, travellerFlightSchema)

  Object.keys(formData).forEach((fieldName) => {
    // delete schema fields that are not in traveller payload (essentially all conditional fields)
    if (traveller[fieldName] === undefined && fieldName !== 'passport_image') {
      delete travellerSchema[fieldName]
      return
    }

    if (schema[fieldName] && schema[fieldName].dateField) {
      formData[fieldName] = changeDateFormat(traveller[fieldName], ISO_DATE_FORMAT, DATE_FORMAT) || ''
      return
    }
    formData[fieldName] = traveller[fieldName] || ''
  })

  formData.booking_number = traveller.booking_number
  formData.traveller_id = traveller.traveller_id
  formData.order_id = traveller.order_id
  formData.customer_id = traveller.customer_id
}

export default class TravellerDetailsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formError: {},
      traveller: {},
      loading: true,
      sending: false,
      success: false,
      totalTravellers: 0,
      tourFlightDetailsRequired: false,
      passportRequired: true,
      displayFlightsForm: false,
      formData: travellerFormData(),
    }
  }

  fetchTravellerData = async() => {
    const { booking_number: bookingNumber, id_order, type, traveller: travellerProp } = this.props.data

    try {
      const formData = Object.assign({}, this.state.formData)

      const travellers = await TravellerService.getDetails(id_order, false, false)

      let traveller

      if (type === OFFER_TYPE_TOUR_V2) {
        traveller = travellers.find(
          _traveller => _traveller.traveller_id === travellerProp.traveller_id,
        )
      } else {
        traveller = travellers.find(
          data => data.booking_number === bookingNumber,
        )
      }

      if (traveller) {
        populateFormData(traveller, formData)
      }

      this.setState({
        loading: false,
        traveller: traveller,
        totalTravellers: travellers.length,
        formData: formData,
        displayFlightsForm: traveller.flights_submitted || false,
        tourFlightDetailsRequired: traveller?.purchased_item?.tour_flight_details_required ?? false,
        passportRequired: traveller.passportRequired ?? true,
        disablePhotoIdValidation: traveller.disablePhotoIdValidation ?? false,
      })
    } catch (e) {
      this.setState({
        loading: false,
      })
    }
  }

  componentDidUpdate = async() => {
    if (this.state.loading === true && this.props.data) {
      await this.fetchTravellerData()
    }
  }

  formatDateFields = (values, current_format, target_format) => {
    const schema = Object.assign({}, travellerSchema, travellerFlightSchema)
    const dateFields = {}
    Object.keys(values).forEach((name) => {
      if (schema[name] && schema[name].dateField) {
        dateFields[name] = changeDateFormat(this.state.formData[name], current_format, target_format) || this.state.formData[name]
      }
    })
    return dateFields
  }

  handleFieldChange = (e) => {
    const data = Object.assign({}, this.state.formData)
    data[e.target.name] = e.target.value
    this.setState({ formData: data })
  }

  handleImageChange = (image, error) => {
    const formError = Object.assign({}, this.state.formError)
    if (error) {
      formError.passport_image = error
    }
    this.setState({
      formError: formError,
      formData: Object.assign({}, this.state.formData, { passport_image: image }),
    })
  }

  handleDisplayFlightsForm = (display) => {
    this.setState({
      displayFlightsForm: display,
    })
  }

  getValidationState = (name) => {
    if (typeof this.state.formError[name] !== 'undefined') {
      return 'error'
    }
    return null
  }

  onValidate = (submit) => {
    const { formData, displayFlightsForm, tourFlightDetailsRequired, passportRequired, disablePhotoIdValidation } = this.state
    const formError = {}

    let schema = Object.assign({}, travellerSchema)

    if (tourFlightDetailsRequired && displayFlightsForm) {
      schema = Object.assign({}, schema, travellerFlightSchema)
    }

    if (submit) {
      Object.keys(schema).forEach((name) => {
        const { label, required } = schema[name]

        if (!passportRequired && ['passport_number', 'passport_expiry', 'nationality'].includes(name)) {
          return
        }

        if (disablePhotoIdValidation && ['passport_image', 'passport_number', 'passport_expiry', 'nationality'].includes(name)) {
          return
        }

        if (required && (typeof formData[name] === 'undefined' || !formData[name])) {
          formError[name] = `Field "${name === 'passport_image' && !passportRequired ? 'Image of Photo ID' : label}" is required`
        }
      })
    }

    if (formData.dob && !moment(formData.dob, DATE_FORMAT, true).isValid()) {
      formError.dob = 'Please enter date of birth in the correct format'
    }

    if (passportRequired && formData.passport_expiry && !moment(formData.passport_expiry, DATE_FORMAT, true).isValid()) {
      formError.passport_expiry = 'Please enter the passport expiry date in the correct format'
    }

    if (tourFlightDetailsRequired && displayFlightsForm) {
      if (formData.arrival_date && !moment(formData.arrival_date, DATE_FORMAT, true).isValid()) {
        formError.arrival_date = 'Please enter the arrival date in the correct format'
      }

      if (formData.departure_date && !moment(formData.departure_date, DATE_FORMAT, true).isValid()) {
        formError.departure_date = 'Please enter the departure date in the correct format'
      }

      if (formData.arrival_time && !moment(formData.arrival_time, TIME_FORMAT, true).isValid()) {
        formError.arrival_time = 'Please enter the arrival time in the correct format'
      }

      if (formData.departure_time && !moment(formData.departure_time, TIME_FORMAT, true).isValid()) {
        formError.departure_time = 'Please enter the departure time in the correct format'
      }
    }

    return formError
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.sendForm(true)
  }

  onSave = (event) => {
    event.preventDefault()
    this.sendForm()
  }

  sendForm = async(submit = false) => {
    this.setState({
      sending: true,
    })

    let formError = this.onValidate(submit)
    const formErrorKeys = Object.keys(formError)

    if (!formErrorKeys.length) {
      const traveller_submitted = submit ? { traveller_submitted: submit } : {}
      const includeFlights = this.state.displayFlightsForm
      const flights_submitted = includeFlights && submit ? { flights_submitted: submit } : {}
      const formatDateFields = this.formatDateFields(this.state.formData, DATE_FORMAT, ISO_DATE_FORMAT)
      const formData = Object.assign({}, this.state.formData, formatDateFields, { ...traveller_submitted, ...flights_submitted })

      try {
        await TravellerService.updateDetails({
          ...formData,
          vendor_id: this.props.vendorId,
          group_count: this.state.totalTravellers,
          with_flights: includeFlights,
          only_flights: false,
        })

        const orderItem = Object.assign({}, this.props.data)
        orderItem.traveller = Object.assign(orderItem.traveller, formData, { ...traveller_submitted, ...flights_submitted })
        orderItem.guestname = getGuestNameForTourReservation(orderItem.traveller)

        this.props.updateTravellerData(orderItem)

        this.setState({
          success: true,
        })
      } catch (e) {
        formError = {
          error: 'Server internal error.',
        }
      }
    }

    this.setState({
      formError: formError,
      sending: false,
    })
  }

  onHide = () => {
    this.setState({
      formError: {},
      traveller: {},
      loading: true,
      sending: false,
      success: false,
      totalTravellers: 0,
      tourFlightDetailsRequired: false,
      displayFlightsForm: false,
      formData: travellerFormData(),
    })
    this.props.onHide()
  }

  render() {
    const { show, data } = this.props

    if (!data) {
      return null
    }

    const formError = Object.keys(this.state.formError)

    return (
      <Modal className="static-modal" show={show} onHide={this.onHide} dialogClassName="traveller-details-modal">
        <Modal.Body className="traveller-modal">
          {this.state.loading && (
            <div className="spinner-container">
              <MDSpinner className="spinner" size={100} />
            </div>
          )}
          {!this.state.loading && (
            <Form
              data={this.state.formData}
              traveller={this.state.traveller}
              displayFlightsForm={this.state.displayFlightsForm}
              tourFlightDetailsRequired={this.state.tourFlightDetailsRequired}
              passportRequired={this.state.passportRequired}
              disablePhotoIdValidation={this.state.disablePhotoIdValidation}
              schema={Object.assign({}, travellerSchema, travellerFlightSchema)}
              handleImageChange={this.handleImageChange}
              handleFieldChange={this.handleFieldChange}
              handleDisplayFlightsForm={this.handleDisplayFlightsForm}
              getValidationState={this.getValidationState}
              onSubmit={this.onSubmit}
              onHide={this.onHide}
              onSave={this.onSave}
            />
          )}
          <br /><br />
          {this.state.sending && (
            <div className="spinner-container">
              <MDSpinner className="spinner" singleColor={'#000'} size={100} />
            </div>
          )}
          {formError.length > 0 && (
            <Alert bsStyle="danger">
              {formError.map((e) => (<p key={e}>{this.state.formError[e]}</p>))}
            </Alert>
          )}
          {this.state.success && (
            <Alert bsStyle="success">
              <p>Success</p>
            </Alert>
          )}
        </Modal.Body>
      </Modal>
    )
  }
}
