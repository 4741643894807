import VoucherService from '../../services/VoucherService'
import TravellerService from '../../services/TravellerService'
import { validateItemStatus } from './validators'
import getReservationHistory from './getReservationHistory'
import { getGuestName, getNumberOfAdults, getNumberOfChildren, getNumberOfInfants, surchargePaidOnItem } from './items'

export const createRow = (order, item, reservationHistory, traveller) => {
  item.reservation = item.reservation || {}
  item.reservation.currency_code = order.currency_code
  item.reservation.fk_property_id = item.offer_package.fk_property_id
  item.reservation.property_name = item.offer_package.property_name
  item.reservation.fk_room_type_id = item.offer_package.fk_room_type_id

  return {
    id_item: item.id,
    id_order: order.id,
    id_vendor: item.fk_vendor_id,
    id_offer: item.offer.id_salesforce_external,
    booking_number: item.booking_number,
    booking_type: item.offer.booking_type,
    customer_full_name: order.customer_full_name,
    customer_email: order.customer_email,
    customer_phone: order.customer_phone,
    customer_phone_prefix: order.customer_phone_prefix,
    created_at: order.created_at,
    offer_name: item.offer.name,
    offer_slug: item.offer.slug,
    package_name: item.offer_package.name,
    reservation_made: item.reservation_made,
    reservation_type: item.reservation_type,
    reservation: item.reservation,
    package_nights: item.number_of_nights,
    package_days: item.number_of_days,
    status: item.status,
    offer_type: item.offer.type,
    tour_flight_details_required: item.tour_flight_details_required || false,
    surcharge_paid: surchargePaidOnItem(item),
    guestname: getGuestName(item, traveller),
    guest_special_requests: (item.reservation.guest_special_requests ? item.reservation.guest_special_requests : ''),
    number_of_adults: getNumberOfAdults(item),
    number_of_children: getNumberOfChildren(item),
    number_of_infants: getNumberOfInfants(item),
    reservation_history: reservationHistory,
    traveller: traveller || {},
    type: item.offer.type,
    voucher: {},
    on_hold: item.on_hold,
  }
}

export const getItemsFromOrders = async(vendorId, offer, orders = [], travellerDetails = false, filters = {}, vouchers = null) => {
  const result = []
  const bookingNumbers = []
  const showCancelled = typeof filters.showCancelled === 'undefined' ? true : filters.showCancelled
  const filterByBookingNumbers = typeof filters.bookingNumbers === 'undefined' ? null : filters.bookingNumbers
  for (const order of orders) {
    let travellers = []
    if (travellerDetails) {
      travellers = await TravellerService.getDetails(order.id, true)
    }
    for (const item of order.items) {
      if (showCancelled === false && item.status !== 'completed') {
        continue
      }
      if (!validateItemStatus(item.status, item.offer.type)) {
        continue
      }
      if (filterByBookingNumbers && !filterByBookingNumbers.includes(item.booking_number)) {
        continue
      }
      const traveller = travellers.find(
        data => data.booking_number === item.booking_number,
      )
      bookingNumbers.push(item.booking_number)

      item.tour_flight_details_required = traveller?.purchased_item?.tour_flight_details_required ?? offer.tour_flight_details_required

      result.push(createRow(order, item, await getReservationHistory(order, item, vendorId, 1, 5), traveller))
    }
  }

  if (result.length === 0) {
    return result
  }

  const voucherResponse = vouchers ? { vouchers } : await VoucherService.getFilteredVouchers({
    page: 1,
    limit: bookingNumbers.length,
    vendorId,
    offerId: result[0].id_offer,
    bookingNumbers,
  })
  return result.map((result, index) => Object.assign({}, result, {
    voucher: voucherResponse.vouchers.find((v) => v.voucher === result.booking_number) || {},
  }))
}
