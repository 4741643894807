import { request } from './common'

export default class PromotionRequestService {
  static create(vendorId, body) {
    return request(
      `${window.configs.VENDOR_HOST}/api/vendor/${vendorId}/promotions`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      },
    )
  }

  static get(vendorId, promotionId) {
    return request(
      `${window.configs.VENDOR_HOST}/api/vendor/${vendorId}/promotions/${promotionId}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    )
  }

  static getPage(vendorId, params) {
    return request(
      `${window.configs.VENDOR_HOST}/api/vendor/${vendorId}/promotions`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        params,
      },
    )
  }
}
