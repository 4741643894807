import moment from 'moment-timezone'
import fileDownload from 'js-file-download'

import { getCheckInDate, getCheckOutDate } from '../../components/helpers/items'
import { getItemsFromOrders } from '../../components/helpers/getItems'
import { getTourItemsFromOrders } from '../../components/helpers/getTourItems'

import OrderService from '../../services/OrderService'

import { camelCaseToCapitalized } from '../../utils/camelCaseToCapitalized'

import { OFFER_TYPE_TOUR_V2 } from '../../constants/offer-constants'

const limit = 500

const escape = val => `"${val || ''}"`

export default async function({
  vendorId,
  offer,
  filtersData,
}) {
  const headings = [
    'Redemption Code',
    'Tour Duration',
    'Tour Date',
    'Tour End Date',
    offer.type === OFFER_TYPE_TOUR_V2 ? 'Deal Option Name' : 'Package Name',
    'Bookings Status',
    'Booking Name',
    'Email',
    'Title',
    'First Name',
    'Middle Name',
    'Last Name',
    'Gender',
    'Weight',
    'DOB',
    'Photo ID Type',
    'Passport #',
    'Passport Expiry',
    'Nationality',
    'Country',
    'Contact Number',
    'Street Address',
    'City',
    'Postcode',
    'State',
    'Bedding Type',
    'Medical Conditions',
    'Covid Vaccination Status',
    'Mobility Requirements',
    'Dietary Requirements',
    'Conditions',
    'Requests',
    'Booking Request',
    'Emergency Contact Name',
    'Emergency Contact Relationship',
    'Emergency Contact Phone',
    'Emergency Contact Email',
    'Pre-Tour Accommodation',
    'Post-Tour Accommodation',
    'Arrival Airport',
    'Arrival Date',
    'Arrival Flight_number',
    'Arrival Time',
    'Departure Airport',
    'Departure Date',
    'Departure Flight_number',
    'Departure Time',
    ...(offer.type === OFFER_TYPE_TOUR_V2 ? ['Room Type', 'Room Number'] : []),
  ].join(',')

  let done = false
  let page = 1
  let result = `${headings}\r\n`
  let departureDate = null
  let showCancelled = true

  if (filtersData.departureDate) {
    departureDate = filtersData.departureDate
    showCancelled = false
  }

  while (!done) {
    const response = await OrderService.getCompletedVendorPurchases({
      page,
      limit,
      vendorId,
      offerId: offer.type === OFFER_TYPE_TOUR_V2 ? offer.id : offer.id_salesforce_external,
      type: offer.type,
      departureDate,
      showCancelled,
    })
    if (response.result.length === 0) {
      done = true
      continue
    }

    if (offer.type === OFFER_TYPE_TOUR_V2) {
      const tourItems = await getTourItemsFromOrders(offer, response.result, true)

      for (const tourItem of tourItems) {
        for (const traveller of tourItem.travellers) {
          result += [
            escape(tourItem.booking_number),
            escape(moment(tourItem.end_date).diff(moment(tourItem.start_date), 'days')),
            escape(tourItem.start_date),
            escape(tourItem.end_date),
            escape(tourItem.deal_option_name),
            escape(tourItem.status),
            escape(tourItem.customer_full_name),
            escape(traveller.email),
            escape(traveller.title),
            escape(traveller.first_name),
            escape(traveller.middle_name),
            escape(traveller.last_name),
            escape(traveller.gender),
            escape(traveller.weight),
            escape(traveller.dob && moment.utc(traveller.dob).format('YYYY-MM-DD')),
            escape(traveller.photo_id_type ?? 'Passport'),
            escape(traveller.passport_number),
            escape(traveller.passport_expiry && moment.utc(traveller.passport_expiry).format('YYYY-MM-DD')),
            escape(traveller.nationality),
            escape(traveller.country),
            escape(traveller.contact_number),
            escape(traveller.street_address),
            escape(traveller.city),
            escape(traveller.postcode),
            escape(traveller.state),
            escape(traveller.bedding_configuration),
            escape(traveller.medical_conditions),
            escape(traveller.covid_vaccination_status),
            escape(traveller.mobility_requirements),
            escape(traveller.dietary_requirements),
            escape(traveller.conditions),
            escape(traveller.requests),
            escape(''),
            escape(traveller.emergency_contact_name),
            escape(traveller.emergency_contact_relationship),
            escape(traveller.emergency_contact_phone),
            escape(traveller.emergency_contact_email),
            escape(traveller.pre_tour_accommodation),
            escape(traveller.post_tour_accommodation),
            escape(traveller.arrival_airport),
            escape(traveller.arrival_date && moment.utc(traveller.arrival_date).format('YYYY-MM-DD')),
            escape(traveller.arrival_flight_number),
            escape(traveller.arrival_time),
            escape(traveller.departure_airport),
            escape(traveller.departure_date && moment.utc(traveller.departure_date).format('YYYY-MM-DD')),
            escape(traveller.departure_flight_number),
            escape(traveller.departure_time),
            escape(camelCaseToCapitalized(traveller.room_type)),
            escape(traveller.room_number),
          ].join(',') + '\r\n'
        }
      }
    } else {
      const items = await getItemsFromOrders(vendorId, offer, response.result, true)

      for (const item of items) {
        result += [
          escape(item.booking_number),
          escape(item.package_days),
          escape(getCheckInDate(item, item.voucher)),
          escape(getCheckOutDate(item, item.voucher)),
          escape(item.package_name),
          escape(item.on_hold ? 'on hold' : item.status),
          escape(item.customer_full_name),
          escape(item.traveller.email),
          escape(item.traveller.title),
          escape(item.traveller.first_name),
          escape(item.traveller.middle_name),
          escape(item.traveller.last_name),
          escape(item.traveller.gender),
          escape(item.traveller.weight),
          escape(item.traveller.dob && moment.utc(item.traveller.dob).format('YYYY-MM-DD')),
          escape(item.traveller.photo_id_type ?? 'Passport'),
          escape(item.traveller.passport_number),
          escape(item.traveller.passport_expiry && moment.utc(item.traveller.passport_expiry).format('YYYY-MM-DD')),
          escape(item.traveller.nationality),
          escape(item.traveller.country),
          escape(item.traveller.contact_number),
          escape(item.traveller.street_address),
          escape(item.traveller.city),
          escape(item.traveller.postcode),
          escape(item.traveller.state),
          escape(item.traveller.bedding_configuration),
          escape(item.traveller.medical_conditions),
          escape(item.traveller.covid_vaccination_status),
          escape(item.traveller.mobility_requirements),
          escape(item.traveller.dietary_requirements),
          escape(item.traveller.conditions),
          escape(item.traveller.requests),
          escape(item.reservation.guest_special_requests),
          escape(item.traveller.emergency_contact_name),
          escape(item.traveller.emergency_contact_relationship),
          escape(item.traveller.emergency_contact_phone),
          escape(item.traveller.emergency_contact_email),
          escape(item.traveller.pre_tour_accommodation),
          escape(item.traveller.post_tour_accommodation),
          escape(item.traveller.arrival_airport),
          escape(item.traveller.arrival_date && moment.utc(item.traveller.arrival_date).format('YYYY-MM-DD')),
          escape(item.traveller.arrival_flight_number),
          escape(item.traveller.arrival_time),
          escape(item.traveller.departure_airport),
          escape(item.traveller.departure_date && moment.utc(item.traveller.departure_date).format('YYYY-MM-DD')),
          escape(item.traveller.departure_flight_number),
          escape(item.traveller.departure_time),
        ].join(',') + '\r\n'
      }
    }

    page = page + 1
  }

  await fileDownload(result, `travellers_details_${offer.id_salesforce_external ?? offer.id}_${moment().format('YYYYMMDDHHmmss')}.csv`)
}
