import React, { Component } from 'react'

import { Alert, Glyphicon, Col, Row, Button } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import classNames from 'classnames'
import MDSpinner from 'react-md-spinner'

import { OFFER_TYPE_TOUR_V2 } from '../../constants/offer-constants'
import { NO_AWAITING_DATES } from '../../constants/sales-performance-constants'

import Reporting from '../../services/ReportingService'

export default class TourV2SalesPerformance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fetching: false,
      error: null,
      performance: null,
      openPanel: false,
    }
  }

  dismissError = () => {
    this.setState({
      error: null,
    })
  }

  hidePanel = () => {
    this.setState({ openPanel: false })
  }

  fetchData = async() => {
    const shouldFetchPerformance = !this.state.performance

    this.setState({
      openPanel: true,
      fetching: shouldFetchPerformance,
    })

    if (!shouldFetchPerformance) {
      return
    }

    try {
      const response = await Reporting.getOfferPerformance(this.props.idOffer, OFFER_TYPE_TOUR_V2)
      this.setState({
        fetching: false,
        performance: response,
      })
    } catch (err) {
      this.setState({
        fetching: false,
        openPanel: false,
        error: err.message,
      })
    }
  }

  renderClosedPanel() {
    return (
      <Col sm={12} md={12}>
        <div className="sales-performance-buttons">
          <Button bsStyle="link" onClick={this.fetchData} className="open-panel-button">
            <Glyphicon glyph="signal" />
              View Sales Performance
            <Glyphicon glyph="chevron-down" className="small" />
          </Button>
        </div>
      </Col>
    )
  }

  renderLoadingPanel() {
    return (
      <Col sm={12} md={12}>
        <div className="spinner-container">
          <MDSpinner className="spinner" size={80} />
        </div>
      </Col>
    )
  }

  renderPerformancePanel() {
    return (
      <span>
        <Col sm={12} md={2}>
          <h5>Total Revenue</h5>
          {this.state.performance.totalRevenue}
          <h5>Total Active Packages</h5>
          <span className="text-large">
            {this.state.performance.totalSold}
          </span>
          <h5>Confirmed</h5>
          {this.state.performance.totalSold}
          <h5>Awaiting Dates</h5>
          {NO_AWAITING_DATES}
          <h5>Cancelled</h5>
          {this.state.performance.totalCancelled}
        </Col>
        <Col sm={12} md={10}>
          <div className="space-between">
            <h5>Performance by package</h5>
            <Button bsStyle="link" onClick={this.hidePanel}>
              <Glyphicon glyph="chevron-up" className="small primary" />
            </Button>
          </div>
          <BootstrapTable
            data={this.state.performance.packages}
            striped
            hover
            bordered
          >
            <TableHeaderColumn dataField="name" isKey width="60%">Package Name</TableHeaderColumn>
            <TableHeaderColumn dataField="sold">Confirmed</TableHeaderColumn>
            <TableHeaderColumn dataField="cancelled">Cancelled</TableHeaderColumn>
            <TableHeaderColumn dataField="totalRefunds">Refunds</TableHeaderColumn>
            <TableHeaderColumn dataField="revenue">Revenue</TableHeaderColumn>
          </BootstrapTable>
        </Col>
      </span>
    )
  }

  render() {
    const rowClassNames = classNames('sales-performance', {
      active: this.state.openPanel,
    })
    const shouldShowPerformance = !!(this.state.openPanel && this.state.performance)
    if (this.state.error) {
      return (
        <Alert bsStyle="danger" onDismiss={this.dismissError}>
          <h4>An Error occurred while fetching sales performance</h4>
          <p> {this.state.error} </p>
        </Alert>
      )
    }
    return (
      <Row className={rowClassNames}>
        {!this.state.openPanel && this.renderClosedPanel()}
        {this.state.openPanel && this.state.fetching && this.renderLoadingPanel()}
        {shouldShowPerformance && this.renderPerformancePanel()}
      </Row>
    )
  }
}
