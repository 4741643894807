import React, { Component } from 'react'
import { Glyphicon } from 'react-bootstrap'

import { camelCaseToCapitalized } from '../../utils/camelCaseToCapitalized'

export default class TourV2GuestCell extends Component {
  render() {
    const {
      row,
      travellerDetailsButton,
      isLETour,
    } = this.props

    const statuses = {
      guest: {
        submitted: {
          icon: 'ok-circle',
          className: 'glyphicon-color-green',
          message: row.guestname,
        },
        unsubmitted: {
          icon: 'warning-sign',
          className: 'glyphicon-color-yellow',
          message: 'Information required',
        },
      },
      flights: {
        submitted: {
          icon: 'plane',
          className: 'glyphicon-color-green',
          message: 'Flight information submitted',
        },
        unsubmitted: {
          icon: 'plane',
          className: 'glyphicon-color-yellow',
          message: 'Flight information required',
        },
      },
    }

    const isTourFlightDetailsRequired = (row) => {
      if (row.tour_flight_details_required) {
        return true
      }

      return false
    }

    const getStatus = (traveller, field) => {
      if (traveller[field]) {
        return 'submitted'
      }
      return 'unsubmitted'
    }

    const getTravellerStatus = (row) => ({
      guestStatus: statuses.guest[getStatus(row.traveller, 'traveller_submitted')],
      flightsStatus: statuses.flights[getStatus(row.traveller, 'flights_submitted')],
    })

    const getGlyphicon = (status) => (
      <div>
        <Glyphicon glyph={status.icon} className={status.className} />&nbsp;{status.message}
      </div>
    )

    const getTravellerName = (traveller) => `${traveller.first_name} ${traveller.middle_name ?? ''} ${traveller.last_name}`

    const getTravellerRoomDetails = (traveller) => {
      if (!traveller.room_type) {
        return ''
      }

      return `(${camelCaseToCapitalized(traveller.room_type)}${traveller.room_number ? ` - Room ${traveller.room_number}` : ''})`
    }

    const travellerStatus = getTravellerStatus(row)
    const tourFlightDetailsRequired = isTourFlightDetailsRequired(row)
    return (
      <div>
        <div>
          {getTravellerName(row.traveller)} {getTravellerRoomDetails(row.traveller)}
          {isLETour && getGlyphicon(travellerStatus.guestStatus)}
          {isLETour && tourFlightDetailsRequired && getGlyphicon(travellerStatus.flightsStatus)}
        </div>
        {isLETour && travellerDetailsButton}
      </div>
    )
  }
}
