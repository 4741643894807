import React from 'react'
import { LoginForm } from 'react-stormpath'
import { browserHistory } from 'react-router'
import { Row, Col, Button } from 'react-bootstrap'
import SocialGoogleButton from './SocialGoogleButton'

export default function LoginPage(props) {
  const isEnableGoodleLogin = window.configs.GOOGLE_LOGIN_ENABLED === 'true' && window.google

  const GoogleButton = isEnableGoodleLogin ? (
    <div>
      <p className="text-center"> - or -</p>
      <div htmlFor="password" className="col-xs-12 col-sm-4 control-label"/>
      <div className="col-xs-12 col-sm-4">
        <SocialGoogleButton />
      </div>
    </div>
  ) : ''

  return (
    <div className="container login-page">
      <Row>
        <Col sm={12} md={6} mdOffset={3}>
          <h2>Login</h2>
          <hr />
        </Col>
      </Row>
      <LoginForm>
        <div>
          <div className="sp-login-form">
            <div className="row">
              <div className="col-xs-12">
                <div className="form-horizontal">
                  <div className="form-group">
                    <label htmlfor="email" className="col-xs-12 col-sm-4 control-label">
                      Username or Email
                    </label>
                    <div className="col-xs-12 col-sm-4">
                      <input
                        type="text"
                        className="form-control"
                        id="sp-login-0"
                        name="login"
                        placeholder="Username or Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="password" className="col-xs-12 col-sm-4 control-label">
                      Password
                    </label>
                    <div className="col-xs-12 col-sm-4">
                      <input
                        type="password"
                        className="form-control"
                        id="sp-password-1"
                        name="password"
                        placeholder="Password"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-sm-offset-4 col-sm-4">
                      <div data-spIf="form.error">
                        <p
                          className="alert alert-danger"
                          data-spif="form.error"
                        >
                          <span>Username or password was incorrect</span>
                        </p>
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Login
                      </button>
                      <span className="forgot-password">
                        <Button bsStyle="link" onClick={() => {
                          browserHistory.push({
                            pathname: '/forgotpassword',
                          })
                        }}>Forgot Password?</Button>
                      </span>
                    </div>
                  </div>
                  {GoogleButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoginForm>
    </div>
  )
}
