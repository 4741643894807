import VoucherService from '../../services/VoucherService'
import TravellerService from '../../services/TravellerService'
import ReservationService from '../../services/ReservationService'
import { validateItemStatus, hasItemTraveller } from './validators'
import getReservationHistory from './getReservationHistory'
import { createRow as itemCreateRow } from './getItems'
import { ADDON_TYPE } from '../../constants/addon-constants'

export const createRow = (order, item, addonItem, reservationHistory, traveller) => {
  const orderItem = itemCreateRow(order, item, reservationHistory, traveller)

  return {
    id: addonItem.id,
    booking_number: addonItem.booking_number,
    name: addonItem.name,
    status: addonItem.status,
    created_at: addonItem.created_at,
    id_vendor: addonItem.vendor_salesforce_id,
    fare_type: addonItem.fare_type,
    fare_type_display: addonItem.fare_type_display,
    item: orderItem,
    voucher: {},
  }
}

export const getAddonItemsFromOrders = async({
  vendorId,
  addonId,
  showCancelled,
  addons = [],
}) => {
  const result = []
  const bookingNumbersByOffer = {}
  const reservationHistory = {}
  const travellers = {}

  for (const addon of addons) {
    if (typeof travellers[addon.order.id] === 'undefined') {
      travellers[addon.order.id] = []
    }
    if (hasItemTraveller(addon.item)) {
      travellers[addon.order.id] = await TravellerService.getDetails(addon.order.id, true)
    }
    if (showCancelled === false && addon.status !== 'completed') {
      continue
    }
    if (!validateItemStatus(addon.status, ADDON_TYPE)) {
      continue
    }
    if (addon.vendor_salesforce_id !== vendorId) {
      continue
    }
    if (addon.fk_opportunity_salesforce_id !== addonId) {
      continue
    }

    if (typeof reservationHistory[addon.item.id] === 'undefined') {
      reservationHistory[addon.item.id] = await getReservationHistory(addon.order, addon.item, vendorId, 1, 5)
    }

    const traveller = travellers[addon.order.id].find(
      data => data.booking_number === addon.item.booking_number,
    )

    const offerId = addon.item.offer.id_salesforce_external
    if (typeof bookingNumbersByOffer[offerId] === 'undefined') {
      bookingNumbersByOffer[offerId] = []
    }

    bookingNumbersByOffer[offerId].push(addon.booking_number)

    addon.item.tour_flight_details_required = addon.item.offer.tour_flight_details_required
    if (traveller) {
      addon.item.tour_flight_details_required = traveller.purchased_item.tour_flight_details_required
    }

    if (addon.item.offer_package.fk_property_id) {
      const property = await ReservationService.getProperty(addon.item.offer_package.fk_property_id)
      if (property && property.name) {
        addon.item.offer_package.property_name = property.name
      }
    }

    result.push(createRow(addon.order, addon.item, addon, reservationHistory[addon.item.id], traveller))
  }

  if (result.length === 0) {
    return result
  }

  const offersId = Object.keys(bookingNumbersByOffer)

  for (const offerId of offersId) {
    const voucherResponse = await VoucherService.getFilteredVouchers({
      page: 1,
      limit: bookingNumbersByOffer[offerId].length,
      vendorId,
      offerId: offerId,
      bookingNumbers: bookingNumbersByOffer[offerId],
    })

    result.filter(data => data.item.id_offer == offerId).forEach((data) => {
      data.voucher = voucherResponse.vouchers.find((v) => v.voucher === data.booking_number) || {}
    })
  }

  return result
}
