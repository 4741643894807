import React, { Component } from 'react'
import moment from 'moment-timezone'

import { CANCELLED, TOUR } from '../../constants/order-constants'

export default class CheckInDateCell extends Component {
  render() {
    const {
      checkInDate,
      previousCheckInDate,
      status,
      voucher,
      surchargePaid,
      hasReservation,
      type,
      isSurchargeWaived,
    } = this.props

    if (!hasReservation) {
      return null
    }

    const isTour = type === TOUR

    const checkInDatePlaceholder = checkInDate ? moment.utc(checkInDate).format('DD/MM/YYYY') : 'Check-in date not provided'
    const previousCheckInDatePlaceholder = previousCheckInDate ? moment.utc(previousCheckInDate).format('DD/MM/YYYY') : ''

    const isLogged = !!voucher.vendor_logged_at

    if (status === CANCELLED || isTour) {
      return <div className="subtext">{checkInDatePlaceholder}</div>
    }
    return (
      <div>
        {isLogged && <div className="subtext">Logged</div>}
        {previousCheckInDatePlaceholder && (
          <div className="text-warning">
            <div>Changed to {checkInDatePlaceholder}</div>
            <div>From {previousCheckInDatePlaceholder}</div>
          </div>
        )}
        {!previousCheckInDatePlaceholder && (
          <div>{checkInDatePlaceholder}</div>
        )}
        {isSurchargeWaived && (
          <div className="text-danger">Surcharge waived</div>
        )}
        {surchargePaid && <div className="subtext">Surcharge Paid</div>}
      </div>
    )
  }
}
