import { request } from './common'

export default class VoucherService {
  static async patch(data) {
    const baseUri = window.configs.VOUCHER_HOST + '/api/orders/vouchers'
    const requestUri = `${baseUri}/${data.id_vendor}/${data.id_offer}/${data.voucher}`
    return request(requestUri + '?' + data.params, {
      method: 'PATCH',
    })
  }

  static async get(data) {
    const baseUri = window.configs.VOUCHER_HOST + '/api/orders/vouchers'
    let requestUri = `${baseUri}/${data.id_vendor}/${data.id_offer}/${data.voucher}`
    if (data.no_cache) {
      requestUri += `?nocache=${data.no_cache}`
    }
    return request(requestUri, { method: 'GET' })
  }

  static async getUnloggedVouchers({ page, limit, vendorId, offerId }) {
    const baseUri = window.configs.VOUCHER_HOST + '/api/orders/vouchers'
    const requestUri = `${baseUri}/${vendorId}/${offerId}?page=${page}&limit=${limit}&status=unlogged`
    return request(requestUri, { method: 'GET' })
  }

  static async getFilteredVouchers({ page, limit, vendorId, offerId, bookingNumbers }) {
    const baseUri = window.configs.VOUCHER_HOST + '/api/orders/vouchers'
    const requestUri = `${baseUri}/${vendorId}/${offerId}?page=${page}&limit=${limit}&vouchers=${bookingNumbers.join(',')}`
    return request(requestUri, { method: 'GET' })
  }
}
