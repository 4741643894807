import { request } from './common'
import { OFFER_TYPE_TOUR } from '../constants/offer-constants'

export default class ReportingService {
  static getCompletedVendorPurchases(idVendor, idOffer = null) {
    const requestUri = window.configs.REPORTING_HOST + '/api/reporting/orders'
    let requestParams = `id_vendor=${idVendor}`

    if (idOffer) {
      requestParams = requestParams + `&id_offer=${idOffer}`
    }
    return request(requestUri + '?' + requestParams, { method: 'GET' })
  }

  static async getOfferPerformance(idOffer, offerType = OFFER_TYPE_TOUR) {
    const url = `${window.configs.REPORTING_HOST}/api/reporting/offers/${idOffer}/performance?type=${offerType}`

    const response = await request(url)
    return response.result
  }

  static async getAddonPerformance(addonId) {
    const url = `${window.configs.REPORTING_HOST}/api/reporting/addons/${addonId}/performance`

    const response = await request(url)
    return response.performance
  }

  static async setGeneratingTravellerPassportsPDF({ vendorId, offerId, departureDate }) {
    const url = `${window.configs.REPORTING_HOST}/api/reporting/traveller/passports?vendor_id=${vendorId}&offer_id=${offerId}&departure_date=${departureDate}`

    const response = await request(url)
    return response.result
  }

  static async getStatusGeneratingTravellerPassportsPDF(jobId) {
    const url = `${window.configs.REPORTING_HOST}/api/reporting/traveller/passports_status?job_id=${jobId}`

    const response = await request(url)
    return response.result
  }
}
