import React, { Component } from 'react'
import moment from 'moment-timezone'

class DepartureDatesOptions extends Component {
  optgroup = (name, dates) => {
    if (dates && dates.length) {
      return (
        <optgroup label={name}>
          {dates.map((date) => (<option key={`departureDates_${date}`} value={date}>Start {moment.utc(date).format('ddd, D MMM Y')}</option>))}
        </optgroup>
      )
    }

    return null
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  render() {
    const {
      type,
      departureDates,
    } = this.props

    return this.optgroup(this.capitalize(type), departureDates[type])
  }
}

export default DepartureDatesOptions
