import moment from 'moment-timezone'
import fileDownload from 'js-file-download'

import OrderService from '../../services/OrderService'

import { getItemsFromOrders } from '../../components/helpers/getItems'
import { getCheckInDate, getCheckOutDate, formatDate } from '../../components/helpers/items'
import { getTourItemsFromOrders } from '../../components/helpers/getTourItems'
import { getLeadPassengerName, getCustomerPhoneWithCountryCallingCode } from '../../components/helpers/tourItems'

import { DYNAMIC_PRICING_TYPES, OFFER_TYPE_TOUR_V2 } from '../../constants/offer-constants'

// This is maximum allowed by order service
const limit = 250

const escape = val => `"${val}"`

export default async function({ vendorId, offer }) {
  const headings = [
    'Booking ID',
    'Booking Created',
    offer.type === OFFER_TYPE_TOUR_V2 ? 'Traveller Name' : 'Customer Name',
    'Customer Email',
    'Customer Phone',
    'Primary Guest',
    'Additional Request info',
    'Adult Guests',
    'Child Guests',
    offer.type === OFFER_TYPE_TOUR_V2 ? 'Deal Option Name' : 'Package Name',
    offer.type === OFFER_TYPE_TOUR_V2 ? 'Offer Duration' : 'Package Nights',
    offer.type === OFFER_TYPE_TOUR_V2 ? 'Start Date' : 'Check In Date',
    offer.type === OFFER_TYPE_TOUR_V2 ? 'End Date' : 'Check Out Date',
    'Logged',
    'Surcharge',
    'Status',
  ].join(',')

  let done = false
  let page = 1
  let additionalHeadings = ''
  if (DYNAMIC_PRICING_TYPES.includes(offer.type)) {
    additionalHeadings = ',Cost Price,Sell Price'
  }
  let result = `${headings}${additionalHeadings}\r\n`
  while (!done) {
    const response = await OrderService.getCompletedVendorPurchases({
      page,
      limit,
      vendorId,
      offerId: offer.type === OFFER_TYPE_TOUR_V2 ? offer.id : offer.id_salesforce_external,
      ...(offer.type === OFFER_TYPE_TOUR_V2 ? { type: offer.type } : {}),
    })
    if (response.result.length === 0) {
      done = true
      continue
    }

    if (offer.type === OFFER_TYPE_TOUR_V2) {
      const tourItems = await getTourItemsFromOrders(offer, response.result, true)

      for (const tourItem of tourItems) {
        for (const traveller of tourItem.travellers) {
          const values = [
            tourItem.booking_number,
            escape(formatDate(tourItem.created_at, false, 'ddd, D MMM Y')),
            escape(`${traveller.first_name} ${traveller.middle_name ?? ''} ${traveller.last_name}`.replace(/#|&/g, '')),
            escape(tourItem.customer_email),
            escape(getCustomerPhoneWithCountryCallingCode(tourItem.customer_phone_prefix, tourItem.customer_phone)),
            escape(getLeadPassengerName(tourItem.tourItemTravellers ?? []).replace(/#|&/g, '')),
            escape(''),
            tourItem.number_of_adults,
            tourItem.number_of_children,
            escape(tourItem.deal_option_name),
            escape(tourItem.tour_duration),
            tourItem.start_date,
            tourItem.end_date,
            '',
            '',
            tourItem.status,
          ]

          result += values.join(',') + '\r\n'
        }
      }
    } else {
      const items = await getItemsFromOrders(vendorId, offer, response.result)

      for (const item of items) {
        const values = [
          item.booking_number,
          escape(formatDate(item.created_at, false, 'DD/MM/YYYY')),
          escape(item.customer_full_name.replace(/#|&/g, '')),
          escape(item.customer_email),
          escape(((item.customer_phone_prefix != null) && `(+${item.customer_phone_prefix}) `) + item.customer_phone),
          escape(item.guestname.replace(/#|&/g, '')),
          escape(item.guest_special_requests.replace(/#|&/g, '')),
          item.number_of_adults,
          item.number_of_children,
          escape(item.package_name),
          escape(item.package_nights),
          getCheckInDate(item, item.voucher),
          getCheckOutDate(item, item.voucher),
          item.voucher.vendor_logged_at ? 'YES' : 'NO',
          item.surcharge_paid ? 'YES' : 'NO',
          item.status,
        ]
        if (DYNAMIC_PRICING_TYPES.includes(offer.type)) {
          values.push(`${item.reservation.room_rate_cost_currency} ${item.reservation.room_rate_cost_amount}`)
          values.push(`${item.reservation.room_rate_currency} ${item.reservation.room_rate_amount}`)
        }
        result += values.join(',') + '\r\n'
      }
    }
    page = page + 1
  }

  const fileName = `booking_report_${offer.type === OFFER_TYPE_TOUR_V2 ? offer.id : offer.id_salesforce_external}_${moment().format('YYYYMMDDHHmmss')}.csv`
  await fileDownload(result, fileName)
}
