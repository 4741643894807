import qs from 'qs'
import { request } from './common'
import fileDownload from 'js-file-download'
import mime from 'mime-types'

export default class DatesRequestsService {
  static fetchDatesRequests({ vendorId, page, limit = 1 } = {}) {
    const requestParams = {
      vendorId,
      page,
      limit,
    }

    return request(`${window.configs.COMMERCE_HOST}/api/orders/dates-request?${qs.stringify(requestParams)}`, { method: 'GET' })
      .then((response) => response)
  }

  static updateDatesRequests(status, datesRequestID, rejectionMessage) {
    return request(`${window.configs.COMMERCE_HOST}/api/orders/dates-request/${datesRequestID}`,
      {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          status,
          rejectionMessage,
        }),
      },
    ).then((response) => response)
  }

  static async getDatesRequestAttachmentUrl(datesRequestId, userName, attachmentId, index) {
    try {
      const url = `${window.configs.COMMERCE_HOST}/api/orders/dates-request/${datesRequestId}/attachment/${attachmentId}`

      const response = await request(
        url,
        {
          method: 'GET',
        },
        'blob',
      )
      const fileExtension = mime.extension(response.type)

      const fileNameHeader = `${userName}-dates-request-attachment-${index}.${fileExtension}`
      await fileDownload(response, fileNameHeader)
    } catch (error) {
      console.error('Error in getDatesRequestAttachmentUrl:', error)
    }
  }
}
