import React, { Component } from 'react'
import { Alert, Button, Form, FormGroup, FormControl, ControlLabel, Col, Row } from 'react-bootstrap'
import MDSpinner from 'react-md-spinner'
import AuthService from '../../services/AuthService'

export default class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.token = props.params.reset_token
    this.state = {
      updating: false,
      error: null,
      password: '',
      completed: false,
    }
  }

  onPasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    })
  }

  onSubmit = async(event) => {
    event.preventDefault()
    this.setState({ updating: true, error: null, completed: false })
    try {
      await AuthService.sendResetPassword({
        password: this.state.password,
        token: this.token,
      })
      this.setState({ updating: false, completed: true })
    } catch (err) {
      this.setState({ updating: false, error: err.message })
    }
  }

  render() {
    return (
      <div className="container center">
        <Row>
          <Col sm={12} md={6} mdOffset={3}>
            <h2>Reset Password</h2>
            <hr />
            {this.state.error && (
              <Alert bsStyle="danger">{this.state.error}</Alert>
            )}
            {this.state.completed && (
              <Alert bsStyle="success">Password has been successfully changed, please login with your new password</Alert>
            )}
          </Col>
        </Row>
        <Form horizontal>
          <FormGroup>
            <Col sm={12} md={6} mdOffset={3}>
              <ControlLabel>Password</ControlLabel>
              <FormControl type="password" value={this.state.password} onChange={this.onPasswordChange} />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm={12} md={6} mdOffset={3}>
              <Button
                bsSize="large"
                bsStyle="primary"
                disabled={this.state.updating}
                onClick={this.onSubmit}>
                {this.state.updating ? (
                  <div className="spinner-container">
                    <MDSpinner className="spinner" singleColor={'#FFF'} size={16} />
                  </div>
                ) : 'Reset Password'}
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    )
  }
}
