import React, { Component } from 'react'
import { FormGroup, FormControl, ControlLabel, Col, Panel, Form, Button } from 'react-bootstrap'

import UploadImage from '../../Common/UploadImage'
import * as libCountries from '@luxuryescapes/lib-countries'

import {
  TRAVELLER_WEIGHT_OPTIONS,
  TRAVELLER_TITLE_OPTIONS,
  TRAVELLER_COVID_VACCINATION_STATUS_OPTIONS,
} from '../../../constants/traveller-details'

export default class TravellerDetailsForm extends Component {
  render() {
    const {
      handleFieldChange,
      handleImageChange,
      handleDisplayFlightsForm,
      getValidationState,
      tourFlightDetailsRequired,
      passportRequired,
      disablePhotoIdValidation,
      displayFlightsForm,
      traveller,
      data,
      schema,
      onHide,
      onSubmit,
      onSave,
    } = this.props

    const countries = libCountries.getCountries()

    return (
      <Form className="travellerForm" onSubmit={onSubmit}>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Passport Information</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            {!disablePhotoIdValidation && (<FormGroup controlId="travellerPassport" validationState={getValidationState('passport_image')}>
              <Col className="travellerFormCol" sm={12} md={12}>
                <ControlLabel>{`Upload the Photo page of your ${passportRequired ? 'passport' : 'photo ID'}`}</ControlLabel>
                <UploadImage handler={handleImageChange} image={data.passport_image || '/assets/passport-scan.png'} />
              </Col>
            </FormGroup>)}
            <FormGroup controlId="travellerTitle" validationState={getValidationState('title')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.title.label}</ControlLabel>
                <FormControl
                  componentClass="select"
                  name="title"
                  placeholder=""
                  defaultValue={data.title}
                  onChange={handleFieldChange}
                >
                  <option key={'title'} value="" />
                  {TRAVELLER_TITLE_OPTIONS.map((option) => (<option key={`title_${option}`} value={option}>{option}</option>))}
                </FormControl>
              </Col>
            </FormGroup>
            <FormGroup controlId="travellerFirstName" validationState={getValidationState('first_name')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.first_name.label}</ControlLabel>
                <FormControl
                  type="text"
                  name="first_name"
                  placeholder={schema.first_name.label}
                  value={data.first_name}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="travellerMiddleName" validationState={getValidationState('middle_name')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.middle_name.label}</ControlLabel>
                <FormControl
                  type="text"
                  name="middle_name"
                  placeholder={schema.middle_name.label}
                  value={data.middle_name}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="travellerLastName" validationState={getValidationState('last_name')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.last_name.label}</ControlLabel>
                <FormControl
                  type="text"
                  name="last_name"
                  placeholder={schema.last_name.label}
                  value={data.last_name}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="travellerGender" validationState={getValidationState('gender')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.gender.label}</ControlLabel>
                <FormControl
                  componentClass="select"
                  name="gender"
                  placeholder=""
                  defaultValue={data.gender}
                  onChange={handleFieldChange}
                >
                  <option key={'gender'} value="" />
                  {['Male', 'Female', 'Other'].map((option) => (<option key={`gender_${option}`} value={option}>{option}</option>))}
                </FormControl>
              </Col>
            </FormGroup>
            {schema.weight !== undefined && <FormGroup controlId="travellerWeight" validationState={getValidationState('weight')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.weight.label}</ControlLabel>
                <FormControl
                  componentClass="select"
                  name="weight"
                  placeholder=""
                  defaultValue={data.weight}
                  onChange={handleFieldChange}
                >
                  <option key={'weight'} value="" />
                  {TRAVELLER_WEIGHT_OPTIONS.map((option) => (<option key={`weight_${option}`} value={option}>{option}</option>))}
                </FormControl>
              </Col>
            </FormGroup>}
            <FormGroup controlId="travellerDob" validationState={getValidationState('dob')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.dob.label}</ControlLabel>
                <FormControl
                  type="text"
                  name="dob"
                  placeholder="DD/MM/YYYY"
                  value={data.dob}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
            {passportRequired && !disablePhotoIdValidation && <FormGroup controlId="travellerPassportNumber" validationState={getValidationState('passport_number')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.passport_number.label}</ControlLabel>
                <FormControl
                  type="text"
                  name="passport_number"
                  value={data.passport_number}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>}
            {passportRequired && !disablePhotoIdValidation && <FormGroup controlId="travellerPassportExpiry" validationState={getValidationState('passport_expiry')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.passport_expiry.label}</ControlLabel>
                <FormControl
                  type="text"
                  name="passport_expiry"
                  placeholder="DD/MM/YYYY"
                  value={data.passport_expiry}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>}
            {passportRequired && !disablePhotoIdValidation && <FormGroup controlId="travellerNationality" validationState={getValidationState('nationality')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.nationality.label}</ControlLabel>
                <FormControl
                  componentClass="select"
                  name="nationality"
                  placeholder=""
                  defaultValue={data.nationality}
                  onChange={handleFieldChange}
                >
                  <option key={'nationality'} value="" />
                  {countries.map((country) => (<option key={`nationality_${country}`} value={country}>{country}</option>))}
                </FormControl>
              </Col>
            </FormGroup>}
          </Panel.Body>
        </Panel>

        <Panel>
          <Panel.Heading>
            <Panel.Title>Bedding Configuration</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <FormGroup controlId="travellerBeddingConfiguration" validationState={getValidationState('bedding_configuration')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.bedding_configuration.label}</ControlLabel>
                <FormControl
                  componentClass="select"
                  name="bedding_configuration"
                  placeholder=""
                  defaultValue={data.bedding_configuration}
                  onChange={handleFieldChange}
                >
                  <option key={'bedding_configuration'} value="" />
                  {['Double', 'Twin', 'Single'].map((bedding) => (<option key={`bedding_configuration_${bedding}`} value={bedding}>{bedding}</option>))}
                </FormControl>
              </Col>
            </FormGroup>
          </Panel.Body>
        </Panel>

        <Panel>
          <Panel.Heading>
            <Panel.Title>Contact Information</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <FormGroup controlId="travellerContactNumber" validationState={getValidationState('contact_number')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.contact_number.label}</ControlLabel>
                <FormControl
                  type="tel"
                  name="contact_number"
                  placeholder="+61..."
                  value={data.contact_number}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="travellerEmail" validationState={getValidationState('email')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.email.label}</ControlLabel>
                <FormControl
                  type="email"
                  name="email"
                  pattern="\S+@\S+\.\S+"
                  title="For example, myname@example.com"
                  placeholder="myname@example.com"
                  value={data.email}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
          </Panel.Body>
        </Panel>

        <Panel>
          <Panel.Heading>
            <Panel.Title>Residential Address</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <FormGroup controlId="travellerStreetAddress" validationState={getValidationState('street_address')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.street_address.label}</ControlLabel>
                <FormControl
                  type="text"
                  name="street_address"
                  value={data.street_address}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="travellerCity" validationState={getValidationState('city')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.city.label}</ControlLabel>
                <FormControl
                  type="text"
                  name="city"
                  value={data.city}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="travellerPostcode" validationState={getValidationState('postcode')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.postcode.label}</ControlLabel>
                <FormControl
                  type="text"
                  name="postcode"
                  value={data.postcode}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="travellerState" validationState={getValidationState('state')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.state.label}</ControlLabel>
                <FormControl
                  type="text"
                  name="state"
                  value={data.state}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="travellerCountry" validationState={getValidationState('country')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.country.label}</ControlLabel>
                <FormControl
                  componentClass="select"
                  name="country"
                  placeholder=""
                  defaultValue={data.country}
                  onChange={handleFieldChange}
                >
                  <option key={'country'} value="" />
                  {countries.map((country) => (<option key={`country_${country}`} value={country}>{country}</option>))}
                </FormControl>
              </Col>
            </FormGroup>
          </Panel.Body>
        </Panel>

        <Panel>
          <Panel.Heading>
            <Panel.Title>Emergency Contact</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <FormGroup controlId="travellerEmergencyContactNumber" validationState={getValidationState('emergency_contact_name')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.emergency_contact_name.label}</ControlLabel>
                <FormControl
                  type="text"
                  name="emergency_contact_name"
                  value={data.emergency_contact_name}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="travellerEmergencyContactRelationship" validationState={getValidationState('emergency_contact_relationship')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.emergency_contact_relationship.label}</ControlLabel>
                <FormControl
                  type="text"
                  name="emergency_contact_relationship"
                  value={data.emergency_contact_relationship}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="travellerEmergencyContactPhone" validationState={getValidationState('emergency_contact_phone')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.emergency_contact_phone.label}</ControlLabel>
                <FormControl
                  type="tel"
                  name="emergency_contact_phone"
                  placeholder="+61..."
                  value={data.emergency_contact_phone}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="travellerEmergencyContactEmail" validationState={getValidationState('emergency_contact_email')}>
              <Col className="travellerFormCol" sm={6} md={6}>
                <ControlLabel>{schema.emergency_contact_email.label}</ControlLabel>
                <FormControl
                  type="email"
                  name="emergency_contact_email"
                  pattern="\S+@\S+\.\S+"
                  title="For example, myname@example.com"
                  placeholder="myname@example.com"
                  value={data.emergency_contact_email}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
          </Panel.Body>
        </Panel>

        <Panel>
          <Panel.Heading>
            <Panel.Title>Medical Details</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <FormGroup controlId="travellerMedicalConditions" validationState={getValidationState('medical_conditions')}>
              <ControlLabel>{schema.medical_conditions.label}</ControlLabel>
              <Col className="travellerFormCol" sm={12} md={12}>
                <FormControl
                  componentClass="textarea"
                  name="medical_conditions"
                  value={data.medical_conditions}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
            {schema.covid_vaccination_status !== undefined && <FormGroup controlId="travellerCovidVaccinationStatus" validationState={getValidationState('covid_vaccination_status')}>
              <ControlLabel>{schema.covid_vaccination_status.label}</ControlLabel>
              <Col className="travellerFormCol" sm={12} md={12}>
                <FormControl
                  componentClass="select"
                  name="covid_vaccination_status"
                  placeholder=""
                  defaultValue={data.covid_vaccination_status}
                  onChange={handleFieldChange}
                >
                  <option key={'covid_vaccination_status'} value="" />
                  {TRAVELLER_COVID_VACCINATION_STATUS_OPTIONS.map((option) => (<option key={`covid_vaccination_status_${option}`} value={option}>{option}</option>))}
                </FormControl>
              </Col>
            </FormGroup>}
            <FormGroup controlId="travellerMobilityRequirements" validationState={getValidationState('mobility_requirements')}>
              <ControlLabel>{schema.mobility_requirements.label}</ControlLabel>
              <Col className="travellerFormCol" sm={12} md={12}>
                <FormControl
                  componentClass="textarea"
                  name="mobility_requirements"
                  value={data.mobility_requirements}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="travellerDietaryRequirements" validationState={getValidationState('dietary_requirements')}>
              <ControlLabel>{schema.dietary_requirements.label}</ControlLabel>
              <Col className="travellerFormCol" sm={12} md={12}>
                <FormControl
                  componentClass="textarea"
                  name="dietary_requirements"
                  value={data.dietary_requirements}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="travellerConditions" validationState={getValidationState('conditions')}>
              <ControlLabel>{schema.conditions.label}</ControlLabel>
              <Col className="travellerFormCol" sm={12} md={12}>
                <FormControl
                  componentClass="textarea"
                  name="conditions"
                  value={data.conditions}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
          </Panel.Body>
        </Panel>

        <Panel>
          <Panel.Heading>
            <Panel.Title>{schema.requests.label}</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <FormGroup controlId="travellerRequests" validationState={getValidationState('requests')}>
              <Col className="travellerFormCol" sm={12} md={12}>
                <FormControl
                  componentClass="textarea"
                  name="requests"
                  value={data.requests}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
          </Panel.Body>
        </Panel>

        <Panel>
          <Panel.Heading>
            <Panel.Title>Accommodation Details</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <FormGroup controlId="travellerPreTourAccommodation" validationState={getValidationState('pre_tour_accommodation')}>
              <ControlLabel>{schema.pre_tour_accommodation.label}</ControlLabel>
              <Col className="travellerFormCol" sm={12} md={12}>
                <FormControl
                  componentClass="textarea"
                  name="pre_tour_accommodation"
                  value={data.pre_tour_accommodation}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="travellerPostTourAccommodation" validationState={getValidationState('post_tour_accommodation')}>
              <ControlLabel>{schema.post_tour_accommodation.label}</ControlLabel>
              <Col className="travellerFormCol" sm={12} md={12}>
                <FormControl
                  componentClass="textarea"
                  name="post_tour_accommodation"
                  value={data.post_tour_accommodation}
                  onChange={handleFieldChange}
                />
              </Col>
            </FormGroup>
          </Panel.Body>
        </Panel>

        {tourFlightDetailsRequired && (
          <Panel>
            <Panel.Heading>
              <Panel.Title>Flight</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
              {!traveller.flights_submitted && (
                <FormGroup>
                  <Col className="travellerFormCol" sm={12} md={12}>
                    <Button
                      bsSize="large"
                      bsStyle={displayFlightsForm === true ? 'primary' : 'default'}
                      onClick={() => handleDisplayFlightsForm(true)}
                    >
                  Yes
                    </Button>
                    <Button
                      bsSize="large"
                      bsStyle={displayFlightsForm === false ? 'primary' : 'default'}
                      onClick={() => handleDisplayFlightsForm(false)}
                    >
                  No
                    </Button>
                  </Col>
                </FormGroup>
              )}

              {displayFlightsForm && (
                <FormGroup>
                  <Col className="travellerFormCol" sm={12} md={12}>
                    <Panel>
                      <Panel.Heading>
                        <Panel.Title>Arrival</Panel.Title>
                      </Panel.Heading>
                      <Panel.Body>
                        <FormGroup controlId="travellerArrivalDate" validationState={getValidationState('arrival_date')}>
                          <Col className="travellerFormCol" sm={6} md={6}>
                            <ControlLabel>{schema.arrival_date.label}</ControlLabel>
                            <FormControl
                              type="text"
                              name="arrival_date"
                              placeholder="DD/MM/YYYY"
                              value={data.arrival_date}
                              onChange={handleFieldChange}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup controlId="travellerArrivalTime" validationState={getValidationState('arrival_time')}>
                          <Col className="travellerFormCol" sm={6} md={6}>
                            <ControlLabel>{schema.arrival_time.label}</ControlLabel>
                            <FormControl
                              type="text"
                              name="arrival_time"
                              placeholder="e.g 14:00"
                              value={data.arrival_time}
                              onChange={handleFieldChange}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup controlId="travellerArrivalAirport" validationState={getValidationState('arrival_airport')}>
                          <Col className="travellerFormCol" sm={6} md={6}>
                            <ControlLabel>{schema.arrival_airport.label}</ControlLabel>
                            <FormControl
                              type="text"
                              name="arrival_airport"
                              placeholder="e.g LAX"
                              value={data.arrival_airport}
                              onChange={handleFieldChange}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup controlId="travellerArrivalFlightNumber" validationState={getValidationState('arrival_flight_number')}>
                          <Col className="travellerFormCol" sm={6} md={6}>
                            <ControlLabel>{schema.arrival_flight_number.label}</ControlLabel>
                            <FormControl
                              type="text"
                              name="arrival_flight_number"
                              placeholder="e.g QF 1234"
                              value={data.arrival_flight_number}
                              onChange={handleFieldChange}
                            />
                          </Col>
                        </FormGroup>
                      </Panel.Body>
                    </Panel>
                  </Col>
                  <Col className="travellerFormCol" sm={12} md={12}>
                    <Panel>
                      <Panel.Heading>
                        <Panel.Title>Departure</Panel.Title>
                      </Panel.Heading>
                      <Panel.Body>
                        <FormGroup controlId="travellerDepartureDate" validationState={getValidationState('departure_date')}>
                          <Col className="travellerFormCol" sm={6} md={6}>
                            <ControlLabel>{schema.departure_date.label}</ControlLabel>
                            <FormControl
                              type="text"
                              name="departure_date"
                              placeholder="DD/MM/YYYY"
                              value={data.departure_date}
                              onChange={handleFieldChange}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup controlId="travellerDepartureTime" validationState={getValidationState('departure_time')}>
                          <Col className="travellerFormCol" sm={6} md={6}>
                            <ControlLabel>{schema.departure_time.label}</ControlLabel>
                            <FormControl
                              type="text"
                              name="departure_time"
                              placeholder="e.g 14:00"
                              value={data.departure_time}
                              onChange={handleFieldChange}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup controlId="travellerDepartureAirport" validationState={getValidationState('departure_airport')}>
                          <Col className="travellerFormCol" sm={6} md={6}>
                            <ControlLabel>{schema.departure_airport.label}</ControlLabel>
                            <FormControl
                              type="text"
                              name="departure_airport"
                              placeholder="e.g LAX"
                              value={data.departure_airport}
                              onChange={handleFieldChange}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup controlId="travellerDepartureFlightNumber" validationState={getValidationState('departure_flight_number')}>
                          <Col className="travellerFormCol" sm={6} md={6}>
                            <ControlLabel>{schema.departure_flight_number.label}</ControlLabel>
                            <FormControl
                              type="text"
                              name="departure_flight_number"
                              placeholder="e.g QF 1234"
                              value={data.departure_flight_number}
                              onChange={handleFieldChange}
                            />
                          </Col>
                        </FormGroup>
                      </Panel.Body>
                    </Panel>
                  </Col>
                </FormGroup>
              )}
            </Panel.Body>
          </Panel>
        )}
        <div className="pull-right">
          <Button bsSize="large" bsStyle="default" onClick={onHide}>Cancel</Button>
          <Button bsSize="large" bsStyle="default" onClick={onSave}>Save data</Button>
          <Button bsSize="large" type="submit" bsStyle="primary" >
            Submit Traveller
          </Button>
        </div>
      </Form>
    )
  }
}
