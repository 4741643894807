import React from 'react'

const BlogUrl = 'http://luxnews.blog/'

const ButtonHandler = () => {
  const win = window.open(BlogUrl, '_blank')
  win.focus()
}

const LuxNews = () => (<button type="button" className="btn btn-default" onClick={ButtonHandler}>Partner News</button>)

export default LuxNews
