import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DocumentTitle from 'react-document-title'
import MDSpinner from 'react-md-spinner'
import Header from './Header'
import ErrorDisplay from './ErrorDisplay'
import AuthService from '../../services/AuthService'
import VendorService from '../../services/VendorService'

export default class MasterPage extends Component {
  static childContextTypes = {
    vendor: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      fetching: true,
      vendor: null,
      hasError: false,
    }
  }

  getChildContext() {
    return { vendor: this.state.vendor }
  }

  fetchVendorContext = async() => {
    this.setState({ vendor: null, fetching: true })
    let user
    try {
      user = await AuthService.getCurrentUser()
    } catch (err) {
      this.setState({ fetching: false, hasError: false, error: null })
      return
    }

    try {
      let vendorId
      if (this.props.params && this.props.params.id_vendor) {
        if (user.roles.includes('admin-user') || user.vendors.includes(this.props.params.id_vendor)) {
          vendorId = this.props.params.id_vendor
        } else {
          return this.setState({ fetching: false })
        }
      } else if (user.vendors.length) {
        vendorId = user.vendors[0]
      } else {
        return this.setState({ fetching: false })
      }
      const vendorResponse = await VendorService.get(vendorId)
      this.setState({ vendor: vendorResponse.result })
      this.setState({ fetching: false, hasError: false })
    } catch (err) {
      this.setState({
        fetching: false,
        hasError: true,
        error: err,
      })
    }
  }

  async componentDidMount() {
    await this.fetchVendorContext()
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    if (nextContext.user === this.context.user && !this.state.vendor && !this.state.fetching) {
      await this.fetchVendorContext()
    }
    return true
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorDisplay
          message={this.state.error.message}
        />
      )
    }

    if (this.state.fetching) {
      return (
        <div className="spinner-container">
          <MDSpinner className="spinner" size={100} />
        </div>
      )
    }

    return (
      <DocumentTitle title="Vendor Portal | Luxury Escapes">
        <div className="MasterPage">
          <Header vendor={this.state.vendor} />
          { this.props.children }
        </div>
      </DocumentTitle>
    )
  }
}

MasterPage.contextTypes = {
  user: PropTypes.object,
  vendor: PropTypes.object,
}
