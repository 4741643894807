import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Row, Col } from 'react-bootstrap'
import Spinner from '../Common/Spinner'

export default class Remittance extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      downloadId: null,
    }
  }

  remittanceNumberFormatter = (cell, row) => row.remittance_number || 'draft'

  showDownloadTypes = id => {
    this.setState({ downloadId: id })
  }

  downloadRemittance = (id, type) => {
    this.props.handleDownloadRemittance(id, type)

    this.setState({
      downloadId: null,
    })
  }

  actionFormatter = (cell, row) => {
    const remittanceExists = row.remittance_number

    if (remittanceExists) {
      return (
        <div>
          <button onClick={() => this.showDownloadTypes(row.id)}>
            Download
          </button>
          {this.state.downloadId === row.id ? (
            <div>
              <button onClick={() => this.downloadRemittance(row.id, 'csv')}>
                CSV
              </button>
              <button onClick={() => this.downloadRemittance(row.id, 'pdf')}>
                PDF
              </button>
            </div>
          ) : null}
        </div>
      )
    }
  }

  render() {
    let content = <Spinner />

    if (this.props.data) {
      content = (
        <BootstrapTable
          data={this.props.data}
          remote
          pagination={false}
          striped
          bordered={false}
          hover
        >
          <TableHeaderColumn
            dataFormat={this.remittanceNumberFormatter}
            dataField="remittance_number"
          >
            Remittance number
          </TableHeaderColumn>
          <TableHeaderColumn dataField="id" isKey hidden>
            Remittance ID
          </TableHeaderColumn>
          <TableHeaderColumn dataField="date_of_payment">
            Date Of Payment
          </TableHeaderColumn>
          <TableHeaderColumn dataField="cost_currency">
            Cost Currency
          </TableHeaderColumn>
          <TableHeaderColumn dataField="payment_amount">
            Payment Amount
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.actionFormatter} />
        </BootstrapTable>
      )
    }

    return (
      <div>
        <Row>
          <Col xs={12}>{content}</Col>
        </Row>
      </div>
    )
  }
}
