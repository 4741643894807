import React from 'react'
import { Link } from 'react-router'
import { Glyphicon, Grid, Col, Row, Button } from 'react-bootstrap'
import Image from '../Common/Image'
import TourV2SalesPerformance from './TourV2SalesPerformance'

const getImage = (offer) => {
  if (offer.image) {
    return <Image id={offer.image} />
  }

  return null
}

const getOrdersLink = ({ vendorId, offer }) => `/${vendorId}/${offer.id}/orders`

export default function TourV2OfferCard({ id_vendor, offer }) {
  const tourIdWithoutPrefix = offer.id.replace('tour-', '')

  return (
    <div className="offer-card">
      <Grid>
        <Row>
          <Col sm={12} md={2}>
            {getImage(offer)}
            <h5>Deal ID</h5>
            {offer.dealId}
          </Col>
          <Col sm={12} md={7}>
            <h4>{offer.tourName}</h4>
            <h5>Tour Options</h5>
            {offer.tourOptions.map((tourOption, index) => (
              <div key={index}>
                <h6>{`Deal Option: ${tourOption.dealOptionName}, Tour Option: ${tourOption.tourOptionName}`}</h6>
              </div>
            ))}
            <h5>Offer Type</h5>
            {offer.type}
          </Col>
        </Row>
        <TourV2SalesPerformance idOffer={tourIdWithoutPrefix} />
        <Row className="action-bar">
          <Col sm={12} md={6}>
            <div className="deal-links">
              <Button bsStyle="link" onClick={() => {
                window.open(`${window.configs.LE_CUSTOMER_PORTAL}/tour/${offer.id}`, '_blank')
              }}>
                <Glyphicon glyph="list-alt" />View Deal Page on Luxury Escapes
              </Button>
            </div>
          </Col>

          <Col sm={12} md={6}>
            <div className="button-group">
              <Link to={{ pathname: getOrdersLink({ vendorId: id_vendor, offer }), state: { offer } }} className="btn btn-primary"><Glyphicon glyph="briefcase" />View Bookings</Link>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}
