import React from 'react'
import MDSpinner from 'react-md-spinner'

export default props => {
  const size = props.size || 100
  const inline = props.inline || false

  const spinner = (
    <MDSpinner className="spinner" singleColor={'#000'} size={size} />
  )

  if (inline) {
    return spinner
  }

  return <div className="spinner-container">{spinner}</div>
}
