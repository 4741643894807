import moment from 'moment'

// taken from:
// https://github.com/airbnb/react-dates/blob/master/src/utils/getCalendarMonthWeeks.js

export default function getCalendarMonthWeeks(month, enableOutsideDays) {
  const firstDayOfWeek = moment.localeData().firstDayOfWeek()

  // set utc offset to get correct dates in future (when timezone changes)
  const firstOfMonth = month
    .clone()
    .startOf('month')
    .hour(12)
  const lastOfMonth = month
    .clone()
    .endOf('month')
    .hour(12)

  // calculate the exact first and last days to fill the entire matrix
  // (considering days outside month)
  const prevDays = (firstOfMonth.day() + 7 - firstDayOfWeek) % 7
  const nextDays = (firstDayOfWeek + 6 - lastOfMonth.day()) % 7
  const firstDay = firstOfMonth.clone().subtract(prevDays, 'day')
  const lastDay = lastOfMonth.clone().add(nextDays, 'day')

  const totalDays = lastDay.diff(firstDay, 'days') + 1

  const currentDay = firstDay.clone()
  const weeksInMonth = []

  for (let i = 0; i < totalDays; i += 1) {
    if (i % 7 === 0) {
      weeksInMonth.push([])
    }

    let day = null
    if ((i >= prevDays && i < totalDays - nextDays) || enableOutsideDays) {
      day = currentDay.clone()
    }

    weeksInMonth[weeksInMonth.length - 1].push(day)

    currentDay.add(1, 'day')
  }

  return weeksInMonth
}
